import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { IdeaSearch } from '../models/ideaSearch';
import { environment } from '../../environments/environment';
import { default as auth } from '../../assets/auth-config.json';
@Injectable({
  providedIn: 'root'
})
export class IdeasearchService {
  url = auth.resources.todoListApi.resourceUri
  private urlPost: string = environment.baseURL + "api/Idea/DeleteIdea";
  private urlGet: string = this.url + "api/Idea/GetIdeasForSearch";
  private urlPostcopyId: string = environment.baseURL + "api/Idea/CopyIdea";
  constructor(private http: HttpClient) { }

  getIdea(UserId): Observable<IdeaSearch> {
    return this.http.get<IdeaSearch>(this.urlGet + "?userId="+ UserId).pipe(
      retry(1),
      catchError(this.handleError))
  }


  deldata(obj) {
    let IdeaId = Number(obj.IdeaId)
    let UserId = Number(obj.UserId)
    let BusinessAreaId = Number(obj.BusinessAreaId)
    let BusinessLineId = Number(obj.BusinessLineId)

    return this.http.post(this.urlPost + "?ideaId=" + IdeaId + '&userId=' + UserId + '&businessAreaId=' + BusinessAreaId + '&businessLineId=' + BusinessLineId, "").pipe(
      retry(1),
      catchError(this.handleError));

  };

  copydata(obj) {
    let IdeaId = Number(obj.IdeaId);
    let UserId = Number(obj.UserId);
    let BusinessAreaId = Number(obj.BusinessAreaId)
    let BusinessLineId = Number(obj.BusinessLineId)

    return this.http.post(this.urlPostcopyId + "?ideaId=" + IdeaId + '&userId=' + UserId + '&businessAreaId=' + BusinessAreaId + '&businessLineId=' + BusinessLineId, "").pipe(
      retry(1),
      catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
   
    return throwError(error || "Server Error");
  }
}

