import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { ProcessCost } from '../models/process-cost';
@Injectable({
  providedIn: 'root'
})
export class ProcessCostService {
  readonly baseUrl = environment.baseURL;
  public urlProcessCostData = "api/Processcost/GetProcessCostSummary";
  public urlSubProcessCostData = "api/Processcost/GetProcessCostDetail";
  public urlInsertProcessCostData = "api/Processcost/InsertUpdateProcessCostDetail";
  public urlProcessCostDatesData = "api/Processcost/GetProcessCostDatesData";
  constructor(private http: HttpClient) { }

  getProcessCostData(ideaId, solutionId)   {
    return this.http.get(this.baseUrl +this.urlProcessCostData+ '?ideaID=' + ideaId + '&&solutionID=' + solutionId).pipe(
      retry(1),
      catchError(this.handleError))
  }
  getSubProcessCostData(ideaId, solutionId,subProcessId)   {
    return this.http.get(this.baseUrl +this.urlSubProcessCostData+ '?ideaID=' + ideaId + '&&solutionID=' + solutionId + '&&subProcessId=' + subProcessId).pipe(
      retry(1),
      catchError(this.handleError))
  }


  postAllData(data):Observable<ProcessCost>{
    return this.http.post<ProcessCost>(this.baseUrl + this.urlInsertProcessCostData,data).pipe(
      retry(1),
      catchError(this.handleError))
  }

  getProcesscostDatesData(ideaId){
    return this.http.get(this.baseUrl +this.urlProcessCostDatesData+ '?ideaID=' + ideaId ).pipe(
      retry(1),
      catchError(this.handleError))
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}


