import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { DialogComponent } from "../dialog/dialog.component";
import { Dirty } from "../interface/dirty-interface";
import { LocalStorageService } from "./local-storage.service";
import { SharedServiceService } from "./shared-service.service";


@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService implements CanDeactivate<Dirty>{

  constructor(private dialog: MatDialog, private localStorage: LocalStorageService, private sharedService: SharedServiceService, private router: Router) { }
  canDeactivate(
    component: Dirty,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    if (this.localStorage.get("ideaId") == null) {// when user navigate to create idea page
      if (nextState.url == "/home" && component.canDeactivate()) {
        let dialogRef = this.dialog.open(DialogComponent, { data: { message: "You have unsaved changes.Do you want to save it?", okBtn: "Yes", cancelBtn: "No" } })

        return dialogRef.afterClosed().pipe(map(result => {
          if (result) {
            return component.submit()
          }
          else {
            return of(true)
          }

        }))

      }
      else if (nextState.url == "/home" && !component.canDeactivate()) {// To allow the user to navigate when he attmpts to navigate to home while creating idea
        return true;
      }
      else if (nextState.url == '/manageIdea' && !component.canDeactivate()) {// To allow user to navigate directly to manage idea page when the page dont have unsaved changes
        return true;
      }
      else if (nextState.url == '/impact' && !component.canDeactivate()) {// To allow user to navigate directly to impact page when the page dont have unsaved changes
        return true;
      }
      else if (nextState.url == '/categorization' && !component.canDeactivate()) {// To allow user to navigate directly to categorization page when the page dont have unsaved changes
        return true;
      }
      else if (nextState.url == '/manageIdea' && component.canDeactivate()) {// To allow the user to navigate to grid after successful data save
        return this.getDeactivateStatus(component);
      }
      else {
        return this.getDeactivateStatus(component);
      }
    }
    else if (component.canDeactivate()) { // if idea id is not null and page has unsaved changes
      if (this.router.url == "/categorization" || this.router.url == "/impact") {
        this.clearCatLocalstorage(nextState.url);
        return this.getDeactivateStatus(component);
      } else {
        return this.getDeactivateStatus(component);
      }
    }
    else {
      if (this.router.url == "/categorization" || this.router.url == "/impact") {
        this.clearCatLocalstorage(nextState.url);
      }
      return true;
    }
  }
  getDeactivateStatus(component: any) {
    return component.submit();
  }
  clearCatLocalstorage(nextUrl: any) {    
    if (this.router.url == "/categorization") {
      if (nextUrl != "/impact") {
        this.sharedService.onRemoveCategorizationData();
      }
    } else if (this.router.url == "/impact") {
      if (nextUrl != "/categorization") {
        this.sharedService.onRemoveCategorizationData();
      }
    } else {
      this.sharedService.onRemoveCategorizationData();
    }
  }
}
