import { HttpClient } from '@angular/common/http';
import { FormBuilder } from "@angular/forms";
import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { NonplcsummaryService } from '../../../services/nonplcsummary.service';
import { DoublingEditorComponent } from '../costsavings/doubling-editor.component';
import { NumericEditorComponent } from '../costsavings/numeric-editor.component';
import { LocalStorageService } from '../../../services/local-storage.service';
import { MSolutionType } from "../../../enum/MSolutionType";
import { ToasterService } from 'src/app/services/toaster.service';
import { CategorizationService } from "../../../services/categorization.service";
import { AlertDialogComponent } from '../../../alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { serverMessage } from '../../../popUpMessages/serverMessage';
import { messages } from '../../../popUpMessages/messages';
import { MenuService } from 'src/app/services/menu.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';

//Defined Global variables
let countryCode;

@Component({
  selector: 'app-non-plc-summary',
  templateUrl: './non-plc-summary.component.html',
  styleUrls: ['./non-plc-summary.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NonPlcSummaryComponent implements Dirty {

  public nonSummaryRowData: any;
  public ideaId: number;
  public dataSource: any;
  public rowData: any;
  public defaultColDef: any;
  public columnDefs: any;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public frameworkComponents;

  public loginUserDetails: any;
  public opwProductivityRowData: any;
  public responsePost: any;
  public monthDates = []
  public formData = {};
  public errorMessageApi = [];
  public showGrid = false;
  public arrayOPWGridData = [];
  public hasUnsavedChanges: boolean = false;
  public isChangeinOPWGrid: boolean = false;
  public submitted: boolean = false;
  public loggedInUserId: number;
  public isEditableMonth = {};
  hideOptA: boolean = true;
  hideOptB: boolean = true;
  hideOptC: boolean = true;
  isEditable: boolean = false;

  public user: any;
  successMessage: string = "";
  errorMessage: string = "";
  headerHeightRef: number;
  gridColumn: string = "in % of cost";

  namedMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  displayedColumns: string[] = ['costType', 'refCost', 'tarCost', 'tarSaving', 'tarPercentSaving', 'optA_Cost', 'optA_Saving', 'optA_PercentSaving', 'optB_Cost', 'optB_Saving', 'optB_PercentSaving', 'optC_Cost', 'optC_Saving', 'optC_PercentSaving'];
  costTypeList = ["Costs, per year, related to the measure, gross", "Surcharge", "Total cost/saving,net"] //To color the rows which contain these cost types
  costTypesForNoRowgap = ["Risk", "Complexity", "WACC"];
  showCost: boolean = false;
  showOpw: boolean = false;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  // used to control call of GET Api in case of null user;
  public count = 0;
  // used to fetch values from NonPLCSummaryData
  public costs = {};
  public costs1 = [];
  public costs2 = [];
  public options: any = {};
  public errorMessageUnmatch = [];
  constructor(
    private http: HttpClient,
    public fb: FormBuilder,
    private sharedService: SharedServiceService,
    private NonplcsummaryService: NonplcsummaryService,
    private LocalStorageService: LocalStorageService,
    private ToasterService: ToasterService,
    private catService: CategorizationService,
    public dialog: MatDialog,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService
  ) {
  }

  // fetching all data :get API;
  getNonPLCSummaryData() {
    this.NonplcsummaryService.getNonPLCSummary(this.ideaId, this.loggedInUserId).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;

          this.showCost = true;
          if (responseData.Data.SuccessId == 0) {
            this.dataSource = [];
          } else {
            if (responseData.Data[0].solutionoptions != null && responseData.Data[0].solutionoptions != "") {
              this.checkOptionSelected(responseData.Data[0].solutionoptions.split(","))
            }
            this.dataSource = this.bindNonPLCSummaryCalcData(responseData.Data);
          }
          //To update Idea Productivity again
          this.sharedService.getIdeaIdAndtitle();
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      }
    );
  }
  ngOnInit(): void {
    this.ideaId = this.LocalStorageService.get('ideaId');

    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {
              this.loggedInUserId = this.userInOrg.UserId;

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                }
                else {
                  this.isEditable = false;
                }
              }
              //Load Ag-Grid data
              this.getNonPLCSummaryData();
            }
          })
        })
      }
    })

    //Get IdeaId;
    this.ideaId = this.LocalStorageService.get('ideaId');
    //Get Country Code;
    //this.LocalStorageService.set('countryCode', 'de-DE');
    countryCode = this.LocalStorageService.get('countryCode');
    this.catService.getCategorizationDataById(this.ideaId).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;
          if (responseData.Data != null) {
            //Get StartDate and EndDate
            let dbStartDate = new Date(responseData.Data.Categorization.StartDate);
            let _StartDate = dbStartDate.getFullYear() + '-' + (dbStartDate.getMonth() + 1) + '-' + dbStartDate.getDate();
            let dbEndDate = new Date(dbStartDate.setFullYear(dbStartDate.getFullYear() + 1));;
            let _EndDate = dbEndDate.getFullYear() + '-' + (dbEndDate.getMonth() + 1) + '-' + dbEndDate.getDay();
            //Pass dates to functions
            this.dateRange(_StartDate, _EndDate);
          }
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      })


    setTimeout(() => {
      this.callOpwProductivityData();
    }, 1000);
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }
  checkOptionSelected(obj) {
    obj.forEach(element => {
      if (element == MSolutionType.OptionA) {
        this.hideOptA = false;
      }
      if (element == MSolutionType.OptionB) {
        this.hideOptB = false;
      }
      if (element == MSolutionType.OptionC) {
        this.hideOptC = false;
      }
    });
  }

  bindNonPLCSummaryCalcData(obj) {
    let _dataBind = {};
    let nonPLCSummaryData = [];
    let nonPLCSummaryCalcData = [];
    nonPLCSummaryCalcData = obj;
    nonPLCSummaryCalcData.forEach(function (value, key) {
      this.costs = {};
      if (value.CostType == "Costs, per year, related to the measure, gross" ||
        value.CostType == "Surcharge" || value.CostType == "Total cost/saving,net") {
        if (value.CostType == "Costs, per year, related to the measure, gross") {
          this.costs["costs"] = "Costs,per year,related to the measure";
        }
        else if (value.CostType == "Total cost/saving,net") {
          this.costs["costs"] = "Total costs";
        }

        this.costs["Reference"] = value.RefCost;
        this.costs["Target"] = value.TarCost;
        this.costs["Option A"] = value.OptA_Cost;
        this.costs["Option B"] = value.OptB_Cost;
        this.costs["Option C"] = value.OptC_Cost;
        this.costs1.push(this.costs);
      }
      this.costs = {}
      if (value.CostType == "Costs, per year, related to the measure, gross" || value.CostType == "Total cost/saving,net") {
        this.costs["costs"] = value.CostType == "Total cost/saving,net" ? "Savings, net" : "Savings, gross";
        this.costs["Target"] = value.TarSaving;
        this.costs["Option A"] = value.OptA_Saving;
        this.costs["Option B"] = value.OptB_Saving;
        this.costs["Option C"] = value.OptC_Saving;
        this.costs2.push(this.costs)
      }


      _dataBind = {
        nonPlcSummaryCalcId: value.NonPLCSummaryCalcId,
        IdeaId: value.IdeaId,
        CostType: value.CostType,
        RefCost: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.RefCost).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.RefCost).toFixed(0)))),
        TarCost: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.TarCost).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.TarCost).toFixed(0)))),
        TarSaving: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.TarSaving).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.TarSaving).toFixed(0)))),
        TarPercentSaving: (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.TarPercentSaving).toFixed(0))) + '%'),
        OptA_Cost: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptA_Cost).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptA_Cost).toFixed(0)))),
        OptA_Saving: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptA_Saving).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptA_Saving).toFixed(0)))),
        OptA_PercentSaving: (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptA_PercentSaving).toFixed(0))) + '%'),
        OptB_Cost: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptB_Cost).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptB_Cost).toFixed(0)))),
        OptB_Saving: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptB_Saving).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptB_Saving).toFixed(0)))),
        OptB_PercentSaving: (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptB_PercentSaving).toFixed(0))) + '%'),
        OptC_Cost: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptC_Cost).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptC_Cost).toFixed(0)))),
        OptC_Saving: value.CostType == this.gridColumn ? (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptC_Saving).toFixed(0))) + '%') : (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptC_Saving).toFixed(0)))),
        OptC_PercentSaving: (new Intl.NumberFormat(countryCode).format(Number(parseFloat(value.OptC_PercentSaving).toFixed(0))) + '%'),
      }
      nonPLCSummaryData.push(_dataBind);
    }.bind(this));
    this.costs1 = this.costs1.concat(this.costs2);
    this.options = {
      data: this.costs1,
      autoSize: true,
      // title: {
      //   text: 'Decision support non PLC measure',
      // },
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10
      },
      series: [{
        type: 'column',
        xKey: 'costs',
        yKeys: ['Reference', 'Target', 'Option A', 'Option B', 'Option C'],
        yNames: ['Reference', 'Target', 'Option A', 'Option B', 'Option C'],
        grouped: true,
        fills: ['#f3622d', '#fba71b', '#57b757', '#41a9c9', '#4258c9', '#9a42c8', '#c84164', '#888888'],
        tooltip: {
          renderer: function (params) {
            let regex = '/(?=({3})+(?!))/g';
            return (
              '<div class="ag-chart-tooltip-title" style="background-color:' +
              params.color +
              '">' +
              params.xValue +
              '</div>' +
              '<div class="ag-chart-tooltip-content">' +
              params.yValue.toFixed(2).toString().replace(regex, ",") +
              '</div>'
            );
          },
        },
      },
      ],
      axes: [
        {
          type: 'category',
          position: 'bottom',
          label: {
            formatter: function (params) {
              return params.value;
            }, fontSize: 10.5
          },
        },
        {
          type: 'number',
          position: 'left',
          label: {
            formatter: function (params) {
              let regex = '/(?=(d{3})+(?!))/g';
              return params.value.toString().replace(regex, ",");
            }, fontSize: 10.5
          },
        },
      ],
      bar: {
        highlightStyle: {
          fill: undefined,
          stroke: undefined
        }
      },
      legend: { fontSize: 10.4, position: 'bottom' }
    }
    return nonPLCSummaryData;
  }

  dateRange(startDate, endDate) {
    let start = startDate.split('-');
    let end = endDate.split('-');
    let startYear = parseInt(start[0]);
    let endYear = parseInt(end[0]);
    this.monthDates = [];
    for (let i = startYear; i <= endYear; i++) {
      let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        this.monthDates.push(this.namedMonths[j] + '-' + i.toString().substr(-2));
      }
    }
    this.monthDates.pop(); //Remove last element
  }

  //Load ag-Grid 
  loadAgGrid() {
    this.showGrid = true;
    this.domLayout = 'autoHeight';
    this.columnDefs = [
      {
        field: 'OpwproductivityId',
        hide: true,
      },
      {
        field: 'Productivity', editable: false, width: 300, tooltipField: 'Productivity',
        headerTooltip: "Productivity",
        cellClassRules: {
          'grid-tar-cell': (params: any) => params.data.Productivity == "Target Scenario",
          'grid-opt-cell': (params: any) => ["Option A", "Option B", "Option C"].includes(params.data.Productivity)
        }
      },
      {
        field: 'Month1', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month2', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month3', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month4', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month5', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month6', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month7', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month8', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month9', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month10', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month11', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },
      {
        field: 'Month12', tooltipValueGetter: CurrencyCellRenderer, headerComponentParams: (element) => {
          if (this.setHeaderLockIcon(element)) {
            return { template: '<div>' + element.displayName + '<img src="../assets/icons/lockIcon.svg" alt="LockIcon" width="6" height="6" margin-left= "5" style="vertical-align: top"/> </div>' };
          }
          else {
            return { template: '<div>' + element.displayName + '</div>' }
          }
        },
        cellEditor: 'numericEditor', cellRenderer: CurrencyCellRenderer, editable: (element) => this.setEditable(element)
      },

      { headerName: 'Delta monthly roll up vs. Total', headerTooltip: 'Delta monthly roll up vs. Total', field: 'MonthlyTotal', editable: false, valueGetter: formatValue, tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer },
      {
        headerName: 'Total', headerTooltip: "Total", field: 'Total', editable: false, tooltipValueGetter: CurrencyCellRenderer, cellRenderer: CurrencyCellRenderer, valueGetter:
          actualTotal
      }
    ];


    this.defaultColDef = {
      flex: 1,
      editable: this.isEditable,
      singleClickEdit: true,
      suppressMovable: true,
      sortable: false,
    };

    this.headerHeight = 53;
    this.rowClassRules = {
      'grid-tar-row': (params: any) => params.data.Productivity == "Target Scenario",
      'grid-opt-row': (params: any) => ["OptionA", "OptionB", "OptionC"].includes(params.data.Productivity)
    };
    this.frameworkComponents = {
      doublingEditor: DoublingEditorComponent,
      numericEditor: NumericEditorComponent
    };
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //Bind ag grid Header
    setTimeout(() => {
      this.monthDates.forEach((value, index) => {
        if (this.gridApi.getColumnDef("Month" + (index + 1))) {
          this.gridApi.getColumnDef("Month" + (index + 1)).headerName = value;
          this.gridApi.getColumnDef("Month" + (index + 1)).headerTooltip = value;
        }
      });
      this.gridApi.refreshHeader();
    }, 1000);
  }
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  //Call function on Cell value change
  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.isChangeinOPWGrid = true;
    this.gridApi = params.api;
    if (params.oldValue != params.newValue && params.data.OpwproductivityId != null) {
      params.data.IsAction = true;
    }
    if (params.data.SolutionId != 6) {
      params.data.MonthlyTotal = (Number(params.data.Month1 ?? params.data.Month1) * 100 + Number(params.data.Month2 ?? params.data.Month2) * 100 + Number(params.data.Month3 ?? params.data.Month3) * 100 +
        Number(params.data.Month4 ?? params.data.Month4) * 100 + Number(params.data.Month5 ?? params.data.Month5) * 100 + Number(params.data.Month6 ?? params.data.Month6) * 100 +
        Number(params.data.Month7 ?? params.data.Month7) * 100 + Number(params.data.Month8 ?? params.data.Month8) * 100 + Number(params.data.Month9 ?? params.data.Month9) * 100 +
        Number(params.data.Month10 ?? params.data.Month10) * 100 + Number(params.data.Month11 ?? params.data.Month11) * 100 + Number(params.data.Month12 ?? params.data.Month12) * 100) / 100;
      params.data.MonthlyTotal = params.data.Total - ((params.data.MonthlyTotal * 100) / 100);
    }
    this.gridApi.refreshCells(params);
  }

  callOpwProductivityData() {
    this.NonplcsummaryService.getOPWProductivity(this.ideaId).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          this.sharedService.getIdeaIdAndtitle();
          let responseData;
          responseData = data;
          this.showOpw = true;
          if (responseData.Data.length > 0) {
            this.opwProductivityRowData = this.bindOpwproductivityData(responseData.Data);
            this.loadAgGrid();
          } else {
            this.opwProductivityRowData = [];
          }
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        // this.sharedService.getErrorMessage(error);
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  bindOpwproductivityData(obj) {
    let opwProductivityData = [];
    this.opwProductivityRowData = obj;
    this.opwProductivityRowData.forEach(function (value, key) {
      if (value.SolutionId == 1 || value.SolutionId == 2 || value.SolutionId == 6 || value.SolutionId == 3 || value.SolutionId == 4 || value.SolutionId == 5) {
        let _bindData: any;
          _bindData = {
            OpwproductivityId: value.OPWProductivityId,
            IdeaId: value.IdeaId,
            SolutionId: value.SolutionId,
            //Productivity: value.SolutionId == MSolutionType.Actuals ? 'Actual' : value.SolutionId == MSolutionType.TargetScenario ? 'Target Scenario' : value.SolutionId == MSolutionType.OptionA ? 'Option A' : value.SolutionId == MSolutionType.OptionB ? 'Option B' : 'Option C',
            IsPlc: value.IsPLC,
            Month1: value.Month1,
            Month2: value.Month2,
            Month3: value.Month3,
            Month4: value.Month4,
            Month5: value.Month5,
            Month6: value.Month6,
            Month7: value.Month7,
            Month8: value.Month8,
            Month9: value.Month9,
            Month10: value.Month10,
            Month11: value.Month11,
            Month12: value.Month12,
            Total: value.Total,
            MonthlyTotal: 0,
            IsAction: null
          }
        if (value.SolutionId == MSolutionType.Actuals) {
          _bindData.Productivity = 'Actual'
        }
        else if (value.SolutionId == MSolutionType.TargetScenario) {
          _bindData.Productivity = 'Target Scenario'
        }
        else if (value.SolutionId == MSolutionType.OptionA) {
          _bindData.Productivity = 'Option A'
        }
        else if (value.SolutionId == MSolutionType.OptionB) {
          _bindData.Productivity = 'Option B'
        }
        else if (value.SolutionId == MSolutionType.OptionC) {
          _bindData.Productivity = 'Option C'
        }

        opwProductivityData.push(_bindData);
      }
      if (value.SolutionId == 99 && value.OPWProductivityId == 0) {
        this.isEditableMonth = {
          SolutionId: value.SolutionId,
          Month1: value.Month1,
          Month2: value.Month2,
          Month3: value.Month3,
          Month4: value.Month4,
          Month5: value.Month5,
          Month6: value.Month6,
          Month7: value.Month7,
          Month8: value.Month8,
          Month9: value.Month9,
          Month10: value.Month10,
          Month11: value.Month11,
          Month12: value.Month12
        }
      }
    }.bind(this));
    return opwProductivityData;
  }

  //Submit OPW Productivity data
  submit(event?: any) {

    this.submitted = true;
    let opwAgGridData: any;
    let formResponse = null;

    if (this.isChangeinOPWGrid) {
      opwAgGridData = this.getOPWProductivityGridData();
    }

    if (opwAgGridData == undefined) {
      if (event != undefined) {
        this.router.navigate(['/manageIdea'])
      }
    } else if (opwAgGridData == 'error') {
      this.arrayOPWGridData = [];
    } else {
      formResponse = this.postFormData(event, opwAgGridData);
    }
    return formResponse;
  }



  getOPWProductivityGridData() {
    this.errorMessageUnmatch = [];
    let opwGridData: any;
    this.gridApi.forEachNode((rowNode, index) => {
      if (rowNode.data.SolutionId != MSolutionType.Actuals && Number(parseFloat(rowNode.data.MonthlyTotal)) != 0) {
        opwGridData = 'error';
        this.errorMessageUnmatch.push({ [rowNode.data.Productivity]: messages.nonPLCSummary.validationMessage })
      }
      else {
        if (rowNode.data.IsAction != null) {
          this.fillGridDataInArray(this.arrayOPWGridData, rowNode.data);
        }
      }
    });
    if (opwGridData != 'error') {
      opwGridData = this.getAgGridData(this.arrayOPWGridData);
    } else if (opwGridData == 'error') {
      this.openAlertDialog(this.errorMessageUnmatch);
      this.submitted = false;
    }
    return opwGridData;
  }

  fillGridDataInArray(objOpWProductivityData, objData) {
    let objOpwProductivity: any;
    objOpwProductivity = {
      OpwproductivityId: objData.OpwproductivityId ?? 0 ,
      IdeaId: this.ideaId,
      //SolutionId: objData.Productivity == 'Actual' ? MSolutionType.Actuals : objData.Productivity == 'Target Scenario' ? MSolutionType.TargetScenario : objData.Productivity == 'Option A' ? MSolutionType.OptionA : objData.Productivity == 'Option B' ? MSolutionType.OptionB : MSolutionType.OptionC,
      IsPlc: false,
      Month1: Number(objData.Month1 ?? 0 ),
      Month2: Number(objData.Month2 ?? 0),
      Month3: Number(objData.Month3 ?? 0),
      Month4: Number(objData.Month4 ?? 0),
      Month5: Number(objData.Month5 ?? 0),
      Month6: Number(objData.Month6 ?? 0),
      Month7: Number(objData.Month7 ?? 0),
      Month8: Number(objData.Month8 ?? 0),
      Month9: Number(objData.Month9 ?? 0),
      Month10: Number(objData.Month10 ?? 0),
      Month11: Number(objData.Month11 ?? 0),
      Month12: Number(objData.Month12 ?? 0),
      //Total: parseFloat(objData.Total == null ? 0 : objData.Total),
      Total: this.sumTotal(objData),
      IsActive: true,
      CreatedBy: this.loggedInUserId,
      ModifiedBy: this.loggedInUserId,
    }
    if (objData.Productivity == 'Actual') {
      objOpwProductivity.solutionId = MSolutionType.Actuals
    }
    else if (objData.Productivity == 'Target Scenario') {
      objOpwProductivity.solutionId = MSolutionType.TargetScenario
    }
    else if (objData.Productivity == 'Option A') {
      objOpwProductivity.solutionId = MSolutionType.OptionA
    }
    else if (objData.Productivity == 'Option B') {
      objOpwProductivity.solutionId = MSolutionType.OptionB
    }
    else if (objData.Productivity == 'Option C') {
      objOpwProductivity.solutionId = MSolutionType.OptionC
    }
    objOpWProductivityData.push(objOpwProductivity);
  }


  getAgGridData(opwGridDataGridData,) {
    this.formData = {
      OPWProductivity: opwGridDataGridData,
      IdeaId: this.ideaId,
      UserId: this.loggedInUserId,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId
    }
    return this.formData;
  }
  sumTotal(obj) {
    let Total = (Number(obj.Month1 ?? 0) * 100 + Number(obj.Month2 ?? 0) * 100 + Number(obj.Month3 ?? 0) * 100 +
      Number(obj.Month4 ?? 0) * 100 + Number(obj.Month5 ?? 0) * 100 + Number(obj.Month6 ?? 0) * 100 +
      Number(obj.Month7 ?? 0) * 100 + Number(obj.Month8 ?? 0) * 100 + Number(obj.Month9 ?? 0) * 100 +
      Number(obj.Month10 ?? 0) * 100 + Number(obj.Month11 ?? 0) * 100 + Number(obj.Month12 ?? 0) * 100) / 100;
    return Total
  }


  postFormData(event, postOPWProductivityGridData) {
    let response = this.NonplcsummaryService.postOPWProductivityData(postOPWProductivityGridData).toPromise().then(
      (data) => {
        this.responsePost = data;
        let successCode = this.responsePost.StatusCode;
        let errorCode = this.responsePost.Data.ErrorCode;
        let errorMsgeFromBack = this.responsePost.Data.Message;
        //Check if successCode is ok i.e 200
        if (successCode == 200) {
          //check if errorCode in the response is 0 then everythig is fine.
          if (errorCode == 0) {
            this.ToasterService.notify(serverMessage.dataSaveMessage, "success");
            this.hasUnsavedChanges = false;
            if (event != undefined) {
              this.router.navigate(['/manageIdea'])
            }
            return true;
          }
          //If the error code in the resposne is not 0, then display the error in UI.
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.submitted = false;
            return false;
          }
        }
        //Going in else means that success code is not. ie. Http Status from server is not ok.
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
          this.submitted = false;
          return false;
        }
      }, (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        this.submitted = false;
        return false;
      }
    )
    return response;
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  displayErrorMessage() {
    this.submitted = false;
    let error = messages.nonPLCSummary.validationMessage;
    let valueAlert = { [error]: "Warning !" };
    this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
    this.submitted = false
  }

  onClickCancel() {
    this.hasUnsavedChanges = false
    this.router.navigate(['/manageIdea'])
  }
  onClickBack() {
    this.menuService.onClickNextAndBack("back")

  }
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  setEditable(element) {
    return this.isEditableMonth[element.colDef.field] == 0 || this.isEditableMonth[element.colDef.field] == null ? true : false;
  }

  setHeaderLockIcon(element) {
    return this.isEditableMonth[element.column.colId] == 0 || this.isEditableMonth[element.column.colId] == null ? false : true;
  }
}

function CurrencyCellRenderer(params: any) {
  if (params.value == 0)
    return params.value;

  return params.value == null || params.value == undefined || params.value == "" ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(0)));
};

function formatValue(params: any) {
  params.data.MonthlyTotal = Number(parseFloat(params.data.MonthlyTotal).toFixed(0));
  return params.data.MonthlyTotal;
}

function actualTotal(params: any) {
  if (params.data.Productivity == "Actual") {
    let totalVal = 0;
    for (let i = 1; i < 13; i++) {
      let cellValue = Number(params.data["Month" + i]).toFixed(2);
      totalVal = totalVal + Number(cellValue);
    }
    params.data.Total = totalVal;
  } else {
    params.data.Total = Number(parseFloat(params.data.Total).toFixed(0));
  }
  return params.data.Total;
}


