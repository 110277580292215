import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { MDunitsService } from 'src/app/services/mdunits.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DoublingEditorComponent } from 'src/app/pages/non-plc/costsavings/doubling-editor.component';
import * as XLSX from 'xlsx';
import { Router } from "@angular/router";
import { serverExpression } from 'src/app/validationExpressions/Expressions';

let countryCode;
@Component({
  selector: 'app-md-units',
  templateUrl: './md-units.component.html',
  // styleUrls: ['./md-units.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MDUnitsComponent implements OnInit {

  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;

  public alertErrorBox = [];
  public modifiedMDUnitRows = [];
  public modifiedExcelMDUnitRows = [];
  public errorMessage = []
  public roleMasterData = [];
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;

  public sizeToFitRefresh: number = 0;
  public showGrid = false;

  submitResponse: any;
  refObject: { MDUnitData: any[]; UserId: any; };
  receivedData: any;
  headerYears: any = [];
  startYear: any;
  hideExportImport: boolean = true;
  FilterData: any;
  importExcelData: any;
  FinalDatatoSend: any = [];
  Flag: boolean = false;
  s1: any = ["PlanUnit"];
  yearArr: any = ["2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040"];
  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;
  public Editable: boolean = true;

  selectBusinessArea = "";
  selectBusinessLine = "";


  constructor(
    private mdUnitsService: MDunitsService,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toast: ToasterService) {
    this.frameworkComponents = { doublingEditor: DoublingEditorComponent };
  }

  ngOnInit(): void {
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
          this.hideExportImport = false;
        }
        this.showGrid = true;
      }
    )

    this.adminSubscription = this.sharedService.mdUnits.subscribe(() => {
      this.submit();
    })

    countryCode = this.localStorageService.get('countryCode');
    this.bindMdUnitsData();
  }

  updateBusinessLine() {
    this.hideExportImport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    if (this.headerYears != "")
      this.bindMdUnitsData();
  }

  updateMdUnits() {
    if (this.selectBusinessLine == "") {
      this.hideExportImport = true;
    }
    else {
      this.hideExportImport = false;
    }
    this.bindMdUnitsData();
  }

  exportGridData() {
    this.showGrid = false;
    this.SelectFilterOnIsActive();
    this.gridApi.exportDataAsExcel({
      // columnKeys: ['ProductTitle'],
      processCellCallback(params) {
        return params.value ?? 0;
      },
      allColumns: true,
      protection: true,
      sheetName: "PlanUnit",
      fileName: "PlanUnit"
    });
    this.RemoveFilterOnIsActive();
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage, "success");
  }
  SelectFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive')!;
    instance.setModel({ values: ['No'] });
    this.gridApi.onFilterChanged();
  }



  RemoveFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive')!;
    instance.setModel(null);
    this.gridApi.onFilterChanged();
  }

  importExcel(event: any) {
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      let ext = file.name.split('.').pop();
      if (ext != "xlsx") {
        this.toast.notify(serverMessage.excelnotImportMessage, "error"); this.onCancel();
      }
      else {
        // To check the size of the Excel Sheet
        if (event.target.files[0].size / 1024 / 1024 > 10) {
          this.toast.notify(serverMessage.excelImportMessage + ' is More Than 10MB', "error"); this.onCancel();
        }
        // To check the size of the Excel Sheet
        else {
          let fileReader = new FileReader();
          fileReader.readAsBinaryString(file);
          fileReader.onload = (e) => {
            let workBook = XLSX.read(fileReader.result, { type: 'binary' });
            let workSheet = workBook.SheetNames;
            this.importExcelData = XLSX.utils.sheet_to_json(workBook.Sheets[workSheet[0]]);
            let yearvalidate; let ExcelIsReadOnly;
            let productRow = {};
            let MDUnitComponentArray = [];
            let UpdatedValuesFromExcel = [];
            let ExcelYearssend = [];
            let ExcelheaderYears = [];
            let ExcelProduct; let ExcelParentId; let ExcelProductid; let yearval;
            let Finalobj = {}; const BreakError = {};
            {
              // To check the Work Shett Name
              if (workBook.SheetNames[0] !== this.s1[0]) { this.toast.notify(serverMessage.WorksheetError, "error"); this.onCancel(); }
              // To check the Work Shett Name
              else if (this.importExcelData.length == 0) { this.toast.notify(serverMessage.Blanksheeterror, "error"); this.onCancel(); }

              else {

                for (let i of this.importExcelData) {
                  debugger;
                  Finalobj = {};
                  yearvalidate = false;
                  ExcelProduct = i.Product;
                  ExcelIsReadOnly = i.IsReadOnly;
                  ExcelProductid = i.ProductId;
                  ExcelParentId = i.ParentId;

                  if (ExcelProductid == undefined || ExcelParentId == undefined || ExcelProduct == undefined) {
                    this.toast.notify(serverMessage.NewRowAddError, "error"); this.onCancel();
                    break;
                  }
                  else {
                    // To validate the Excel Readonly
                    if (ExcelIsReadOnly == undefined || serverExpression.dotnadno.test(ExcelIsReadOnly) || ExcelIsReadOnly.toLowerCase() !== 'no' && ExcelIsReadOnly.toLowerCase() !== 'NO' && ExcelIsReadOnly.toLowerCase() !== 'No' && ExcelIsReadOnly.toLowerCase() !== 'nO') {
                      this.toast.notify(serverMessage.IsReadOnlyError, "error"); this.onCancel();
                      break;
                    }
                    // To validate the Excel Readonly
                    else {
                      if (this.receivedData == "") {
                        this.toast.notify(serverMessage.NoDataFound, "error"); this.onCancel();
                        break;
                      }
                      else {
                        try {
                          this.FilterData = (this.rowData).filter(obj => obj.ProductId == ExcelProductid && obj.ParentId == ExcelParentId && obj.ProductTitle == ExcelProduct && obj.IsActive == 'No');
                          //  Checking the Existiing Row
                          if (this.FilterData.length == 0) {
                            this.toast.notify(serverMessage.NewRowAddError, "error"); this.onCancel();
                            break;
                          }
                          // Checking the Existing Row
                          else {
                            this.receivedData.forEach((value, index) => {
                              productRow["ProductTitle"] = value.ProductTitle;
                              productRow["ProductId"] = value.ProductId;
                              productRow["ParentId"] = value.ParentId;
                              if (ExcelProductid === value.ProductId && ExcelParentId === value.ParentId && ExcelProduct === value.ProductTitle) {
                                UpdatedValuesFromExcel = [];

                                for (let years of this.headerYears) {
                                  debugger;
                                  let ExcelHeaderYearData = i[years];
                                  yearvalidate = serverExpression.dotnadno.test(ExcelHeaderYearData);
                                  const str = String(ExcelHeaderYearData);
                                  // Checking the Header Years
                                  if (ExcelHeaderYearData == undefined) {
                                    this.toast.notify(serverMessage.NewRowAddError, "error"); this.onCancel();
                                    yearvalidate = false;
                                    throw BreakError;
                                  }
                                  // Checking the Header Years
                                  // Validating the Years Data
                                  else if (!yearvalidate) {
                                    this.toast.notify(serverMessage.validateerrormessage, "error"); this.onCancel();
                                    yearvalidate = false;
                                    throw BreakError;
                                  }
                                  // Validating the Years Data
                                  // Validating the Years Data Length
                                  else if (str.length > 10) {
                                    this.toast.notify(serverMessage.RangeLengthError, "error"); this.onCancel();
                                    yearvalidate = false;
                                    throw BreakError;
                                  }
                                  // Validating the Years Data Length
                                  UpdatedValuesFromExcel.push({
                                    headerYears: ExcelHeaderYearData,
                                    ExcelYear: years
                                  });
                                }
                                MDUnitComponentArray = [];
                                if (value.MDUnits.length == 1) {
                                  // for (let i = 0; i < UpdatedValuesFromExcel.length; i++) 
                                  for (let i of UpdatedValuesFromExcel) {
                                    debugger;
                                    MDUnitComponentArray.push({
                                      MDUnitID: 0,
                                      MDUnit: i.headerYears,
                                      Year: i.ExcelYear
                                    });
                                  }
                                } else {
                                  // condition added to check the years 
                                  //  for (let i = 0; i < UpdatedValuesFromExcel.length; i++)
                                  for (let i of UpdatedValuesFromExcel) {
                                    debugger;
                                    let mdunit = value.MDUnits.find(y => y.Year == i.ExcelYear)
                                    if (mdunit != null) {
                                      MDUnitComponentArray.push({
                                        MDUnitID: mdunit.MDUnitID,
                                        MDUnit: i.headerYears,
                                        Year: i.ExcelYear
                                      });
                                    }
                                    else {
                                      MDUnitComponentArray.push({
                                        MDUnitID: 0,
                                        MDUnit: i.headerYears,
                                        Year: i.ExcelYear
                                      });

                                    }
                                  }
                                }
                              }
                            });

                          }
                        }
                        catch (error) {
                          if (error !== BreakError) throw error;
                          break;
                        }
                      }
                    }
                  }
                  Finalobj["ProductId"] = ExcelProductid;
                  Finalobj["ProductTitle"] = ExcelProduct;
                  Finalobj["ParentId"] = ExcelParentId;
                  Finalobj["MDUnits"] = MDUnitComponentArray;
                  Finalobj["IsReadOnly"] = ExcelIsReadOnly;
                  this.FinalDatatoSend.push(Finalobj);
                }
              }
            }
            if (yearvalidate) {
              this.FinalData();
              this.toast.notify(serverMessage.excelImportMessage, "success");
            }
          }
        }
      }
    }
  }
  FinalData() {
    let ProductArray = [];
    let MDUnitComponentArray = [];
    this.FinalDatatoSend.forEach(node => {
      node["IsActive"] = node.IsReadOnly == 'No' ? 1 : 0;
      node["CreatedBy"] = this.loggedInUser.UserId;
      node["CreatedOn"] = new Date().toISOString();
      node["ModifiedBy"] = this.loggedInUser.UserId;
      node["ModifiedOn"] = new Date().toISOString();
      ProductArray.push(node);
    });
    if (this.modifiedExcelMDUnitRows.length == 0) {
      this.modifiedExcelMDUnitRows = ProductArray;
      this.Editable = false;
      this.loadMdUnitGrid();
    }
  }

  onCancel() {
    // changes;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unsvdAdminChanges = false;
    this.unSavedChanges.emit(valToEmit);
    (<HTMLInputElement>document.getElementById('file')).value = "";
    this.hideExportImport = false;
    this.Editable = true;


    // end;

    // end;
    this.bindMdUnitsData();
    this.router.navigate(['/mdUnits'])
  }
  bindMdUnitsData() {
    this.showGrid = false;
    this.mdUnitsService.getAllMdUnits(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((data) => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.headerYears = [];
        this.receivedData = data.Data.MDUnitData;

        if (this.receivedData != "") {
          if (this.receivedData[0].MDUnits[0].Year)
            this.startYear = this.receivedData[0].MDUnits[0].Year;
          else
            this.startYear = 2022;
        }
        else {
          this.rowData = [];
          this.showGrid = true;
          return;
        }

        for (let val = 0; val < 20; val++) {
          this.headerYears.push(this.startYear + val)
        }
        let productDataArray = [];
        let productRow = {};
        this.receivedData.forEach((value, index) => {
          productRow = {};
          productRow["ProductTitle"] = value.ProductTitle;
          productRow["ProductId"] = value.ProductId;
          productRow["IsActive"] = value.IsActive ? 'No' : 'Yes';
          productRow["ParentId"] = value.ParentId;
          productRow["CreatedBy"] = value.CreatedBy;
          productRow["CreatedOn"] = value.CreatedOn;
          let MDUnitValues = value.MDUnits;
          if (MDUnitValues.length == 1) {
            for (let year of this.headerYears) {
              productRow[String(year)] = null;
              productRow[String("ID" + null) + ":" + String(year)] = null;
            }
          }
          else {
            for (let year of this.headerYears) {
              let unit = MDUnitValues.find(y => y.Year == year)
              if (unit != null) {
                productRow[String(unit.Year)] = unit.MDUnit;
                productRow[String("ID" + unit.MDUnitID) + ":" + String(unit.Year)] = unit.MDUnit;
              }
              else {
                productRow[String(year)] = null;
                productRow[String("ID" + null) + ":" + String(year)] = null;
              }

            }
          }
          productDataArray.push(productRow);
        });
        this.rowData = productDataArray;
        this.loadMdUnitGrid();
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      return false;
    })

  }
  loadMdUnitGrid() {
    let yearColumnsGroup = [];
    this.columnDefs = [
      {
        headerName: "ProductId",
        field: "ProductId",
        tooltipField: "ProductId",
        headerTooltip: "ProductId",
        editable: false,
        hide: false
      },
      {
        headerName: "ParentId",
        field: "ParentId",
        tooltipField: "ParentId",
        headerTooltip: "ParentId",
        editable: false,
        hide: false
      },
      {
        headerName: "Product",
        field: "ProductTitle",
        tooltipField: "ProductTitle",
        headerTooltip: "Product",
        editable: false,
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }]
    this.headerYears.forEach((year, index) => {
      yearColumnsGroup.push({
        headerName: year,
        field: String(year),
        tooltipValueGetter: CurrencyCellRenderer,
        headerTooltip: year,
        //editable: this.checkEditable,
        editable: (params) => {
          if (params.data.IsActive == 'Yes') {
            return false;
          }
          else {
            return this.Editable;
          }
        },
        singleClickEdit: true,
        cellRenderer: CurrencyCellRenderer,
        cellEditor: "doublingEditor",
        minWidth: 50
      });
    });

    yearColumnsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }
    });

    this.columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = yearColumnsGroup;
      }
    });

    this.columnDefs.push({
      headerName: "IsReadOnly",
      field: "IsActive",
      tooltipField: "IsActive",
      headerTooltip: "IsReadOnly",
      editable: false,
      minWidth: 50
    })


    this.defaultColDef = {
      editable: true,
      filter: true,
      sortable: true,
      suppressMovable: true,
      singleClickEdit: true,
    };
    this.domLayout = 'autoHeight';
    this.showGrid = true;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onCellValueChanged(params) {
    // changes;
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false

    }

    this.unSavedChanges.emit(valToEmit);
    // end;
    this.hideExportImport = true;
    let rowPushed = false;
    if (this.modifiedMDUnitRows.length == 0) {
      this.modifiedMDUnitRows.push(params.data);
      rowPushed = true;
    }
    else {
      this.modifiedMDUnitRows.forEach(
        (row) => {
          if (row["ProductId"] == params.data["ProductId"]) {
            rowPushed = true;
          }
        }
      )
    }
    if (!rowPushed) {
      this.modifiedMDUnitRows.push(params.data);
    }
  }

  checkEditable(params) {
    if (params.data.IsActive == 'Yes') {
      return false;
    }
    else {
      return true;
    }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  onClickCancel() {
    // changes;
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }

    this.unSavedChanges.emit(valToEmit);
    // end;
  }

  submit(event?: any) {
    if (this.modifiedExcelMDUnitRows.length != 0) {
      this.exceldataToSend();
      let response = null;
      let postData = [];
      if (this.refObject != null) {
        this.showGrid = false;
        postData.push(this.refObject);
        this.mdUnitsService.updateData(postData).toPromise()
          .then(res => {
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              //check if errorCode in the response is 0 then everythig is fine.
              if (errorCode == 0) {
                // changes;
                let valToEmit = {
                  unsavedChanges: false,
                  saveButtonClick: event == undefined ? false : true
                }
                this.unsvdAdminChanges = false;
                this.unSavedChanges.emit(valToEmit);
                // end;
                this.toast.notify(serverMessage.dataSaveMessage, "success");
                this.headerYears = [];
                this.modifiedMDUnitRows = [];
                this.hideExportImport = false;
                this.bindMdUnitsData();
                this.Editable = true;
                return true;
              }
              //If the error code in the resposne is not 0, then display the error in UI.
              else {
                //Show errorCode and errorMessage in the UI              
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer);
                this.showGrid = true;
                return false;
              }
            }
            //Going in else means that success code is not. ie. Http Status from server is not ok.
            else {
              //Show errorCode and errorMessage in the UI           
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.showGrid = true;
              return false;
            }
          }, (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            this.showGrid = true;
            return false;
          })
      }
      else {
        let valToEmit = {
          unsavedChanges: false,
          saveButtonClick: event == undefined ? false : true
        }
        this.unsvdAdminChanges = false;
        this.unSavedChanges.emit(valToEmit);
        // end;
        return true;
      }
    }
    else if (this.modifiedMDUnitRows.length != 0) {
      this.dataToSend();
    }
    let response = null;
    let postData = [];
    if (this.modifiedMDUnitRows.length != 0) {
      this.showGrid = false;
      this.modifiedMDUnitRows = [];
      postData.push(this.refObject);
      response = this.mdUnitsService.updateData(postData).toPromise()
        .then(res => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              // changes;
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unsvdAdminChanges = false;
              this.unSavedChanges.emit(valToEmit);
              // end;
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.headerYears = [];
              this.modifiedMDUnitRows = [];
              this.hideExportImport = false;
              this.bindMdUnitsData();
              this.Editable = true;
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              return false;
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI           
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.showGrid = true;
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.showGrid = true;
          return false;
        })
    }
    else {
      // changes;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unsvdAdminChanges = false;
      this.unSavedChanges.emit(valToEmit);
      // end;
      return true;
    }
    return response;
  }
  exceldataToSend() {
    let MDUnitComponentArray = [];
    let ProductComponentArray = [];
    this.modifiedExcelMDUnitRows.forEach(node => {
      MDUnitComponentArray = node.MDUnits;
      ProductComponentArray.push({
        ProductId: node["ProductId"],
        ProductTitle: node["ProductTitle"],
        MDUnits: MDUnitComponentArray,
        ParentId: node["ParentId"],
        BusinessAreaId: Number(this.selectBusinessArea),
        BusinessLineId: Number(this.selectBusinessLine),
        IsActive: node.IsActive == 'Yes' ? 0 : 1,
        CreatedBy: node["CreatedBy"],
        CreatedOn: node["CreatedOn"],
        ModifiedBy: this.loggedInUser.UserId,
        ModifiedOn: new Date().toISOString()
      })
    });
    this.refObject = {
      MDUnitData: ProductComponentArray,
      UserId: this.loggedInUser.UserId,
    }
  }

  //Prepare the data which is to be send to database with required dto format
  dataToSend() {
    let MDUnitComponentArray = [];
    let ProductComponentArray = [];
    this.modifiedMDUnitRows.forEach(node => {
      let keys = Object.keys(node);
      let nodeRow = {}
      MDUnitComponentArray = []
      for (let key of keys) {
        // nodeRow = {}
        if (key.startsWith('ID')) {
          let splitkey = [];
          splitkey = key.split(':')

          MDUnitComponentArray.push({
            MDUnitID: splitkey[0].substr(2,) == "null" ? 0 : Number(splitkey[0].substr(2,)),
            Year: splitkey[1] == "null" ? 0 : Number(splitkey[1]),
            MDUnit: node[splitkey[1]] == null ? null : Number(node[splitkey[1]])
          })
        }
      }
      ProductComponentArray.push({
        ProductId: node["ProductId"],
        ProductTitle: node["ProductTitle"],
        MDUnits: MDUnitComponentArray,
        ParentId: node["ParentId"],
        BusinessAreaId: Number(this.selectBusinessArea),
        BusinessLineId: Number(this.selectBusinessLine),
        IsActive: node.IsActive == 'Yes' ? 0 : 1,
        CreatedBy: node["CreatedBy"],
        CreatedOn: node["CreatedOn"],
        ModifiedBy: this.loggedInUser.UserId,
        ModifiedOn: new Date().toISOString()
      })
    })

    this.refObject = {
      MDUnitData: ProductComponentArray,
      UserId: this.loggedInUser.UserId,
    }
  }

  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }
}

//use to format the cell according to german currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
}
