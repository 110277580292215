import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Help } from '../models/help'
import { HelpIconText } from '../models/HelpIconText'
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HelpService {
  constructor(private http: HttpClient) { }
  private urlGetbyId: string = environment.baseURL+ "api/Help/GetHelpTextByHelpId";
  private urlGet: string = environment.baseURL + "api/Help/GetAllHelpTexts";
  private urlUpdate: string = environment.baseURL+ "api/Help/InsertUpdateHelp";
  private urlGetbyText: string = environment.baseURL + "api/HelpIconText/GetAllHelpIconText";


  helpIdEvent=new EventEmitter();
  // This is used to get data from backend by passing HelpId;
  getDatabyId(helpId): Observable<Help> {
    let helpIdn = Number(helpId)
    return this.http.get<Help>(this.urlGetbyId + '?helpId=' + helpIdn).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //This is used to get data from backend 
  getAllData(): Observable<Help> {
    return this.http.get<Help>(this.urlGet).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //This is used to get data from backend for help text
  getAllText(): Observable<HelpIconText> {
    return this.http.get<HelpIconText>(this.urlGetbyText).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };

  //  This is used to update new help Data in Database;
  updateData(data): Observable<Help> {
    return this.http.post<Help>(this.urlUpdate, data).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
  emitHelpId(helpId){
    this.helpIdEvent.emit(helpId);
  }
}

