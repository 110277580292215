import { Component, OnInit } from '@angular/core';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { HttpClient } from '@angular/common/http';
import { PlcSummaryService } from 'src/app/services/plc-summary.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { Router } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';

let countryCode;
@Component({
  selector: 'app-plc-summaryanalysis-tgtscenario',
  templateUrl: './plc-summaryanalysis-tgtscenario.component.html',
  styleUrls: ['./plc-summaryanalysis-tgtscenario.component.css']
})
export class PlcSummaryanalysisTgtscenarioComponent implements OnInit {
  tgtRowData: any[];
  tgtColumnDefs: any;
  tgtdefaultColDef: any;
  tgtGridApi: any;
  tgtGridColumnApi: any;
  tgtRowHeight: any;
  tgtheaderHeight: any;
  tgtrowClassRules: any;
  loginUserDetails: any;
  ideaId: any;
  domLayout: any;
  getRowStyle: any;
  defaultColDef: any;
  rowStyle: any;
  solutionId: any;
  getResponse: any;
  errorMessage: any;
  headerYears: any;
  tgtGridStoredData: any;
  showTgtPlcAnalysis = false;
  autoGrouptgtCostColumnDef: any;
  getDataPath: any;
  aggRowData: any;
  groupDefaultExpanded: any;

  public sizeToFitRefresh: number = 0;
  constructor(private http: HttpClient,
    private sharedService: SharedServiceService,
    private plcSummaryService: PlcSummaryService,
    private localStorageService: LocalStorageService,
    public dialog: MatDialog, private router: Router, private menuService: MenuService) {

    this.tgtColumnDefs = [

      {
        headerName: '',
        field: 'RowGroup', rowGroup: true, hide: true,
        suppressSizeToFit: true
      },
      { headerName: 'Years11-20', headerTooltip: 'Years11-20' }
    ]


    this.tgtRowHeight = 30;
    this.tgtheaderHeight = 40;
    this.domLayout = 'autoHeight';
    this.autoGrouptgtCostColumnDef = {
      headerName: '',
      field: 'plctype',
      minwidth: 200, suppressSizeToFit: true,
      tooltipValueGetter: (params) => {

        return params.value;
      },
      cellClass: params => {
        if (!params.node.group) {
          return params.node.data.rownum == 5.00 || params.node.data.rownum == 6.00
            || params.node.data.rownum == 8.00 || params.node.data.rownum == 9.00
            || params.node.data.rownum == 12.00 || params.node.data.rownum == 13.00
            || params.node.data.rownum == 15.00 || params.node.data.rownum == 16.00
            || params.node.data.rownum == 18.00 || params.node.data.rownum == 19.00 ? 'plcGroupPadding1' : '';
        }
      },
      cellRendererParams: {
        suppressCount: true
      },// turn off the row count
      valueGetter: params => {
        if (!params.node.group) {
          if (params.node.data.rownum == 1.00 || params.node.data.rownum == 2.00 || params.node.data.rownum == 3.00) {
            return params.node.data.ComponentTitle;
          }
          else {
            return params.node.data.plctype;
          }
        }
        else {
          return params.value;
        }
      }
    };

    this.tgtrowClassRules = {
      'colorTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.50 || params.data.rownum == 0.60 || params.data.rownum == 0.70) {
            return true;
          }
        }
      },
      'colorSubTotal': (params: any) => {
        if (!params.node.group) {
          if (params.data.rownum == 0.80 || params.data.rownum == 3.80
            || params.data.rownum == 0.90 || params.data.rownum == 1.90 || params.data.rownum == 2.90 || params.data.rownum == 3.90 || params.data.rownum == 10.00
            || params.data.rownum == 20.00 || params.data.rownum == 21.00 || params.data.rownum == 22.00) {
            return true;
          }        
        }
      }
    }

    this.defaultColDef = {      
      suppressMovable: true,
      sortable: false,
    };

    this.getDataPath = function (data) {
      return data.RowGroup.split(',');
    };
  }

  check() {
    return false;
  }

  ngOnInit(): void {
    this.errorMessage = []; this.tgtGridStoredData = [];
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user) => {
        this.loginUserDetails = user;
      }
    );
    //Get IdeaId
    this.ideaId = this.localStorageService.get('ideaId');
    this.solutionId = MSolutionType.TargetScenario;
    //Get Country Code
    countryCode = this.localStorageService.get('countryCode');

    this.plcSummaryService.getPLCSummaryAnalysis(this.ideaId, this.solutionId).subscribe(
      (data: any) => {
        this.getResponse = data;
        let successCode = this.getResponse.StatusCode;
        if (successCode != 200) {
          this.showTgtPlcAnalysis = true;
          let errorMessage = serverMessage.serverErrorMessage;
          let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
          this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
          return false;
        }
        else if (successCode == 200) {
          if (this.getResponse.Data[0].HeaderYear != null && this.getResponse.Data[0].HeaderYear != "") {

            this.headerYears = this.getResponse.Data[0].HeaderYear.split(",")

            this.tgtGridStoredData = this.bindPLCSummaryAnalysisData(this.getResponse.Data);

            setTimeout(() => {
              this.setTgtGridData(this.headerYears);
            }, 1000)
          }
          else {
            this.showTgtPlcAnalysis = true;
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
            return false;
          }
        }
      }, (error) => {
        this.showTgtPlcAnalysis = true;
        this.errorMessage.push(error)
        this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
        return false;
      })
  }

  bindPLCSummaryAnalysisData(obj) {
    let rowData = [];
    let dataBind = {};
    let plcSummaryAnalysisData = [];
    this.aggRowData = [];
    plcSummaryAnalysisData = obj;
    plcSummaryAnalysisData.forEach(function (value, key) {
      dataBind = {
        rownum: value.rownum,
        ComponentId: value.ComponentId,
        ComponentTitle: value.ComponentTitle,
        plctype: value.plctype,
        Year1: this.getDecimalFixed(value.Year1),
        Year2: this.getDecimalFixed(value.Year2),
        Year3: this.getDecimalFixed(value.Year3),
        Year4: this.getDecimalFixed(value.Year4),
        Year5: this.getDecimalFixed(value.Year5),
        Year6: this.getDecimalFixed(value.Year6),
        Year7: this.getDecimalFixed(value.Year7),
        Year8: this.getDecimalFixed(value.Year8),
        Year9: this.getDecimalFixed(value.Year9),
        Year10: this.getDecimalFixed(value.Year10),
        Year11: this.getDecimalFixed(value.Year11),
        Year12: this.getDecimalFixed(value.Year12),
        Year13: this.getDecimalFixed(value.Year13),
        Year14: this.getDecimalFixed(value.Year14),
        Year15: this.getDecimalFixed(value.Year15),
        Year16: this.getDecimalFixed(value.Year16),
        Year17: this.getDecimalFixed(value.Year17),
        Year18: this.getDecimalFixed(value.Year18),
        Year19: this.getDecimalFixed(value.Year19),
        Year20: this.getDecimalFixed(value.Year20),
        Total: this.getDecimalFixed(value.Total),
        RowGroup: value.RowGroup,
        SubRowGroup: value.SubRowGroup
      }
      if (value.rownum != 0.90 && value.rownum != 1.90 && value.rownum != 2.90 &&
        value.rownum != 3.90 && value.rownum != 10.00) {
        rowData.push(dataBind);
      }
      if (value.rownum == 0.90 || value.rownum == 1.90 || value.rownum == 2.90 ||
        value.rownum == 3.90 || value.rownum == 10.00) {
        this.aggRowData.push(dataBind);
      }
    }.bind(this));
    return rowData;
  }


  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error });
  }

  getDecimalFixed(obj) {
    return obj != null && obj != "" ? new Intl.NumberFormat(countryCode).format(obj.toFixed()) : "0";
  }

  ontgtGridReady(params) {
    this.tgtGridApi = params.api;
    this.tgtGridColumnApi = params.columnApi;
    this.sizeToFitRefresh = 1;
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.tgtGridApi.sizeColumnsToFit();
    }
  }

  ontgtGridSizeChanged(params) {
    this.tgtGridApi = params.api;
    this.tgtGridApi.sizeColumnsToFit();
  }

  setTgtGridData(obj) {
    let columnDefsGroup = []
    let columnDefs = this.tgtGridApi.getColumnDefs();
    obj.forEach((element, index) => {
      columnDefsGroup.push({
        headerName: element, field: 'Year' + (index + 1), editable: false, minWidth: 51,
        tooltipValueGetter: (params) => {
          return params.value;
        },
        allowedAggFuncs: ['sum'],
        //aggFunc: calculateGroupTotal
        valueGetter: params => {
          // put the value in bold
          let currentCol = params.colDef.field;
          if (params.node.group) {
            let val = this.aggRowData.find(function (data) {
              if (data.plctype == params.node.key)
                return true;
            })
            if (val != undefined) {
              return val[currentCol];
            }
          }
          else {
            return params.data[currentCol]
          }
        },
        cellClass: params => {
          if (!params.node.group) {
            if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
              if (params.value != null && params.value != undefined && params.value != "")
              {
                if (Number(params.value.replace(/,/g, "")) > 0) {
                  return 'positive-num-color'
                }
                return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
              }
            }
          }
        },
      });
    });
    columnDefsGroup.forEach(function (colDef, index) {
      if (index > 9) {
        colDef.columnGroupShow = 'open';
      }
    });
    columnDefs.forEach(function (colDef, index) {
      if (colDef.headerName == "Years11-20") {
        colDef.children = columnDefsGroup;
      }
    });
    this.tgtGridApi.sizeColumnsToFit();
    columnDefs.push({
      headerName: 'Total', field: 'Total', editable: false, minWidth: 51,
      tooltipValueGetter: (params) => {
        return params.value;
      },
      valueGetter: params => {
        if (params.node.group) {
          let val = this.aggRowData.find(function (data) {
            if (data.plctype == params.node.key)
              return true;
          })
          if (val != undefined) {
            return val.Total;
          }
        }
        else {
          return params.data.Total;
        }
      },
      cellClass: params => {
        if (!params.node.group) {
          if (params.data.rownum == 20 || params.data.rownum == 21 || params.data.rownum == 22) {
            if (params.value != null && params.value != undefined && params.value != "")
            {
              if (Number(params.value.replace(/,/g, "")) > 0) {
                return 'positive-num-color'
              }
              return Number(params.value.replace(/,/g, "")) < 0 ? 'negative-num-color' : '';
            }
             
          }
        }
      },
    });
    this.tgtGridApi.setColumnDefs(columnDefs);
    this.tgtRowData = this.tgtGridStoredData;
    this.showTgtPlcAnalysis = true;
  }
}


