import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ICellEditorParams } from 'ag-grid-community';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { messages } from 'src/app/popUpMessages/messages';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
const moment = _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
  },
}
@Component({
  selector: 'app-grid-datepicker',
  templateUrl: './grid-datepicker.component.html',
  styleUrls: ['./grid-datepicker.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class GridDatepickerComponent implements AfterViewInit {
  private cellEditorParams: ICellEditorParams;
  private columnWidth: string;
  value: any;
  date: any;

  @ViewChild('picker', { read: MatDatepicker }) picker: MatDatepicker<Date>;

  constructor(private datePipe: DatePipe, public dialog: MatDialog) {
  }

  agInit(params: ICellEditorParams): void {
    this.cellEditorParams = params;
  }

  getValue(): any {
    if (this.date == null || this.date == undefined || this.date == "")     
      return null
    else {
      if (this.date < new Date().getFullYear()) {
        let error = messages.processCostPastDate;
        let valueAlert = { [error]: "Warning !" }
        this.dialog.open(AlertDialogComponent, { data: [valueAlert] })
      }
      return this.date;
    }
  }

  isCancelAfterEnd(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isPopup(): boolean {
    return false;
  }

  ngAfterViewInit() {
    this.picker.open();
  }

  onSelect(event) {
    this.cellEditorParams.api.stopEditing(false);
  }
  onClose() {
    this.cellEditorParams.api.stopEditing(false);
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    this.date = normalizedYear.year();
    datepicker.close();
    this.cellEditorParams.api.stopEditing(false);
  }

  clearStartDate() {
    this.date = "";
    this.onClose();
  }
  
  buttonDisabled() {
     setTimeout(() => {
      let periodButton = document.querySelectorAll('.mat-calendar-period-button');
      let arrowButton = document.querySelectorAll('.mat-calendar-arrow, .mat-calendar-invert');
      if (periodButton) {
        (<HTMLElement>periodButton[0]).setAttribute("disabled", "true");
      }
      if(arrowButton) {
        (<HTMLElement>arrowButton[0]).setAttribute("class", "arrowDisable");
      }
    })
  }
}