<app-progress-spinner *ngIf="(!showGrid && !showTree && !showopw ) || submitted"></app-progress-spinner>
<!-- Code for Measure Categorization -->

<div *ngIf="showopw" class="container" [formGroup]="categorizationForm">
  <div class="card" style="height: 163px;border: 0px solid #e6e6e6;width: 99.4%;">
    <!-- <div class="card-header labelimpact">Measure Categorization</div> -->
    <div class="label-header" style="height: 38px;margin-top: 0px; border-radius: 3px;">
      <label class="label-headercolor" style="white-space: normal;">
        Measure Categorization
      </label>
    </div>
    <input type="hidden" id="hdnCategorizationId" formControlName="CategorizationId" />
    <input type="hidden" id="hdnIdeadId" formControlName="IdeadId" />

   <div class="card-body" style="padding-top: 0px; padding-left: 10px">
      <div class="form-row">

        <div class="form-group col-md-3">
          <label for="ddlMeasureType" class="label">Measure Type</label>
          <button title={{MeasureTypeHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <select class="form-control catInvalid" formControlName="MeasureTypeId" aria-label="MeasureType">
            <option value="">Please select</option>
            <option *ngFor="let mType of measureType.Data" [value]="mType.MeasureTypeId">
              {{ mType.MeasureTypeTitle }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="ddlMeasureCategory" class="label">Measure Category</label>
          <button title={{MeasureCategoryHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <select id="ddlMeasureCategory" aria-label="MeasureCategory" class="form-control catInvalid"
            formControlName="MeasureCategoryId">
            <option value="">Please select</option>
            <option *ngFor="let mCat of measureCategory.Data" [value]="mCat.MeasureCategoryId">
              {{ mCat.MeasureCategoryTitle }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="ddlCurrency" class="label">Currency</label>
          <button title={{CurrencyHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button><br>
          <input type="text" formControlName="CurrencyId" [(target)]="masterCurrencyData" minlength="3"
            (input)="onSearchChange($event.target.value)" placeholder="Currency" [matAutocomplete]="auto"
            class="ttmp2 catInvalid" (focusout)="focusOutFunction($event.target.value)">
          <mat-autocomplete #auto="matAutocomplete">
            <ng-container *ngFor="let mCur of filtered; let i = index">
              <mat-option id=matdrop [value]="mCur.CurrencyCode+' - '+mCur.Currency"
                (onSelectionChange)="onSelCurrency($event, mCur.CurrencyId)"
                title={{mCur.CurrencyCode}}-{{mCur.Currency}}>
                {{ mCur.CurrencyCode }} - {{ mCur.Currency
                }}</mat-option>
              <mat-option id=matdrop *ngIf="i==2" disabled="disabled">
                <hr style="border: solid black;
                border-width: 0.05556rem 0 0;
                clear: both;
                margin: 1.66667rem 0 1.61111rem;
                height: 0;">
              </mat-option>
            </ng-container>
          </mat-autocomplete>
        </div>
        <div class="form-group col-md-3">
          <label for="inputStartDate" class="label">Start date (of saving impact)</label>
          <button title={{StartDateHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <div>
            <input class="ttmp catInvalid" type="text" aria-label="Datepicker" [matDatepicker]="picker"
              formControlName="StartDate" placeholder=" mm/dd/yyyy" style="
              width: 85%;
          "/>
            <mat-datepicker-toggle class="ttmp1" matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="ddlCostImpact" class="label">Cost Impact</label>
          <button title={{CostImpactHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <select id="ddlCostImpact" class="form-control catInvalid" formControlName="CostImpactId"
            aria-label="costImpact">
            <option value="">Please select</option>
            <option *ngFor="let cImpact of costImpact.Data" [value]="cImpact.CostImpactId">
              {{ cImpact.CostImpactTitle }}
            </option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label for="ddlAssessment" class="label">Product Lifecycle Cost assessment</label>
          <button title={{PLCAssesmentHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <select (change)="checkPlcStatusChange($event)" id="ddlAssessment" aria-label="Assessment"
            class="form-control catInvalid" formControlName="IsPlcrequired">
            <option value="">Please select</option>
            <option *ngFor="let cAssessment of costAssessment" [value]="cAssessment.id">
              {{ cAssessment.title }}
            </option>
          </select>


        </div>
        <div class="form-group col-md-4">
          <label for="ddlStatus" class="label">Status</label>
          <button title={{StatusHelpText}} (click)="false">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="12" height="12"
              style="margin-bottom:2px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
          <select id="ddlStatus" class="form-control catInvalid" aria-label="status" formControlName="MeasureStatusId">
            <option value="">Please select</option>
            <option *ngFor="let sts of status.Data" [value]="sts.MeasureStatusId">
              {{ sts.MeasureStatusTitle }}
            </option>
          </select>
        </div>
      </div>
    </div> 
  </div>
</div>


<!-- Code for OPW Measure Classification -->
<div *ngIf="showGrid && showTree" class="container">
  <div style="width: 100%; margin-left: -2px;margin-top: -16px;" class="row">
    <div style="width: 64%;height: 88px; float: left; margin-left: 1px;" >
      <div class="label-header" style=" border-radius: 3px;">
        <label class="label-headercolor" style="padding-bottom: 0px">Impacted Organization (Click
          <button aria-label="Add" (click)="AddNewRowImpact()" [disabled]="!isEditable"
            [ngClass]="{ 'click-not-allowed': !isEditable }">
            <img alt="Plus Icon" src="../assets/icons/Plus.svg" width="25" height="25" />
          </button>
          to add new item)</label>
          <button title={{ImpactedOrganizationtext}} (click)="false" style="padding-left: 4px;">
            <img alt="helpIcon" src="../assets/icons/information.svg" width="14" height="14"
              style="margin-bottom:7px; border:1px #faa500 solid;background-color:#faa500; border-radius:50%">
          </button>
      </div>

      <ag-grid-angular *ngIf="showGrid" id="orgGrid" style="width: 100%; border: 1px solid #e6e6e6;"
        class="ag-theme-alpine" [rowData]="orgRowData" [domLayout]="domLayout" [columnDefs]="orgColumnDefs"
        [defaultColDef]="defaultColDef" (rowClicked)="onRowClicked($event)" [gridOptions]="orgGridOptions"
        [popupParent]="popupParent" (gridReady)="OrggridReady($event)" (cellValueChanged)="onCellValueChanged($event)"
        (gridSizeChanged)="onGridSizeChanged($event)" [frameworkComponents]="frameworkComponents"
        [enableBrowserTooltips]="true" (columnResized)="onColumnResized($event)"
        (columnVisible)="onColumnVisible($event)">
      </ag-grid-angular>
    </div>

    <div style="width: 34.9%; float: right; margin-left: 10.1px">
      <div class="label-header" style="height: 34.66px; border-radius: 3px;">
        <label class="label-headercolor" style="white-space: normal;">
          Impacted Product
        </label>
      </div>
      <div id="product" class="div-tree">
        <mat-tree *ngIf="showTree" [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree"
          role="tree" style="border-radius: 5px;">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <ul role="list">
              <li class="mat-tree-node">
                <!-- use a disabled button to provide padding for tree leaf -->
                <button aria-label="checkbox" mat-icon-button disabled></button>
                <mat-checkbox class="checklist-leaf-node checkbox-color" [disabled]="!isEditable"
                  (change)="todoItemSelectionToggle($event.checked,node,$event)" [checked]="node.selected">{{node.name}}
                </mat-checkbox>
              </li>
            </ul>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
            <ul role="list">
              <li>
                <div class="mat-tree-node">
                  <button aria-label="Arrows" mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
                    <img alt="Right_Arrow" *ngIf="treeControl.isExpanded(node)==false"
                      src="../assets/icons/RightArrow.svg" height="15" width="15" style="margin-bottom: 3px;">
                    <img alt="Down_Arrow" *ngIf="treeControl.isExpanded(node)==true" src="../assets/icons/DownArrow.svg"
                      height="15" width="15" style="margin-bottom: 3px;">
                  </button>
                  <mat-checkbox [checked]="node.selected" class="checkbox-color" [disabled]="!isEditable"
                    [indeterminate]="node.indeterminate && !node.selected"
                    (change)="todoItemSelectionToggle($event.checked,node,$event)">{{node.name}}</mat-checkbox>
                </div>

              </li>
            </ul>
            <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="list">
              <li>
                <ng-container matTreeNodeOutlet></ng-container>
              </li>
            </ul>
          </mat-nested-tree-node>
        </mat-tree>
      </div>


    </div>
  </div>
  <div *ngIf="showGrid && showTree" class="row row-height remark_style">
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea id="txtareaRemarks" style="font-size: 0.7rem; width: 99%" aria-label="Remarks_Target"
        [(ngModel)]="remark" [disabled]="!isEditable" (input)="checkRemarkChange()" cols="75" rows="3" maxlength="1500"
        wrap="hard"></textarea>
    </div>
  </div>

  <div *ngIf="!hideButton" class="row" style="margin-top: 100px">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle" style="float: right; margin-right: 5px; margin-bottom: 20px">
        <button class="button button--small" (click)="onClickCancel()">
          Cancel & Exit</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">
          Back</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">
          Next</button>&nbsp;
        <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)"
          type="submit">
          Save & Exit
        </button>
      </div>
    </div>
  </div>
</div>
<!-- error block -->
<div>
  <span *ngIf="
      categorizationForm.get('MeasureTypeId').invalid;
      else other_MeasureType
    ">
    {{ getErrorMessage("Its a Required field", "Measure Type", "false") }}</span>
  <ng-template #other_MeasureType>{{
    getErrorMessage("", "Measure Type", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('MeasureCategoryId').invalid;
      else other_MeasureCategory
    ">
    {{
    getErrorMessage("Its a Required field", "Measure Category", "false")
    }}</span>
  <ng-template #other_MeasureCategory>{{
    getErrorMessage("", "Measure Category", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('CurrencyId').invalid;
      else other_Currency
    ">
    {{
    getErrorMessage("Its a Required field", "Currency", "false")
    }}</span>
  <ng-template #other_Currency>{{
    getErrorMessage("", "Currency", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="categorizationForm.get('StartDate').invalid; else other_StartDate">
    {{ getErrorMessage("Its a Required field", "StartDate", "false") }}</span>
  <ng-template #other_StartDate>{{
    getErrorMessage("", "StartDate", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('CostImpactId').invalid;
      else other_CostImpact
    ">
    {{ getErrorMessage("Its a Required field", "Cost Impact", "false") }}</span>
  <ng-template #other_CostImpact>{{
    getErrorMessage("", "Cost Impact", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('MeasureStatusId').invalid;
      else other_MeasureStatus
    ">
    {{ getErrorMessage("Its a Required field", "Status", "false") }}</span>
  <ng-template #other_MeasureStatus>{{
    getErrorMessage("", "Status", "true")
    }}</ng-template>
</div>

<div>
  <span *ngIf="
      categorizationForm.get('IsPlcrequired').invalid;
      else other_IsPlcrequired
    ">
    {{
    getErrorMessage(
    "Its a Required field",
    "Product Lifecycle Cost assessment",
    "false"
    )
    }}</span>
  <ng-template #other_IsPlcrequired>{{
    getErrorMessage("", "Product Lifecycle Cost assessment", "true")
    }}</ng-template>
</div>