import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { FxRatesService } from 'src/app/services/fx-rates.service';

let countryCode;
@Component({
  selector: 'app-fxtranslation',
  templateUrl: './fxtranslation.component.html',
  styleUrls: ['./fxtranslation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FxtranslationComponent implements OnInit {

  public rowData: any = [];
  public defaultColDef: any;
  public columnDefs: any = [];
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowHeight: any;
  public headerHeight: any;
  public Editable: boolean = true;
  public alertErrorBox = [];
  public errorMessage = []
  public loggedInUser: any;
  public getResponse: any;
  public frameworkComponents: any;

  public sizeToFitRefresh: number = 0;
  public showGrid = false;
  public submitted = false;

  submitResponse: any;
  refObject: any;
  receivedData: any;
  headerYears: any = [];
  startYear: any = "";
  unsvdParams: boolean = false;
  importExcelData: any;
  hideExportImport: boolean = true;
  hideMonthDropdown: boolean = true;
  masterCurrencyDetails: any = [];
  allExchangeRatesData: any = [];

  selectedYear = "";
  monthDropdownValues = ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"]
  selectedMonth = "";

  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();

  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";

  constructor(
    private fxRatesService: FxRatesService,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService) {
    this.frameworkComponents = { doublingEditor: DoublingEditorComponent };
  }

  ngOnInit(): void {
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        //getting business area and line access of user
        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
        }
        this.showGrid = true;
      }
    )
    countryCode = this.localStorageService.get('countryCode');
    this.getMasterCurrencyData();
    this.getAllExchangeRatesData();
  }

  getMasterCurrencyData() {
    this.fxRatesService.GetMasterCurrency().subscribe((data) => {
      this.getResponse = data;
      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedData = data.Data;

        this.masterCurrencyDetails = this.receivedData;
      }
    });
  }

  getAllExchangeRatesData(){
    this.showGrid = false;
    this.rowData = [];
    this.fxRatesService.GetFxRatesDetails().subscribe((data) => {
      this.getResponse = data;

      let successCode = this.getResponse.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.getResponse.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedData = data.Data;
        if (this.receivedData == "") {
          this.rowData = [];
          this.showGrid = true;
          return;
        }
        this.allExchangeRatesData = this.receivedData;
        const maxYear = Math.max(...this.allExchangeRatesData.map(o => Number(o.Year)));
        const minYear = Math.min(...this.allExchangeRatesData.map(o => Number(o.Year)));
        for(let i = 0; i<=(maxYear - minYear); i++){
          this.headerYears.push(minYear+i);
        }
        this.loadExchangeRateGrid();
      }
    });
  }

  updateExchangeRate() {
    if(this.selectedYear != ""){
      let filteredExchangeRates = [];
      filteredExchangeRates = this.allExchangeRatesData.filter(row => row.Year == Number(this.selectedYear));
      this.rowData = filteredExchangeRates;
    }
    else{
      this.rowData = [];
    }
    this.loadExchangeRateGrid();
  }

  // Import() {
  //   if (this.selectedCategory == this.categories[0]) {
  //     this.importActualExcel(event);
  //   }
  //   else {
  //     if (this.selectedCategory == this.categories[1] || this.selectedCategory == this.categories[2]) {
  //       this.importForcastPlanningExcel(event);
  //     }
  //     else {
  //       this.toast.notify(serverMessage.SelectCategory, "error");
  //     }
  //   }
  // }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', {
      month: 'short',
    });
  }
  
  loadExchangeRateGrid() {

    this.columnDefs = [
      {
        headerName: "Id",
        field: "Id",
        tooltipField: "Id",
        headerTooltip: "Id",
        editable: false,
        hide: true
      },
      {
        headerName: "Currency",
        field: "Currency",
        sortable: true,
        tooltipField: "Currency",
        headerTooltip: "Currency",
        editable: false,
        hide: false
      },
      {
        headerName: "Country",
        field: "Country",
        tooltipField: "Country",
        headerTooltip: "Country",
        editable: false,
        hide: false
      }
    ]

    this.monthDropdownValues.forEach(month => {
      this.columnDefs.push({
        headerName: month,
        field: String(month),
        headerTooltip: month,
        tooltipField: month,
        editable: false,
        minWidth: 50,
        filter: 'agSetColumnFilter',
        hide: false
      })
    })

    this.defaultColDef = {
      editable: true,
      suppressMovable: true,
      singleClickEdit: true,
    };
    this.domLayout = 'autoHeight';
    this.showGrid = true;

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi
    this.sizeToFitRefresh = 1;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onDisplayedColumnsChanged(event) {
    if (this.sizeToFitRefresh != 0) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}

//use to format the cell according to german currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
}
