import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PeoplePickerService {

  constructor(private http: HttpClient) { }

  headerDict = {
    'ConsistencyLevel': 'eventual',
  }

  requestOptions = {
    headers: new HttpHeaders(this.headerDict)
  };


  getUsers(input: string) {
    return this.http.get(`https://graph.microsoft.com/v1.0/users?$search="mail:${input}"&$select=displayname,mail,department,givenName,surname`, this.requestOptions);
    
  }
}
