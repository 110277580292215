<!-- Used  as a normal dialog box-->
<div
  style="white-space: pre-line; line-height: 1.5; font-size: 14px; font-family: Calibri,sans-serif;"
>
  <mat-dialog-content class="error">{{ data.message }}</mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="ok-button"
    >
      {{ data.okBtn }}
    </button>
    <button
      mat-button
      [mat-dialog-close]="false"
      *ngIf="data.cancelBtn"
      class="cancel-button"
    >
      {{ data.cancelBtn }}
    </button>
  </mat-dialog-actions>
</div>
