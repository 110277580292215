import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { LocalStorageService } from '../../services/local-storage.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  [x: string]: any;
  public passedData = 0;
  isMasterDataManagementVisible: boolean = false;
  public user: any;
  constructor(
    private Route: Router,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.localStorageService.set('ideaId', null)
    this.localStorageService.remove('countryCode');
    this.localStorageService.set('countryCode', 'en-US');
    this.sharedService.loggedInUser.subscribe((user: any) => {
      if (user != null) {
        this.user = user;
        if (user.RoleId == 1) {
          this.isMasterDataManagementVisible = true
        }
        else {
          this.isMasterDataManagementVisible = false
        }
      }
      else {
        this.Route.navigate(['/welcome']);
      }
    })
  }

  gotoCreateIdea() {
    this.localStorageService.remove('ideaId')
    this.localStorageService.set('ideaId', null)
    this.Route.navigate(["/idea"]);
  }

  gotoManageIdea() {
    this.Route.navigate(['/manageIdea']);
  }

  gotoMasterData() {
    this.Route.navigate(['/manageData'])
  }

  gotoProcessGuide() {  
    let url = "https://healthineers.sharepoint.com/sites/ProductivityEco-SystemAT/SitePages/Productivity-Eco-System.aspx";
    window.open(url, "_blank", "noopener");
  }

  gotoLegalTerms() {
    let url = "https://www.siemens-healthineers.com/terms-of-use"
    window.open(url, "_blank", "noopener");
  }
}
