import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { Idea } from '../models/idea'
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { IdeaProductivity } from '../models/IdeaProductivity';

@Injectable({
  providedIn: 'root'
})
export class IdeaformService {

  constructor(private http: HttpClient) { }
  private urlGet: string = environment.baseURL+ "api/Idea/GetIdeaByIdeaId";
  private urlGetIdeaProductivity: string = environment.baseURL+ "api/Idea/GetCommonDataForIdea";
  private urlPost: string = environment.baseURL+ "api/Idea/InsertUpdateIdea";
  private urlPostCopySolution: string = environment.baseURL+ "api/Idea/CopySolution";

  // This is used to get data from backend by passing IdeaId;
  getData(ideaId): Observable<Idea> {
    return this.http.get<Idea>(this.urlGet + '?ideaId=' + ideaId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  getUserInfo(gid): Observable<any> {
    return this.http.get(environment.baseURL + "api/Idea/GetUserInfo" + "?gid=" + gid).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  // This is used to get Idea Productivity and MarqueMessage data from backend by passing IdeaId;
  getIdeaProductivityData(ideaId): Observable<IdeaProductivity> {
    return this.http.get<IdeaProductivity>(this.urlGetIdeaProductivity + '?ideaId=' + ideaId).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };

  //  This is used to post new Idea Data and also updates existing Idea present in database;
  postData(data): Observable<Idea> {
    return this.http.post<Idea>(this.urlPost, data).pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  //This is used to post ideaid and solutionid to swap the datas of Optional Solutions with solution proposal
  postDataCopySolution(ideaId, solutionId, userId){
    return this.http.post(this.urlPostCopySolution + '?ideaId=' + ideaId+ '&solutionId=' + solutionId+ '&userId=' + userId, "").pipe(
      retry(1),
      catchError(this.handleError)
    );
  };
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
};
