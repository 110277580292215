import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';

LicenseManager.setLicenseKey('CompanyName=Comparex AG (Leipzig)_on_behalf_of_Siemens Healthcare GmbH,LicensedApplication=Productivity Eco-System @ AT,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=0,AssetReference=AG-014183,ExpiryDate=9_March_2022_[v2]_MTY0Njc4NDAwMDAwMA==99a141bab45077418db1daa03b43c9de');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
