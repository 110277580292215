import { AfterViewInit, Component, ViewChild, ViewContainerRef } from "@angular/core";


@Component({
  selector: 'numeric-cell',
  template: `<input #input (keydown)="onKeyDown($event)" id="myInput" autofocus [(ngModel)]="value" 
  style="width: 100%;height:100%;border: none !important;outline-color:#D3D3D3;position:absolute">`
})

export class NumericEditorComponent implements AfterViewInit {
  private params: any;
  public value: any;
  private cancelBeforeStart: boolean = false;

  @ViewChild('input', { read: ViewContainerRef }) public input;


  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    // only start edit if key pressed is a number, not a letter
    this.cancelBeforeStart = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
  }

  getValue(): any {
    if(isNaN(this.value)){
      this.value=0;
      return this.value
    }
    if(this.value ==0 ){
      return this.value
    }
    return parseInt(this.value) == null ? null : (Math.round(this.value * 100) / 100).toFixed(0);
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  //isCancelAfterEnd(): boolean {
  //  return this.value > 1000000;
  //};

  onKeyDown(event): void {
    if (!this.isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }
    document.getElementById("myInput").addEventListener("paste", (event) => {
      let pastedText = event.clipboardData.getData('text');
      if (isNaN(Number(pastedText)) || (Math.round(Number(pastedText) * 100) / 100).toFixed(0) !== pastedText){
        event.preventDefault();
      }
    });
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    setTimeout(() => this.input.element.nativeElement.focus());
  }

  private getCharCodeFromEvent(event): any {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
  }

  private isCharNumeric(charStr): boolean { 
    if(this.params.data.CostSavingDetailId>=0){
      return !!/^\d*$/.test(charStr) || charStr === 'Enter' || charStr === 'Backspace' || charStr === 'Tab'|| charStr === 'Delete' || charStr === 'ArrowLeft' || charStr === 'ArrowRight' || charStr === 'Escape' ;
    }
    return !!/^\d*$/.test(charStr) || charStr === 'Enter' || charStr === 'Backspace' || charStr === 'Tab'|| charStr === 'Delete' || charStr === 'ArrowLeft' || charStr === 'ArrowRight' || charStr === '-' || charStr === 'Escape' 
    || charStr === 'v'|| charStr === 'V' || charStr === 'ctrl'|| charStr === 'A'|| charStr === 'a'|| charStr === 'c'|| charStr === 'C';
  }

  private isKeyPressedNumeric(event): boolean {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

}
