
import { AfterViewInit, Component, ViewChild, ViewContainerRef, } from '@angular/core';

@Component({
  selector: 'editor-cell',
  template: `<input
    type="number" onkeydown="return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189" 
    [(ngModel)]="value" 
    #input
    style="width: 100%;height:100%; border: none !important;outline-color:#D3D3D3;position:absolute"
  />`,
})
export class DoublingEditorComponent implements AfterViewInit {
  public params: any;
  public value: any;

  @ViewChild('input', { read: ViewContainerRef })
  public input: ViewContainerRef;

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => this.input.element.nativeElement.focus());
  }

  agInit(params: any): void {
    this.params = params;
    if (params.value == null || params.value == "0" || params.value == 0) {
      // Uncomment to bring '0.00' format on editing the cell;
      // this.value = 0;
      // this.value = parseFloat(this.value).toFixed(2)
      this.value = null;
    }
    else {
      this.value = parseFloat(this.params.value)
    }
  }

  /* Component Editor Lifecycle methods */
  // the final value to send to the grid, on completion of editing
  getValue() {
    if (this.value == null || this.value == 0) {
      return null;
    }
    else {
      if (this.params.data.OpwproductivityId > 0) {
        return this.value;
      }
      else {
        return parseFloat(this.value).toFixed(2);
      }
    }
  }
}
