<div class="container">
  <app-progress-spinner *ngIf="!showGrid"></app-progress-spinner>
  <div class="label-header">
    <label class="label-headercolor">{{ scenarioHeader }} </label>
  </div>
  <div class="label-header">
    <label class="label-headercolor"
      >{{subProcessTitle}} Sub Process (Add New Sub Process Item
    </label>
    <button
      [disabled]="!isEditable"
      [ngClass]="{ 'click-not-allowed': !isEditable }"
      (click)="addNewRow()"
      title="Add"
    >
      <img
        alt="plusIcon"
        src="../assets/icons/Plus.svg"
        height="25"
        width="25"
        class="icon-add"
      />
    </button>
    <label class="label-headercolor">)</label>
  </div>
  <ag-grid-angular
    *ngIf="showGrid"
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine"
    id="subdetails"
    [rowData]="subProcessCostRowData"
    [columnDefs]="columnDefs"
    [rowClassRules]="rowClassRules"
    [domLayout]="domLayout"
    
    [frameworkComponents]="frameworkComponents"
    [defaultColDef]="defaultColDef"
    [headerHeight]="headerHeight"
    [enableBrowserTooltips]="true"
    [popupParent]="popupParent"
    (cellValueChanged)="onCellValueChangedSubProcess($event)"
    (gridReady)="onGridReady($event)"
    (gridSizeChanged)="onGridRefSubProcessCostSizeChanged($event)"

    [getRowHeight]="getRowHeight"
    
    (columnResized)="onColumnResized($event)"
    (columnVisible)="onColumnVisible($event)"
  >
  </ag-grid-angular>
  <!-- Remark Changes -->
  <div *ngIf="showGrid" class="row row-height remark_style"  >
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea
        id="txtareaRemarks"
        style="font-size: 0.7rem; width: 100%"
        aria-label="Remarks_Target"
        [(ngModel)]="remark"
        [disabled]="!isEditable"
        (input)="checkRemarkChange()"
        cols="75"
        rows="3"
        maxlength="1500"
        wrap="hard"
      ></textarea>
    </div>
  </div>
  <div class="row"  style="margin-top: 100px">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle">
        <button
          class="button button--small"
          [disabled]="submitted"
          (click)="onClickCancel()"
        >
          Cancel</button
        >&nbsp;
        <button
          class="button button--small"
          [disabled]="submitted"
          (click)="onClickBack()"
        >
          Back</button
        >&nbsp;
      </div>
    </div>
  </div>
</div>
