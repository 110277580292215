import { Component } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { BehaviorSubject } from 'rxjs';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';

interface Node {
  MenuId: number;
  MenuTitle: string;
  Sequence: number;
  ParentId: number
  Route: string;
  RoleId: number;
  HelpId: number;
  IsDisplay: boolean;
  Children?: Node[];
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})

export class NavComponent {
  defaultExpand: number;
  treeControl = new NestedTreeControl<Node>(node => node.Children);
  data: any;
  dataSource: any;
  flatdata: any = [];
  _flatdata: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  hasChild = (_: number, node: Node) => !!node.Children && node.Children.length > 0;

  constructor( private menuSvc: MenuService, 
    private sharedService: SharedServiceService) {
    setTimeout(() => {
      this.loadMenu();
    }, 100)
  }

  loadMenu() {
    this.menuSvc.treeData.subscribe(
      res => {
        this.data = res;
        this.dataSource = this.data;
        this.dataSource.forEach((node: any) => {
          this.setParent(node, null)
        })        
        this.flatdata = [];
        this.getFlatData(this.dataSource);
        //To open the tree node when the corresponding  router url is hit
        this.sharedService.routeURL.subscribe(url => {
          if (url != "") {
            this.flatdata.forEach((node: any) => {
              if (url == "/" + node.Route) {
                this.defaultExpand = node.MenuId
              }
            })
            this.expand(this.flatdata, this.defaultExpand);
          }
        })        
      }
    )
  }

  // To set the parent node of a node
  setParent(data: any, parent: any) {
    data.parent = parent;
    if (data.Children) {
      data.Children.forEach((x: any) => {
        this.setParent(x, data);
      });
    }
  }

  /*To expand all the parents of a particular node without expanding the node itself*/
  expand(data: any, id: number): any {
    data.forEach((node: any) => {
      if (node.MenuId == id) {
        if (node.MenuId != this.defaultExpand) {
          this.treeControl.expand(node);
        }
        if (node.parent) {
          this.expand(this.flatdata, node.parent.MenuId);
        }
      }
    })
    setTimeout(() => {
      this.changeRole();
    }, 1000)
  }

  /* To make the nested tree data flat */
  getFlatData(dataSource: any) {
    dataSource.forEach((node: any) => {
      if (!node.Children) {
        this.flatdata.push(node)
      }
      else if (node.Children) {
        this.flatdata.push(node);
        this.getFlatData(node.Children)
      }
    })
  }
  
  changeRole() {
    let parentNode = document.querySelectorAll(".roleGroup");
    if (parentNode != null) {
      parentNode.forEach((value) => {
        if (value) {
          value.setAttribute('role', 'group');
          value.removeAttribute('aria-level')
        }
      })
    }
  }
}
