import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MMeasureType } from '../models/m-measure-type';
import { MMeasureCategory } from '../models/m-measure-category';
import { Categorization } from '../models/categorization';

import { McostImpact } from 'src/app/models/mcost-impact';
import { MMeasureStatus } from 'src/app/models/m-measure-status';
import { Mcommodity } from 'src/app/models/mcommodity';
import { MSubCommodity } from 'src/app/models/m-sub-commodity';
import { environment } from '../../environments/environment';
import { EnumMasterType } from '../enum/MasterType';
import { MOPWFilter } from '../models/mOpwFIlter';
import { MOPWSubFilter } from '../models/mOpwSubFilter';
import { MOPWTag } from '../models/mOpwTag';

@Injectable({
  providedIn: 'root'
})

export class CategorizationService {

  private msgService = new BehaviorSubject<string>(" ")
  public currentMsg = this.msgService.asObservable();

  constructor(private http: HttpClient) { }
  private GetAllData: string = environment.baseURL + "api/Master/GetAllData";

  private urlGetCategorizationData: string = environment.baseURL + "api/Categorization/GetCategorizationByIdeaId";
  private urlPostCategorizationData: string = environment.baseURL + "api/Categorization/InsertUpdateCategorization";

  private urlGetPageLock: string = environment.baseURL + "api/Categorization/GetPageLockStatus";
  private urlGetActuals: string = environment.baseURL + "api/Categorization/GetPlcSummaryActualUnitData";

  changeMsg(value: string) {
    this.msgService.next(value);
  }
  onPageLoad(value: string) {
    this.msgService.next(value);
  }
  getMeasureType(businessAreaId: number, businessLineId: number): Observable<MMeasureType[]> {
    return this.http.get<MMeasureType[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.MeasureType + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId);
  }

  getMeasureCategory(businessAreaId: number, businessLineId: number): Observable<MMeasureCategory[]> {
    return this.http.get<MMeasureCategory[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.MeasureCategory + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId);
  }

  getCostImpacts(businessAreaId: number, businessLineId: number): Observable<McostImpact[]> {
    return this.http.get<McostImpact[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.CostImpact + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId);
  }

  getMeasureStatus(businessAreaId: number, businessLineId: number): Observable<MMeasureStatus[]> {
    return this.http.get<MMeasureStatus[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.MeasureStatus + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getMCommoditiyHierarchy(businessAreaId: number, businessLineId: number): Observable<Mcommodity[]> {
    return this.http.get<Mcommodity[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.Commodity + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getMSubCommoditiyHierarchy(businessAreaId: number, businessLineId: number): Observable<MSubCommodity[]> {
    return this.http.get<MSubCommodity[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.SubCommodity + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getMOPWFilter(businessAreaId: number, businessLineId: number): Observable<MOPWFilter[]> {
    return this.http.get<MOPWFilter[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.OPWFilter + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getMOPWSubFilter(businessAreaId: number, businessLineId: number): Observable<MOPWSubFilter[]> {
    return this.http.get<MOPWSubFilter[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.OPWSubFilter + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getMOPWTag(businessAreaId: number, businessLineId: number): Observable<MOPWTag[]> {
    return this.http.get<MOPWTag[]>(this.GetAllData + '?masterTypeId=' + EnumMasterType.OPWTag + "&businessAreaId=" + businessAreaId + "&businessLineId=" + businessLineId)
  }

  getCategorizationDataById(ideaId): Observable<Categorization> {
    let _ideadId = Number(ideaId)
    return this.http.get<Categorization>(this.urlGetCategorizationData + '?ideaId=' + _ideadId);
  }

  postCategorizationData(data): Observable<Categorization> {
    return this.http.post<Categorization>(this.urlPostCategorizationData, data);
  };

  getPageLockStatus(ideaId: number, businessAreaId: number, businessLineId: number): Observable<any> {
    return this.http.get<any>(this.urlGetPageLock + '?ideaId=' + ideaId + '&businessAreaId=' + businessAreaId + '&businessLineId=' + businessLineId);
  }

  getPLCSummaryActualUnitData(ideaId: number)
  {
    return this.http.get<any>(this.urlGetActuals + '?ideaId=' + ideaId);
  }
}


