<form
  [formGroup]="multiSelectPeoplePickr"
  style="width: 100%; position: absolute"
  (click)="autofocus()"
>
  <mat-form-field appearance="legacy" class="myPanel-contact no-underline">
    <mat-select
      class="mat-select-contact"
      formControlName="multiSelectPeoplePickr"
      multiple
      (closed)="clearSearch()"
      (click)="autofocus()"
      [compareWith]="compare"
    >
      <mat-form-field
        [floatLabel]="'never'"
        class="no-underline no-wrapper sticky-search-contact"
      >
        <mat-label class="search-label-contact">Search Email...</mat-label>
        <input
          style="
            padding-left: 10px;
            position: relative;
            top: -2px;
            border: 1px solid blue;
            box-shadow: 0px 0px 6px;
            border-radius: 2px;
            width: 100%;
            height: 20px;
            bottom: 4px;
            align-items: center;
          "
          matInput
          #myInput
          type="text"
          [formControl]="searchInput"
          (input)="fetchUserData($event, false)"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-option class="mat-option-hide-contact" disabled></mat-option>
      <mat-select-trigger
        style="white-space: normal; font-size: 0.7rem; top: -10px"
      >
        <div
          style="height: 40px; width: 100%; overflow-y: scroll;"
          *ngIf="dataSource.length !== 0"
        >
          <ul style="margin-bottom:2px">
            <li *ngFor="let i of userData" class="dropdown-style">
              {{ i }}
            </li>
          </ul>
        </div>
      </mat-select-trigger>
      <mat-option
        class="font-control-contact"
        *ngFor="let user of searchedDataMatch; let i = index"
        [value]="user"
        (click)="getDetails(user)"
        id="user{{ i }}"
      >
        {{ user.email }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
