<app-progress-spinner *ngIf="!showRefPlcAnalysis"></app-progress-spinner>
<div class="container" style="margin-bottom: 20px;">
  
        <div class="label-header">
          <label class="label-headercolor">Reference Scenario </label>
      </div>
     
        <ag-grid-angular style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        [headerHeight]="refheaderHeight"
        [suppressRowTransform]="true"
        [getRowStyle]="getRowStyle"
        [rowClassRules]="refrowClassRules"
        [rowHeight]="refRowHeight"
        [rowStyle]="rowStyle"
        [rowData]="refRowData"
        [columnDefs]="refColumnDefs"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupRefCostColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded"
        [suppressAggFuncInHeader]="true"
        [enableBrowserTooltips]="true"
        [domLayout]="domLayout"
        (gridReady)="onrefGridReady($event)"
        (gridSizeChanged)="onrefGridSizeChanged($event)"
        (rowClicked)="onRowClicked($event)"

        (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
    >
    
    </ag-grid-angular>

    <app-plc-summaryanalysis-tgtscenario></app-plc-summaryanalysis-tgtscenario>
    <div class="row">
      <div class="offset-4 col-md-8">
          <div class="buttonStyle">
            <button class="button button--small" (click)="onClickBack()">Back</button>&nbsp;
            <button class="button button--small" *ngIf="isNextBtnVisible" (click)="onClickNext()">Next</button>&nbsp;
           
          </div>
      </div>
  </div>
    </div>