<div>
  <cdk-tree
    [dataSource]="dataSource"
    [treeControl]="treeControl"
    role="tree"
    aria-label="node"
  >
    <cdk-nested-tree-node
      aria-label="childNode"
      *cdkTreeNodeDef="let node"
      class="example-tree-node"
    >
      <div *ngIf="node.IsDisplay" style="height: 30px" class="menuStyle">
        <button mat-icon-button style="height: 10px;" disabled aria-label="menuTitle"></button>
        <a
          routerLink="{{ node.Route }}"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          aria-label="MenuTitle"
          class="menuStyle"
          >{{ node.MenuTitle }}</a
        >
      </div>
    </cdk-nested-tree-node>

    <cdk-nested-tree-node
      aria-label="parentNode"
      *cdkTreeNodeDef="let node; when: hasChild"
      class="example-tree-node roleGroup"
    >
      <div *ngIf="node.IsDisplay" style="height: 30px">
        <button
          aria-label="menuTitle"
          mat-icon-button
          [attr.aria-label]="'Toggle ' + node.name"
          cdkTreeNodeToggle
        >
          <img
            alt="RightArrow"
            *ngIf="treeControl.isExpanded(node) == false"
            src="../assets/icons/RightArrow.svg"
            height="15"
            width="15"
            style="margin-bottom: 3px"
          />
          <img
            alt="DownArrow"
            *ngIf="treeControl.isExpanded(node) == true"
            src="../assets/icons/DownArrow.svg"
            height="15"
            width="15"
            style="margin-bottom: 3px"
          />
        </button>
        <a
          routerLink="{{ node.Route }}"
          routerLinkActive=""
          aria-label="Menu"
          >{{ node.MenuTitle }}</a
        >
      </div>

      <div [class.example-tree-invisible]="!treeControl.isExpanded(node)">
        <ng-container cdkTreeNodeOutlet></ng-container>
      </div>
    </cdk-nested-tree-node>
  </cdk-tree>
</div>
