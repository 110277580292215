<app-progress-spinner *ngIf="!showGrid || submitted"></app-progress-spinner>

<div *ngIf="showGrid" class="container">
  <div class="label-header">
    <label class="label-headercolor">OPTION C </label>
  </div>

  <ag-grid-angular #agGrid *ngIf="showGrid" style="width: 100% ;height: 100%;" id="optionCCostSavingGrid"
    class="ag-theme-alpine" [modules]="modules" [masterDetail]="true" [domLayout]="domLayout"
    [columnDefs]="optionCColumnDefs" [defaultColDef]="defaultColDef"
    [autoGroupColumnDef]="autoGroupOptionCCostColumnDef" [groupIncludeTotalFooter]="true" [enableRangeSelection]="true"
    [animateRows]="true" [rowSelection]="rowSelection" [suppressAggFuncInHeader]="true" [groupMultiAutoColumn]="true"
    [rowData]="optionCCostRowData" (gridReady)="onGridOptionCCostReady($event)"
    [frameworkComponents]="frameworkComponents" (gridSizeChanged)="onGridOptionCSizeChanged($event)"
    (cellClicked)="onCellClicked(event)" (cellValueChanged)="onCellValueChanged($event)"></ag-grid-angular>

  <!-- Savings versus Reference scenario -->
  <div class="row row-height" style="margin-top: 15px;">
    <div class="col-md-3 col-lg-3">
      <label>Savings versus reference scenario </label>
    </div>
    <div class="col-md-2 col-lg-2">
      <input type="text" class="form-control" aria-label="savingsVersesReferenceValue" [value]='savingsVSreferenceValue' disabled style="width: 150%;">
    </div>
  </div>
  <!-- Additional Details Textarea -->
  <div class="row row-height" style="margin-top: 15px;">
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea id="txtareaRemarks" aria-label="Remarks_C" style="font-size: 0.7rem;width:100%" [(ngModel)]="Remarks" (ngModelChange)="fillRemarks($event)" cols="75" rows="3"
        maxlength="1500" wrap="hard" [disabled]="!isEditable"></textarea>
    </div>
  </div>
  <div *ngIf="showGrid" class="row" style="margin-top: 100px">
    <div class="offset-4 col-md-8">
      <div class="buttonStyle" >
        <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickBack()">Back</button>&nbsp;
        <button class="button button--small" [disabled]="submitted" (click)="onClickNext()">Next</button>&nbsp;
        <button class="button button--small" type="submit" [disabled]="submitted || !isEditable" (click)="submit($event)">Save &
          Exit</button>
      </div>
    </div>
  </div>
</div>