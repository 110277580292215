import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable,throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {

  constructor(private http: HttpClient) { }
  
  private urlGetMasterTypes: string = environment.baseURL + "api/Master/GetMasterTypes";
  private urlGetDataById: string = environment.baseURL + "api/Master/GetAllData?masterTypeId="
  private urlUpdate: string = environment.baseURL+ "api/Master/InsertUpdateMaster?masterTypeId=";
  
  //This is used to get data from backend 
  getAllMasterTypeData(): Observable<any> {
    return this.http.get<any>(this.urlGetMasterTypes).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  getDatabyMasterTypeId(pageId, businessAreaId?: number, businessLineId?: number): Observable<any> {
    let pageIdn = Number(pageId)
    return this.http.get<any>(this.urlGetDataById + pageIdn +"&businessAreaId="+businessAreaId+"&businessLineId="+businessLineId).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  //  This is used to update new any Data in Database;
  updateData(masterId,data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    }
    let masterData=JSON.stringify(data)
    let masterIdn=Number(masterId)
    return this.http.post<any>(this.urlUpdate + masterIdn, masterData,httpOptions).pipe(
      retry(1),
      catchError(this.handleError)
    );;
  };
  handleError(error:HttpErrorResponse) {
    return throwError(error || "Server Error");
  }
}
