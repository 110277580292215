import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  userName: string;
  userInfo: any
  userAccount: any;
  isLoggedIn: boolean = false
  showSpinner: boolean = false
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private sharedService: SharedServiceService,
    private userSvc: UserInfoService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,

  ) { }

  ngOnInit(): void {
    this.checkLoginStatus();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.showSpinner = true;
      })

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        sessionStorage.setItem("status", "true")
        this.showSpinner = false;
        this.checkLoginStatus()
        this.checkAndSetActiveAccount();
      });

    this.authService.instance.handleRedirectPromise().then(
      res => {
        this.showSpinner = false;
        this.checkAndSetActiveAccount();
        this.getUserInfo()
      }
    )
  }

  checkLoginStatus() {
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0
    if (!this.isLoggedIn) {
      sessionStorage.removeItem("status");
    }
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  getUserInfo() {
    if (this.userAccount) {
      let gid = this.userAccount.idTokenClaims.siemens_gid;
      if (this.authService.instance.getAllAccounts().length > 0) {
        this.userSvc.getUserInfo(gid).subscribe((res: any) => {
          if (res.StatusCode == 200) {
            this.userInfo = res;
            if (this.userInfo.Data) {
              this.sharedService.accessMsgObj.next("accessed")
              this.userName = this.userInfo.Data.FullName;
              this.sharedService.loggedInUser.next(this.userInfo.Data);
            }
            else {
              this.sharedService.accessMsgObj.next("denied")
            }
          }
          else {
            let errorMsgeFromBack = res.Data.Message;
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
          (error) => {
            //Show errorCode and errorMessage in the UI
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          }
        )
      }
    }
  }

  login() {
    sessionStorage.setItem("status", "true");
    this.showSpinner = true;
    this.authService.loginRedirect();
  }

  logout() {
    sessionStorage.removeItem("status");
    this.authService.logout().subscribe(
      () => {
        this.localStorageService.clear();
        sessionStorage.clear()
        this.router.navigate(['/welcome']);
        this.userName = null
      }
    )
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userAccount = accounts[0]
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
