<app-progress-spinner *ngIf="!showform || submitted"></app-progress-spinner>
<div>
  <div>
    <main>
      <div *ngIf="showform" class="container">
        <form (keydown.enter)="$event.preventDefault()" [formGroup]="ideaForm" (ngSubmit)="submit($event)">
          <div class="col-md-12 col-lg-12 label-header">
            <label class="label-headercolor label-text">Organization </label>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3">
              <label for="businessArea" class="label">Business Area <span class="asterisk_input">*</span> </label>
            </div>
            <div class="col-md-3 col-lg-3">
              <select id="businessArea" class="form-control invalid" aria-label="businessArea"
                formControlName="businessAreaName"
                style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;"
                (change)="updateBusinessArea()">
                <option value="">Please select</option>
                <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
                  {{ba.BusinessAreaName}}
                </option>
              </select>
            </div>
            <div class="col-md-3 col-lg-3">
              <label for="businessLine" class="label">Business Line <span class="asterisk_input">*</span> </label>
            </div>
            <div class="col-md-3 col-lg-3">
              <select id="businessLine" class="form-control invalid" aria-label="businessLine"
                formControlName="businessLineName"
                style="margin-top: 4px; height: 30px !important; font-size: 0.7rem !important; padding: 1px;"
                (change)="updateBusinessLine()">
                <option value="">Please select</option>
                <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
                  {{bl.BusinessLineName}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-12 col-lg-12 label-header">
            <label class="label-headercolor label-text">Contacts </label>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label>Submitter <span class="asterisk_input">*</span> </label>
            </div>
            <div class="col-md-5 col-lg-5">
              <div class="textfield js-textfield textfield-with-prefix">

                <form [formGroup]="submitterPeoplePickr" style="width: 100%; position: absolute"
                  (click)="autofocus('sub')">
                  <mat-form-field appearance="legacy" class="myPanel no-underline">
                    <mat-select style="
                        font-size: 0.7rem;
                        padding-left: 10px;
                        position: relative;
                        top: -17px;
                      " formControlName="submitterPeoplePickr" (closed)="clearSearch('submitter')"
                      (click)="autofocus('sub')">
                      <mat-form-field [floatLabel]="'never'" class="no-underline no-wrapper sticky-search">
                        <mat-label class="search-label">Search Email...</mat-label>
                        <input role="textbox" style="
                            padding-left: 10px;
                            position: relative;
                            top: -2px;
                            border: 1px solid blue;
                            box-shadow: 0px 0px 6px;
                            border-radius: 2px;
                            width: 100%;
                            height: 20px;
                            bottom: 4px;
                            align-items: center;
                          " matInput #myInput type="text" [formControl]="searchInputSubmitter"
                          (input)="fetchUserData($event, false, 'submitter')" autocomplete="off" />
                      </mat-form-field>
                      <mat-option class="mat-option-hide" disabled></mat-option>
                      <mat-select-trigger role="textbox" aria-label="peoplePicker">
                        <span *ngIf="submitterPeoplePickr.value">
                          {{
                          submitterPeoplePickr.get("submitterPeoplePickr")
                          .value
                          ? submitterPeoplePickr.get("submitterPeoplePickr")
                          .value.FullName
                          : ""
                          }}
                        </span>
                      </mat-select-trigger>
                      <mat-option class="font-control" *ngFor="let user of submitterSearchData" [value]="user"
                        (click)="assignValueId(user, 'submitter')">
                        {{ user.Email }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="textfield js-textfield textfield-with-prefix">
                <input maxlength="50" type="text" aria-label="orgCodeSub" class="form-control textfield__input invalid"
                  formControlName="orgCodeSub" />
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style" style="margin-bottom: 5px">
            <div class="col-md-3 col-lg-3">
              <label>Responsible <span class="asterisk_input">*</span></label>
            </div>
            <div class="col-md-5 col-lg-5">
              <div class="textfield js-textfield textfield-with-prefix">
                <form [formGroup]="responsiblePeoplePickr" style="width: 100%; position: absolute"
                  (click)="autofocus('res')">
                  <mat-form-field appearance="legacy" class="myPanel no-underline">
                    <mat-select style="
                        font-size: 0.7rem;
                        padding-left: 10px;
                        position: relative;
                        top: -17px;
                      " formControlName="responsiblePeoplePickr" (closed)="clearSearch('responsible')"
                      (click)="autofocus('res')">
                      <mat-form-field [floatLabel]="'never'" class="no-underline no-wrapper sticky-search">
                        <mat-label class="search-label">Search Email...</mat-label>
                        <input role="textbox" style="
                            padding-left: 10px;
                            position: relative;
                            top: -2px;
                            border: 1px solid blue;
                            box-shadow: 0px 0px 6px;
                            border-radius: 2px;
                            width: 100%;
                            height: 20px;
                            bottom: 4px;
                            align-items: center;
                          " matInput #myInputRes type="text" [formControl]="searchInputResponsible"
                          (input)="fetchUserData($event, false, 'responsible')" autocomplete="off" />
                      </mat-form-field>
                      <mat-option class="mat-option-hide" disabled></mat-option>
                      <mat-select-trigger role="textbox" aria-label="peoplePicker">
                        <span *ngIf="responsiblePeoplePickr.value">
                          {{
                          responsiblePeoplePickr.get("responsiblePeoplePickr")
                          .value
                          ? responsiblePeoplePickr.get(
                          "responsiblePeoplePickr"
                          ).value.FullName
                          : ""
                          }}
                        </span>
                      </mat-select-trigger>
                      <mat-option class="font-control" *ngFor="let user of responsibleSearchData" [value]="user"
                        (click)="assignValueId(user, 'responsible')">
                        {{ user.Email }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="textfield js-textfield textfield-with-prefix">
                <input type="text" aria-label="orgCodeResp" class="form-control textfield__input invalid" maxlength="50"
                  formControlName="orgCodeResp" placeholder="Enter Responsible org code" />
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 label-header form-group form-check">
            <div class="row">
              <div class="col-md-4 col-lg-4">
                <label class="label-headercolor label-text"> Idea </label>
              </div>
              <div class="col-md-5 col-lg-5">
                <label style="position: relative; padding-bottom: 2px" class="label-headercolor checkbox1">
                  Approved by FPO &nbsp;
                  <mat-checkbox class="before-checked after-checked" formControlName="IsFPCApproved"
                    aria-label="IsFPCApproved">
                  </mat-checkbox>
                </label>
              </div>
              <div class="col-md-3 col-lg-3">
                <label style="position: relative; padding-bottom: 2px" class="label-headercolor">
                  Approved by FBP&nbsp;
                  <mat-checkbox class="before-checked after-checked" formControlName="IsFBPApproved"
                    aria-label="IsFBPApproved">
                  </mat-checkbox>
                </label>
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label>Title <span class="asterisk_input">*</span></label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input maxlength="255" type="text" aria-label="title" class="form-control textfield__input invalid"
                  formControlName="title" title="{{this.ideaTitle}}" [(ngModel)]="this.ideaTitle" />
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label>Current Situation & Complication
                <span class="asterisk_input">*</span></label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input type="text " aria-label="currentSituationAndComplication"
                  class="form-control textfield__input invalid" formControlName="currentSituationAndComplication"
                  title="{{this.situation}}" [(ngModel)]="this.situation" />
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label>Solution Proposal <span class="asterisk_input">*</span></label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input id="inputdefault" type="text" aria-label="solutionProposal"
                  class="form-control textfield__input invalid" formControlName="solutionProposal"
                  title={{this.solutionProposal}} [(ngModel)]="this.solutionProposal" />
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label style="margin-left: 10px">Option A (optional) <button id="ButtonOptA" value="3"
                  title="Swap Data of this solution with Solution Proposal" (click)="SwapSolution($event)"
                  [disabled]="!this.optionAvalue" style="padding-right: 10px;" class="CopySolutionData"><img id="3"
                    src="../assets/icons/copy.svg" height="15" width="15" alt="Swap Icon"></button>
              </label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input id="optionA" aria-label="optionA" type="text" class="form-control textfield__input borderStyle"
                  formControlName="optionA" title={{this.optionAvalue}} [(ngModel)]="this.optionAvalue" />
              </div>
            </div>
          </div>
          <div class="row row-height row row-height-height row-style">
            <div class="col-md-3 col-lg-3">
              <label style="margin-left: 10px">Option B (optional) <button id="ButtonOptB" value="4"
                  title="Swap Data of this solution with Solution Proposal" (click)="SwapSolution($event)"
                  [disabled]="!this.optionBvalue" style="padding-right: 10px;" class="ng-star-inserted"><img
                    _ngcontent-vok-c88="" id="4" src="../assets/icons/copy.svg" height="15" width="15"
                    alt="Copy Icon"></button>
              </label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input id="optionB" aria-label="optionB" type="text" class="form-control textfield__input borderStyle"
                  formControlName="optionB" title={{this.optionBvalue}} [(ngModel)]="this.optionBvalue" />
              </div>
            </div>
          </div>
          <div class="row row-height row-style" style="margin-bottom: 5px">
            <div class="col-md-3 col-lg-3">
              <label style="margin-left: 10px">Option C (optional) <button id="ButtonOptC" value="5"
                  title="Swap Data of this solution with Solution Proposal" (click)="SwapSolution($event)"
                  [disabled]="!this.optionCvalue" style="padding-right: 10px;" class="ng-star-inserted"><img
                    _ngcontent-vok-c88="" id="5" src="../assets/icons/copy.svg" height="15" width="15"
                    alt="Copy Icon"></button>
              </label>
            </div>
            <div class="col-md-9 col-lg-9">
              <div class="textfield js-textfield textfield-with-prefix">
                <input id="optionC" aria-label="optionC" type="text" class="form-control textfield__input borderStyle"
                  formControlName="optionC" title={{this.optionCvalue}} [(ngModel)]="this.optionCvalue" />
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12 label-header">
            <label class="label-headercolor label-text">Additional Information
            </label>
          </div>
          <div class="row row-height row-style">
            <div class="col-md-2 col-lg-2">
              <label style="padding-top: 12px;" class="checkbox1">
                Originated from OPW &nbsp;
                <mat-checkbox class="before-checked after-checked" formControlName="IsOriginatedOPW"
                  aria-label="IsOriginatedOPW">
                </mat-checkbox>
              </label>
            </div>
            <div class="col-md-1 col-lg-1">
              <label  style="
              padding-left: 57px;
          ">OPW ID </label>
            </div>
            <div class="col-md-2 col-lg-2">
              <div class="textfield js-textfield textfield-with-prefix">
                <input (input)="checkOpwid($event)" type="number" id="inputBox" aria-label="OPWNumber"
                  class="form-control textfield__input borderStyle" formControlName="OPWNumber"
                  (keydown)="hideSpecialCharacters($event)"
                  onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData.includes('.')){event.preventDefault();} " />
              </div>
            </div>
            <div class="col-md-0 col-lg-0">
              <label style="
              padding-left: 65px;
          ">Link to Work Item</label>
            </div>
            <div class="col-md-0 col-lg-0" style="
              padding-left: 13px;">
              <a href="{{ devopsWorkitemLink }}" target="_blank" rel="noopener"
                class="form-control textfield__input borderStyle" style="float: right"><img alt="TFS Link icon"
                  src="../assets/icons/globe.svg" height="22" width="22" /></a>
            </div>
            <!-- <div *ngIf="showWorkItem" class="col-md-1 col-lg-1"> -->
            <div class="col-md-2 col-lg-2" style="
              padding-left: 6px;">
              <div class="textfield js-textfield textfield-with-prefix">
                <!-- <input type="text" class="form-control textfield__input borderStyle" formControlName="workitem"
                  title={{this.devopsWorkitemLink}} /> -->

                <input (input)="devopsLinkBind($event, false)" type="text" *ngIf="showWorkItem"
                  class="form-control textfield__input borderStyle" aria-label="workitem" formControlName="workitem"
                  title="{{value}}" />
              </div>
            </div>
            <div class="col-md-0 col-lg-0" style="
              padding-left: -2px;">
              <label style="
              padding-left: 58px;">Link to TFS</label>
            </div>
            <div class="col-md-0 col-lg-0">
              <a href="{{ tfsLinkValue }}" title="Go To Link: {{ tfsLinkValue }}" target="_blank" rel="noopener"
                style="float: right;padding-left: 15px;"><img alt="TFS Link icon" src="../assets/icons/globe.svg" height="22"
                  width="22" /></a>
            </div>
            <div class="col-md-2 col-lg-2" style="
            padding-left: 13px;
        ">
              <div class="textfield js-textfield textfield-with-prefix">
                <input (input)="tfsLinkBind($event, false)" type="text"
                  class="form-control textfield__input borderStyle" aria-label="TFSId" id=tfs formControlName="TFSId"
                  style="width: 215px;" />
              </div>
            </div>
          </div>
        </form>
        <div class="row row-height">
          <div class="offset-6 col-md-6">
            <div style="float: right; margin-top: 10px">
              <button class="button button--small" (click)="onClickCancel()">
                Cancel & Exit</button>&nbsp;
              <button class="button button--small" (click)="onClickNext()" [disabled]="submitted">
                Next</button>&nbsp;
              <button class="button button--small" type="submit" [disabled]="submitted || !isEditable"
                (click)="submit($event)">
                Save & Exit
              </button>
            </div>
          </div>
        </div>
        <!-- validation Business Area -->
        <div>
          <span id="title6" *ngIf="ideaForm.get('businessAreaName').invalid; else nextArea">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Business Area",
            "false"
            )
            }}</span>
          <ng-template #nextArea>{{
            getErrorMessage("", "Business Area", "true")
            }}</ng-template>
        </div>
        <!-- validation Business Line -->
        <div>
          <span id="title7" *ngIf="ideaForm.get('businessLineName').invalid; else nextLine">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Business Line",
            "false"
            )
            }}</span>
          <ng-template #nextLine>{{
            getErrorMessage("", "Business Line", "true")
            }}</ng-template>
        </div>
        <!-- Validation Submitter -->
        <div>
          <span id="subError" *ngIf="
              submitterPeoplePickr.get('submitterPeoplePickr').invalid;
              else other_content
            ">
            {{
            getErrorMessage("Its a Required field", "Submitter", "false")
            }}</span>
          <ng-template #other_content>{{
            getErrorMessage("", "Submitter", "true")
            }}</ng-template>
        </div>
        <!-- validation Title -->
        <div>
          <span id="title" *ngIf="ideaForm.get('title').invalid; else nextTitle">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Title",
            "false"
            )
            }}</span>
          <ng-template #nextTitle>{{
            getErrorMessage("", "Title", "true")
            }}</ng-template>
        </div>
        <!-- validation Solution Proposal -->
        <div>
          <span id="title1" *ngIf="ideaForm.get('solutionProposal').invalid; else nextSol">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Solution_Proposal",
            "false"
            )
            }}</span>
          <ng-template #nextSol>{{
            getErrorMessage("", "Solution_Proposal", "true")
            }}</ng-template>
        </div>
        <!--validation current situation and compilation -->
        <div>
          <span id="title2" *ngIf="
              ideaForm.get('currentSituationAndComplication').invalid;
              else nextCurr
            ">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Current_Situation_And_Complication",
            "false"
            )
            }}</span>
          <ng-template #nextCurr>{{
            getErrorMessage("", "Current_Situation_And_Complication", "true")
            }}
          </ng-template>
        </div>
        <!-- validation Submitter org code -->
        <div>
          <span id="title3" *ngIf="ideaForm.get('orgCodeSub').invalid; else nextScode">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Submitter_Org_Code",
            "false"
            )
            }}</span>
          <ng-template #nextScode>{{
            getErrorMessage("", "Submitter_Org_Code", "true")
            }}</ng-template>
        </div>
        <!-- validation Responsible org code -->
        <div>
          <span id="title4" *ngIf="ideaForm.get('orgCodeResp').invalid; else nextRcode">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Responsible_Org_Code",
            "false"
            )
            }}</span>
          <ng-template #nextRcode>{{
            getErrorMessage("", "Responsible_Org_Code", "true")
            }}</ng-template>
        </div>
        <!-- validation Responsible -->
        <div>
          <span id="title5" *ngIf="
              responsiblePeoplePickr.get('responsiblePeoplePickr').invalid;
              else nextRes
            ">
            {{
            getErrorMessage(
            "Its a
            Required field",
            "Responsible",
            "false"
            )
            }}</span>
          <ng-template #nextRes>{{
            getErrorMessage("", "Responsible", "true")
            }}</ng-template>
        </div>
        <br />
      </div>
    </main>
  </div>