import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { Dirty } from 'src/app/interface/dirty-interface';
import { DoublingEditorComponent } from 'src/app/pages/non-plc/costsavings/doubling-editor.component';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { CategorizationService } from 'src/app/services/categorization.service';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { PlcPartUsedService } from '../../../../services/plc-part-used.service';
let countryCode;
@Component({
  selector: 'app-plc-parts-used-option-b',
  templateUrl: './plc-parts-used-option-b.component.html',
  styleUrls: ['./plc-parts-used-option-b.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PlcPartsUsedOptionBComponent implements OnInit, Dirty {
  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;
  public submitted: boolean = false;
  //reference scenario properties
  public referenceScenerioColumnDef: any;
  public referenceDataReceived: any;
  public gridApiReference: any;
  public gridColApiReference: any;
  public refObject: any;
  public refScPinnedBottomTotalRowData: any;
  public colForPinnedBottomRowRefSc = [];
  public modifiedRefRows = [];
  public tarScPinnedBottomTotalRowData: any;
  public colForPinnedBottomRowTarSc = [];
  public modifiedTarRows = [];
  // grid Common Properties;
  public defaultProperties: any;
  public headerHeightRef: any;
  public domLayout: any;
  public rowHeight: any;
  // hover
  public tooltipShowDelay;
  public rowStyle;
  // fot total row color
  public rowClassRulesTar;
  public getRowHeightTar;
  // for IdeaId and SolutionId
  public ideaId: any;
  public solutionIdOptionB: number;
  public UserId: any;
  // load Grid after loading data;
  public showGrid = false;
  public CreatedBy = 1;
  public ModifiedBy = 1;
  public hasUnsavedChanges: boolean = false;
  public isEditable: boolean = false;
  // response received from api
  public submitResponse: any;
  public frameworkComponents: any;
  public columnDefinationReference = [];
  public rowDataReference: any;
  public user: any;

  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  constructor(
    private localStorageService: LocalStorageService,
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private partUsedService: PlcPartUsedService,
    public dialog: MatDialog,
    private LocalStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService
  ) {
    this.frameworkComponents = { doublingEditor: DoublingEditorComponent };
    this.getRowHeightTar = () => { return 35 };
  }
  ngOnInit(): void {
    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
    })

    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');
    this.solutionIdOptionB = MSolutionType.OptionB;
    this.ideaId = this.localStorageService.get('ideaId');
    this.rowDataReference = [];
    this.showGrid = false;

    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadReferenceScData();
            }
          })
        })
      }
    })
  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if(pageLockMessage != ""){
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }

  // it load the grid data and column definitions by API'S Calls;
  loadReferenceScData() {
    this.ideaId = this.localStorageService.get("ideaId");
    this.partUsedService.getAllData(this.ideaId, this.solutionIdOptionB).subscribe((res: any) => {
      if (res.Data == null) {
        return;
      }
      this.referenceDataReceived = res;
      if (this.referenceDataReceived.StatusCode == 200) {
        this.referenceDataReceived = this.referenceDataReceived.Data;      

        // for remark GET;
        this.remarkDataRecieved = this.referenceDataReceived.Remark;
        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }

        let componentData: any;
        let partUsedData: any;
        let rowArrayReference = [];
        componentData = this.referenceDataReceived.Components;
        partUsedData = this.referenceDataReceived.PartsUsedData;
        this.columnDefinationReference.push({
          headerName: "Product",
          field: "ProductTitle",
          tooltipField: "ProductTitle",
          headerTooltip: "Product",
          // filter: true,
          editable: false,
          singleClickEdit: true,
          cellClass: params => { return params.data["ProductTitle"] == 'Average consumption per unit' ? 'colorTotal' : null },
        })
        for (let i of componentData) {
          this.columnDefinationReference.push({
            headerName: i.ComponentTitle,
            field: String(i.ComponentId),
            // tooltipField: String(i.componentId),
            headerTooltip: i.ComponentTitle,
            tooltipValueGetter: CurrencyCellRenderer,
            // filter: true,
            singleClickEdit: true,
            cellRenderer: CurrencyCellRenderer,
            cellClass: params => { return params.data["ProductTitle"] == 'Average consumption per unit' ? 'colorTotal' : null },
            cellEditor: "doublingEditor"
          });
          this.colForPinnedBottomRowRefSc.push(String(i.ComponentId))
        }
        this.columnDefinationReference.push({
          headerName: "Total",
          field: "Total",
          headerTooltip: "Total",
          tooltipValueGetter: CurrencyCellRenderer,
          cellRenderer: CurrencyCellRenderer,
          // tooltipField: "Total",
          editable: false,
          cellClass: 'colorTotal',
          valueGetter: totalCalculator
        })
        for (let j of partUsedData) {
          let row = {};
          row["ProductTitle"] = j.ProductTitle;
          row["ImpactedProductId"] = j.ProductId;
          let partUsedComponent = j.ComponentValues;
          for (let i of partUsedComponent) {
            row[String(i.ComponentId)] = i.Value;
            row[String("PartsUsedId" + "_" + i.ComponentId)] = i.PartsUsedId;
          }
          rowArrayReference.push(row);
        }
        this.rowDataReference = rowArrayReference;
        this.loadGridStructure();
      }
      else {
        let errorMsgeFromBack = this.referenceDataReceived.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    })
  }
  // this will load the structure of grid to display the data coming from API Calls;
  loadGridStructure() {
    this.domLayout = 'autoHeight';
    this.defaultProperties = {
      sortable: false,
      enableValue: false,
      resizable: false,
      suppressMovable: true,
      editable: this.isEditable,
      filter: false,
    }
    this.tooltipShowDelay = 0;
    this.headerHeightRef = 62;
    this.rowStyle = {
      fontSize: '0.7rem'
    }
    this.showGrid = true;
  }
  // gets called on load of grid and can be used to fetch all the information data of the grid;
  onGridReadyReference(params) {
    this.gridApiReference = params.api;
    this.gridColApiReference = params.columnApi;
    let rowCount = this.gridApiReference.getDisplayedRowCount();
    if (rowCount > 5) {
      this.gridApiReference.setDomLayout('normal');
      document.getElementById('reference').style.height = '250px';
    }
  }
  // gets called when a value is changed in a cell;
  onCellValueChangedReference(params) {
    let isPushed = false;
    this.hasUnsavedChanges = true;
    if (this.modifiedRefRows.length == 0) {
      this.modifiedRefRows.push(params.data)
      isPushed = true
    }
    else {
      this.modifiedRefRows.forEach(row => {
        if (row["ImpactedProductId"] == params.data["ImpactedProductId"]) {
          isPushed = true
        }
      })
    }
    if (!isPushed) {
      this.modifiedRefRows.push(params.data)
    }
  }
  // called when a row is clicked in Reference Grid;
  onRowClickedReference($event) {
    let rowCount = this.gridApiReference.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApiReference.setDomLayout('autoHeight');
      document.getElementById('reference').style.height = null;
    }
  }
  // open popUp error box which shows error;
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  // saves  all the data after doing mandatory validations;
  submit(event?) {
    this.submitted = true;
    this.dataToSend();
    let response = null;
    let combinedPostData = [];
    if (this.refObject.PartsUsedData.length != 0 || this.refObject.Remark != null) {
      combinedPostData.push(this.refObject);
    }

    if (this.hasUnsavedChanges) {

      response = this.partUsedService.postData(combinedPostData).toPromise()
        .then(res => {
          this.submitResponse = res;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.hasUnsavedChanges = false;
              if (event != undefined) {
                this.router.navigate(['/manageIdea'])
              }
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI              
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.submitted = false;
              return false;
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI           
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer);
            this.submitted = false;
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.submitted = false;
          return false;
        })
    } else {
      this.hasUnsavedChanges = false;
      if (event != undefined) {
        this.router.navigate(['/manageIdea']);
      }
      return true;
    }
    return response;
  }
  //Prepate the data which is to be send to database with required dto format
  dataToSend() {
    let refPartsUsedArray = [];
    let refScenarioComponentArray = [];
    this.modifiedRefRows.forEach(node => {
      let keys = Object.keys(node);
      refScenarioComponentArray = []
      for (let key of keys) {
        if (!isNaN(+key)) {
          refScenarioComponentArray.push({
            PartsUsedId: Number(node["PartsUsedId" + "_" + key]),
            ComponentId: Number(key),
            Value: node[key] == 0 ? null : node[key]
          })
        }
      }
      refPartsUsedArray.push({
        ProductId: node["ImpactedProductId"],
        ProductTitle: node["ProductTitle"],
        ComponentValues: refScenarioComponentArray,
        IsActive: 1,
        CreatedBy: this.userInOrg.UserId,
        CreatedOn: "2021-04-27T09:21:28.3983191Z",
        ModifiedBy: this.userInOrg.UserId,
        ModifiedOn: null
      })
    })
    // Remarks changes: While POST;

    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.userInOrg.UserId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: MSolutionType.OptionB,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.PartsUsed
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }
    this.refObject = {
      Components: [],
      PartsUsedData: refPartsUsedArray,
      Remark: Remark,
      IdeaId: this.ideaId,
      UserId: this.userInOrg.UserId,
      SolutionId: this.solutionIdOptionB,
      BusinessAreaId: this.businessAreaId,
      BusinessLineId: this.businessLineId
    }
  }
  onGridTgtSizeChanged(params) {
    this.gridApiReference = params.api;
    this.gridApiReference.sizeColumnsToFit();
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }
  // get called when user click on cancel and exit;
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }
  // move user to back page;
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }
  // moves user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  // Remarks changes: While Input Changes;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
}
// use to format the cell according to german currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(params.value);
}
// For calculating total
function totalCalculator(params) {
  let keys = Object.keys(params.data);
  let total = 0;
  for (let key of keys) {
    if (!isNaN(+key)) {
      total += Number(params.data[key])
    }
  }
  if (params.data["ProductTitle"] == 'Average consumption per unit' || total == 0) {
    total = null;
  }
  if (Number.isInteger(total)) {
    return total;
  }
  else if (total) {
    return total.toFixed(2);
  }
  else {
    return null;
  }
}

