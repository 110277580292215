
  <app-progress-spinner *ngIf="!showTgtPlcAnalysis"></app-progress-spinner>
    <div class="label-header">
      <label class="label-headercolor">Target Scenario </label>
  </div>
 
  <ag-grid-angular style="width: 100%; height: 100%;"
        class="ag-theme-alpine"
        [headerHeight]="tgtheaderHeight"
        [suppressRowTransform]="true"
        [getRowStyle]="getRowStyle"
        [rowClassRules]="tgtrowClassRules"
        [rowHeight]="tgtRowHeight"
        [rowStyle]="rowStyle"
        [rowData]="tgtRowData"
        [columnDefs]="tgtColumnDefs"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGrouptgtCostColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded"
        [suppressAggFuncInHeader]="true"
        [enableBrowserTooltips]="true"
        [domLayout]="domLayout"
        (gridReady)="ontgtGridReady($event)"
        (gridSizeChanged)="ontgtGridSizeChanged($event)"

        (displayedColumnsChanged)="onDisplayedColumnsChanged($event)"
    >
</ag-grid-angular>
  
  
