import { Component, ViewEncapsulation } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  encapsulation:ViewEncapsulation.None
})

export class CheckboxComponent implements ICellRendererAngularComp {   
  public params: any;

  // refresh the grid with new value;
  refresh(params: any): boolean {      
      params.data.OnLegalHold = params.value
      let checked =params.value 
      let colId = params.column.colId;     
      this.params.node.setDataValue(colId, checked);
      return false;
  }

  agInit(params: any): void {
      this.params = params;       
  }

  // update the grid data as the input is checked/unchecked;
  checkedHandler(event) {
      let checked = event.target.checked;      
      let colId = this.params.column.colId;   
      this.params.node.setDataValue(colId, checked);    
  }
}