<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
<div *ngIf="message!='denied' && !showSpinner" class="welcomePage" style="position:fixed;top:70px;bottom: 30px;left:0px;right:0px;">
 
</div>
<div *ngIf="loggedIn==false && !showSpinner" class="welcomePage" style="position:fixed;top:70px;bottom: 30px;left:0px;right:0px;">
    <div class="logout">
        <div>{{logoutMsg1}}</div>
        <div>{{logoutMsg2}}</div>
    </div>
</div>
<div *ngIf="message=='denied' && !showSpinner" class="welcomePage" style="position:fixed;top:70px;bottom: 30px;left:0px;right:0px;">
    <div class="access-denied">
        <div>{{message1}}</div>
        <div>{{message2}}</div>
        <div style="color: blue">{{contact}}</div>
    </div>
</div>



