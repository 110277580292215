import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { messages } from 'src/app/popUpMessages/messages';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-login-check',
  templateUrl: './login-check.component.html',
  styleUrls: ['./login-check.component.css']
})
export class LoginCheckComponent implements OnInit {
  public message1: string;
  public message2: string;
  public contact: string;
  public userAccount: any;
  public userInfo: any;
  public message: string;
  public loggedIn:any;
  public user: any;
  public showSpinner: boolean = false;
  public signingInMsg = "";
  public logoutMsg1:string; 
  public logoutMsg2:string;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private sharedService: SharedServiceService,
    private userSvc: UserInfoService,
    private localStorage: LocalStorageService
  ) { }
  ngOnInit(): void {
    this.logoutMsg1=messages.logoutMsg1;
    this.logoutMsg2=messages.logoutMsg2;
    let loginStatus=sessionStorage.getItem("status")
    this.showSpinner = true;
    if(loginStatus=="true"){
      this.loggedIn=true;
      this.showSpinner = true;
    }
    else{
      this.loggedIn=false
      this.showSpinner = false;
    }
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.showSpinner = true;
        if (result.payload.account) {
          this.loggedIn = true;
        }
      });

    if (this.authService.instance.getAllAccounts().length > 0) {
      this.loggedIn = true;
    }
    this.sharedService.accessMsgObj.subscribe(msg => {
      this.message = msg;
      if (msg == "accessed") {
        this.router.navigate(['/home'])
      }
      else if (msg == "denied") {
        this.showSpinner = false;
        this.message1 = "You do not have access to the application."
        this.message2 = "Please contact administrator to get the required access."
        this.contact = "Schubert, Maik <maik.schubert@siemens-healthineers.com>"
      }
    })

  }
}
