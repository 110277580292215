<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>
<div
  class="
    application-bar application-bar--compact application-bar--documentation
  "
>
  <div class="application-bar__container">
    <div class="application-bar__logo">
      <div class="logo"></div>
    </div>
    <div class="application-bar__app-identifier">
      Productivity Ecosystem
    </div>
    <div class="application-bar__additional-functions"></div>
    <div class="application-bar__meta-menu">
      <div class="meta-menu js-meta-menu">
        <ul class="bare-list meta-menu__list">
          <li class="meta-menu__item">
            <a href="#" class="meta-menu__link" aria-label="menu_link">
              <span class="meta-menu__title"></span>
            </a>
          </li>
          <li class="meta-menu__item"></li>
        </ul>
      </div>
    </div>
    <div class="application-bar__user-area">
      <div class="user-area dropdown dropdown--right js-dropdown">
        <button class="user-area__toggle-button dropdown__toggle">
          <span class="user-area__details">
            <span class="user-area__name" *ngIf="userName && isLoggedIn">{{
              userName
            }}</span
            ><br />
          </span>
          <i class="icon-arrow-drop-down"></i>
        </button>
      </div>
      <button
        (click)="login()"
        *ngIf="!isLoggedIn"
        aria-label="login"
        title="login"
      >
        <span style="margin-left: 5px">
          Login
          <img
            src="../assets/login.png"
            alt="login"
            height="25"
            width="25"
          /> </span
        ><br />
      </button>
      <button
        (click)="logout()"
        *ngIf="userName && isLoggedIn"
        aria-label="logout"
        title="logout"
      >
        <span style="margin-left: 5px">
          <img
            src="../assets/icons/logout.svg"
            alt="logout"
            height="25"
            width="25"
          /> </span
        ><br />
      </button>
    </div>
  </div>
</div>
