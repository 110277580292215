import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { IdeaformService } from '../../services/ideaform.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { Idea } from '../../models/idea';
import { Router } from "@angular/router";
import { LocalStorageService } from '../../services/local-storage.service';
import { SharedServiceService } from '../../services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { Dirty } from 'src/app/interface/dirty-interface';
// vallidation message
import { messages } from '../../popUpMessages/messages';
// server message
import { serverMessage } from '../../popUpMessages/serverMessage';
import { MenuService } from 'src/app/services/menu.service';
// People-picker service;
import { UserInfoService } from 'src/app/services/user-info.service';
import { ideaPageConstants } from 'src/app/app-constants/ideaPage-constants';
import { UserReplacementService } from 'src/app/services/user-replacement.service';
import { MasterdataService } from "src/app/services/masterdata.service";
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { CategorizationService } from 'src/app/services/categorization.service';


@Component({
  selector: 'app-ideaform',
  templateUrl: './ideaform.component.html',
  styleUrls: ['./ideaform.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IdeaformComponent implements OnInit, Dirty {

  public show: boolean = false;
  public ideaForm: FormGroup;
  public submitted: boolean = false;

  public dataRecieved: any;
  public optionAvalue: any;
  public optionBvalue: any;
  public optionCvalue: any;
  public workitemId: any;
  public devopsLink: any;


  public showform = false;
  public showWorkItem = false;
  public value: any;
  public devopsWorkitemLink: any;
  public IdeaIdFromLocal: any;
  public ideaTitle: any;
  public solutionProposal: any;
  public situation: any;

  public submitResponse: any;
  public hasUnsavedChanges: boolean = false;
  public isEditable: boolean = true;

  // Variables used in error handling
  public formerror = [];
  public errorMessage = {};
  public field = "";
  public validationHeaderMessage: any;
  public optionRemovalMessage: any;

  // return the form value in object format, so that it can be send to database;
  public dataToSend = new Idea();
  public ideaSubmitterId: any;
  public optionsRequired: boolean = true;
  public MeasureStatusId = 1;

  // TFS LINK:--
  public tfsLinkValue: any = "";

  // PEOPLE PICKER Variables:--
  // submitter input search field;
  public searchInputSubmitter = new FormControl();
  //  responsible input search field;
  public searchInputResponsible = new FormControl();
  // responsible people picker form variable;
  public responsiblePeoplePickr: FormGroup;
  // submitter people picker form variable;
  public submitterPeoplePickr: FormGroup;
  // this variable is for the submitterId and ResponsibleId;
  public submitterId: any;
  public responsibleId: any;

  // these variable will contain the searched user data for perticular formField;
  public submitterSearchData: any = [];
  public responsibleSearchData: any = [];
  // this variable will contain all the users data from tbl_User of DB;
  public allUserList: any;
  // used for the autofocus in the input field for the people picker;
  public oldDataSubmitter: any;
  public oldDataResponsible: any;

  public allActInActUser: any;

  @ViewChild('myInput') myInputField: ElementRef;
  @ViewChild('myInputRes') myInputFieldRes: ElementRef
  public ideaId: any;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public tempRoleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public tempRoleBusinessLine = [];
  loginUserDetails = [];
  public userInOrg: any;
  categorizationData: any;

  constructor(
    private cd: ChangeDetectorRef,
    private toast: ToasterService,
    private router: Router,
    private sharedService: SharedServiceService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private _ideaservice: IdeaformService,
    private menuService: MenuService,
    // service for the UserList;
    private userService: UserInfoService,
    private LocalStorageService: LocalStorageService,
    private masterService: MasterdataService,
    private userReplacementSer: UserReplacementService,
    private catService: CategorizationService
  ) { }
  ngOnInit(): void {

    // creating a form for the submitter people picker;
    this.submitterPeoplePickr = this.formBuilder.group({
      submitterPeoplePickr: [null, Validators.required]
    })
    // creating a form for the Responsible people picker;
    this.responsiblePeoplePickr = this.formBuilder.group({
      responsiblePeoplePickr: [null, Validators.required]
    })
    this.sharedService.ideaSubmitterId.subscribe(
      (id) => {
        this.ideaSubmitterId = id;
      }
    )
    this.sharedService.getIdeaIdAndtitle();
    //Get IdeaId
    this.ideaId = this.LocalStorageService.get('ideaId');
    this.sharedService.ideaformCheckOptionsSub = this.sharedService.ideaformCheckOptions.subscribe(() => {
      this.checkOptionOk();
      this.submitted = false;
    })
    this.sharedService.ideaformCheckCancel.subscribe(() => {
      this.checkOptionCancel();
      this.submitted = false;
    })
    this.showform = false;
    this.validationHeaderMessage = messages.cellEmptyErrorMesssage;
    this.optionRemovalMessage = messages.ideaForm.optionRemovalMessage;
    this.loadFormStructure();

    this.catService.getCategorizationDataById(parseInt(this.ideaId)).subscribe((data: any) => {
      // let errorMsgeFromBack = data.Data ? data.Data.Message : ""
      if (data.StatusCode == 200) {
        let responseData;
        responseData = data;
        this.categorizationData = responseData.Data.Categorization;
      }
    });
  }
  // this defines structure of ideaform;
  loadFormStructure() {
    this.ideaForm = this.formBuilder.group({
      submitter: ['', [Validators.required, validateSpaceOnly]],
      orgCodeSub: ['', [Validators.required, validateSpaceOnly]],
      responsible: ['', [Validators.required, validateSpaceOnly, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
      orgCodeResp: ['', [Validators.required, validateSpaceOnly]],
      title: ['', [Validators.required, validateSpaceOnly]],
      currentSituationAndComplication: ['', [Validators.required, validateSpaceOnly]],
      solutionProposal: ['', [Validators.required, validateSpaceOnly]],
      optionA: [''],
      optionB: [''],
      optionC: [''],
      OPWNumber: [{ value: '', disabled: true }],
      TFSId: [''],
      workitem: [{ value: '', disabled: true }],
      // for the checkbox;
      IsFBPApproved: [false],
      IsFPCApproved: [false],
      businessAreaName: ['', [Validators.required, validateSpaceOnly]],
      businessLineName: ['', [Validators.required, validateSpaceOnly]],
      IsOriginatedOPW: [{ value: '', disabled: true }]
    });
    this.loadFormData();
  }

  // this loads the form data;
  loadFormData() {
    this.IdeaIdFromLocal = this.localStorageService.get('ideaId');
    if (this.IdeaIdFromLocal == null) {
      this.sharedService.loggedInUser.subscribe((u: any) => {
        if (u) {
          this._ideaservice.getUserInfo(u.GID).subscribe((user: any) => {
            user = user.Data;
            this.loginUserDetails.push(user);
            this.roleBusinessArea = user.BusinessArea;
            this.roleAllBusinessLine = user.BusinessLine;
            this.roleBusinessArea = this.roleBusinessArea.filter((v, i, a) => a.findIndex(v2 => (v2.BusinessAreaId === v.BusinessAreaId)) === i);

            if (this.roleBusinessArea.length == 1) {
              this.ideaForm.controls['businessAreaName'].setValue(this.roleBusinessArea[0].BusinessAreaId.toString());
              this.roleBusinessLine = this.roleAllBusinessLine;
              this.ideaForm.controls['businessAreaName'].disable();
            }
            if (this.roleAllBusinessLine.length == 1) {
              this.ideaForm.controls['businessLineName'].setValue(this.roleAllBusinessLine[0].BusinessLineId.toString());
              this.ideaForm.controls['businessLineName'].disable();
            }
            this.ideaForm.patchValue({
              submitter: user.Email,
              responsible: user.Email,
              orgCodeSub: user.OrgCode,
              orgCodeResp: user.OrgCode,
            });

            //this function will patch the value to the submitter and the responsible field;
            this.fetchUserData(user.Email, true, "both");
            // Admin and Power User are able to edit the "Submitter" field;
            // Admin, Power User and Normal User all are able to edit "Responsible" field;
            // "Sub org code" and "Res org code" fields are disabled, as it is automatically;

            //new implementation.
            if (this.ideaForm.controls["businessAreaName"].value != "" && this.ideaForm.controls["businessLineName"].value != "") {
              if (user.RoleId == 3) {
                this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
                this.ideaForm.get("orgCodeSub").disable();
                this.ideaForm.get("orgCodeResp").disable();
              } else {
                this.ideaForm.get("orgCodeSub").disable();
                this.ideaForm.get("orgCodeResp").disable();
              }
            } else {
              this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
              this.responsiblePeoplePickr.get("responsiblePeoplePickr").disable();
              this.ideaForm.get("orgCodeSub").disable();
              this.ideaForm.get("orgCodeResp").disable();
            }
          });
        }
      })
      this.showform = true;
      this.showWorkItem = false;
      this.ideaForm.valueChanges.subscribe(e => { this.hasUnsavedChanges = this.ideaForm.dirty; });
    } else {

      this.masterService.getDatabyMasterTypeId("17", 0, 0).subscribe((data) => {
        this.tempRoleBusinessArea = data.Data;
        this.roleBusinessArea = this.tempRoleBusinessArea.map(item => {
          return {
            BusinessAreaId: item.Id,
            BusinessAreaName: item.BusinessArea,
          };
        });
      })
      this.masterService.getDatabyMasterTypeId("18", 0, 0).subscribe((data) => {
        this.tempRoleBusinessLine = data.Data;
        this.roleBusinessLine = this.tempRoleBusinessLine.map(item => {
          return {
            BusinessLineId: item.Id,
            BusinessLineName: item.BusinessLine,
            BusinessAreaId: item.BusinessAreaId
          };
        });
      })
      // making the service call;
      this._ideaservice.getData(this.IdeaIdFromLocal).subscribe((data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          // this.showWorkItem = true;
          this.hasUnsavedChanges = false;
          this.dataRecieved = data;
          this.dataRecieved = this.dataRecieved.Data;
          this.ideaTitle = this.dataRecieved.Title;
          this.situation = this.dataRecieved.Situation;
          this.solutionProposal = this.dataRecieved.TargetSol;
          this.optionAvalue = this.dataRecieved.OptionA;
          this.optionBvalue = this.dataRecieved.OptionB;
          this.optionCvalue = this.dataRecieved.OptionC;
          this.workitemId = this.dataRecieved.WorkItemid;
          this.devopsLink = this.dataRecieved.Devops;

          this.ideaForm.controls["businessAreaName"].setValue(this.dataRecieved.BusinessAreaId.toString());
          this.ideaForm.controls['businessAreaName'].disable();
          this.ideaForm.controls["businessLineName"].setValue(this.dataRecieved.BusinessLineId.toString());
          this.ideaForm.controls['businessLineName'].disable();

          // this will fill the submitter and responsible field;
          this.fetchUserData(this.dataRecieved.Submitter, true, 'submitter');
          this.fetchUserData(this.dataRecieved.Responsible, true, 'responsible');
          // attaching the incoming data to ideaform;
          this.ideaForm.patchValue({
            submitter: this.dataRecieved.Submitter,
            orgCodeSub: this.dataRecieved.SubmitterOrgCode,
            responsible: this.dataRecieved.Responsible,
            orgCodeResp: this.dataRecieved.ResponsibleOrgCode,
            title: this.dataRecieved.Title,
            currentSituationAndComplication: this.dataRecieved.Situation,
            solutionProposal: this.dataRecieved.TargetSol,
            optionA: this.dataRecieved.OptionA,
            optionB: this.dataRecieved.OptionB,
            optionC: this.dataRecieved.OptionC,
            OPWNumber: this.dataRecieved.Opwnumber,
            TFSId: this.dataRecieved.Tfsid,
            IsFBPApproved: this.dataRecieved.IsFBPApproved,
            IsFPCApproved: this.dataRecieved.IsFPCApproved,
            businessAreaName: this.dataRecieved.BusinessAreaId.toString(),
            businessLineName: this.dataRecieved.BusinessLineId.toString(),
            IsOriginatedOPW: this.dataRecieved.IsOriginatedOPW,
            workitem: this.dataRecieved.Devops
          })
          if (this.dataRecieved.IsOriginatedOPW == 1) {
            this.ideaForm.get("title").disable();
          }
          if ((this.dataRecieved.WorkItemid != 0) || (this.dataRecieved.WorkItemid == null)) {
            this.value = this.workitemId;
            this.devopsWorkitemLink = this.dataRecieved.Devops;
            this.showWorkItem = true;
          }
          this.loadSharedValue();
          this.ideaForm.valueChanges.subscribe(e => { this.hasUnsavedChanges = this.ideaForm.dirty; });

          this.tfsLinkBind(this.dataRecieved.Tfsid, true)
          this.devopsLinkBind(this.dataRecieved.Devops, true)
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);
        }
      },
        (error) => {
          //Show errorCode and errorMessage in the UI
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        }
      )
    }
  }

  // it will perform changes in shared service;
  loadSharedValue() {

    this.sharedService.loggedInUser.subscribe((user: any) => {

      // Admin and Power User are able to edit the "Submitter" field;
      // Admin, Power User and Normal User all are able to edit "Responsible" field;
      // "Sub org code" and "Res org code" fields are disabled, as it is automatically;
      this.userService.getUserList(Number(this.ideaForm.controls["businessAreaName"].value), Number(this.ideaForm.controls["businessLineName"].value)).subscribe((d: any) => {
        this.allUserList = d;
        this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
        if (this.userInOrg != undefined) {
          if (this.userInOrg.RoleId == 3) {
            this.submitterPeoplePickr.get("submitterPeoplePickr").disable();
            this.ideaForm.get("orgCodeSub").disable();
            this.ideaForm.get("orgCodeResp").disable();
          } else {
            this.ideaForm.get("orgCodeSub").disable();
            this.ideaForm.get("orgCodeResp").disable();
          }

          if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
            this.isEditable = true;
          }
          else if (this.userInOrg.RoleId == 3) {
            if (this.submitterId == this.userInOrg.UserId) {
              this.isEditable = true;
            }
            else {
              this.isEditable = false;
              this.ideaForm.disable();
              this.submitterPeoplePickr.disable();
              this.responsiblePeoplePickr.disable();
            }
          }
        }
      })
    })
    this.showform = true;
  }

  updateBusinessArea() {
    this.ideaForm.controls['businessLineName'].setValue("");
    this.submitterPeoplePickr.controls["submitterPeoplePickr"].disable();
    this.responsiblePeoplePickr.controls["responsiblePeoplePickr"].disable();
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.ideaForm.controls['businessAreaName'].value)
    );
  }

  updateBusinessLine() {

    if (this.ideaForm.controls["businessAreaName"].value != "" && this.ideaForm.controls["businessLineName"].value != "") {
      this.userService.getUserList(Number(this.ideaForm.controls["businessAreaName"].value), Number(this.ideaForm.controls["businessLineName"].value)).subscribe((d: any) => {
        this.allUserList = d;
        this.userInOrg = this.allUserList.find(u => u.Email == this.ideaForm.controls["submitter"].value);
        if (this.userInOrg != undefined) {
          let userDetail = [];
          userDetail.push(this.userInOrg);
          this.bindValueToSubRes(true, "both", userDetail);
          this.fillSearchValue("both", true, userDetail);

          if (this.userInOrg.RoleId == 3) {
            this.responsiblePeoplePickr.get("responsiblePeoplePickr").enable();
          } else {
            this.submitterPeoplePickr.controls["submitterPeoplePickr"].enable();
            this.responsiblePeoplePickr.controls["responsiblePeoplePickr"].enable();
          }
        }
      })
    } else {
      this.submitterPeoplePickr.controls["submitterPeoplePickr"].disable();
      this.responsiblePeoplePickr.controls["responsiblePeoplePickr"].disable();
    }
  }

  canDeactivate() {
    return this.hasUnsavedChanges;
  }

  //Method for Swap Solution
  SwapSolution(event) {
    this.showform = false;
    let text;
    let target = event.target || event.srcElement || event.currentTarget;
    let idAttr = target.attributes.id;
    let value = idAttr.nodeValue;
    if (value == 3) {
      text = "A";
    } else if (value == 4) {
      text = "B";
    } else if (value == 5) {
      text = "C";
    }
    let IdeaId = 0;
    let solutionId = 0;
    let userId = 0;
    let response = null;
    if (this.IdeaIdFromLocal != "null") {
      IdeaId = Number(this.IdeaIdFromLocal)
    }
    solutionId = value;
    userId = this.userInOrg.UserId;

    // opens the dialog box for confirmation with two button Yes and No
    //let error = messages.copySolutionMwessage;
    let error = messages.swapMessage1 + text + messages.swapMessage2 + '\n' + messages.swapMessage3;
    const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //Sending data to API
        this._ideaservice.postDataCopySolution(IdeaId, solutionId, userId).toPromise().
          then((res) => {
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;

            //Show success Message in the UI
            if (successCode == 200) {
              this.loadFormData();
              this.showform = true;
              if (errorCode == 0) {
                this.hasUnsavedChanges = false;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
              }
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
            }
          });
      }
      else {
        this.showform = true;
        this.loadFormData();
      }
    })
  }

  // bind data in format as required to send to the backend;
  sendData() {
    let ideaIdUpdate = 0;
    if (this.IdeaIdFromLocal != "null") {
      ideaIdUpdate = Number(this.IdeaIdFromLocal)
    }
    let opwNumber = Number(this.ideaForm.get("OPWNumber").value);
    this.dataToSend.IdeaId = ideaIdUpdate;
    this.dataToSend.Title = this.ideaForm.get("title").value.trim();
    this.dataToSend.SubmitterId = this.submitterId;
    this.dataToSend.SubmitterOrgCode = this.ideaForm.get("orgCodeSub").value.trim();
    this.dataToSend.ResponsibleId = this.responsibleId;
    this.dataToSend.ResponsibleOrgCode = this.ideaForm.get("orgCodeResp").value.trim();
    this.dataToSend.MeasureStatusId = this.MeasureStatusId;
    this.dataToSend.Situation = this.ideaForm.get("currentSituationAndComplication").value.trim();
    this.dataToSend.Opwnumber = opwNumber == 0 ? null : opwNumber;
    this.dataToSend.Tfsid = this.ideaForm.get("TFSId").value.trim();
    this.dataToSend.TargetSol = this.ideaForm.get("solutionProposal").value.trim();
    if (this.ideaForm.get("optionA").value == undefined || this.ideaForm.get("optionA").value == null) {
      this.dataToSend.OptionA = "";
    }
    else {
      this.dataToSend.OptionA = this.ideaForm.get("optionA").value.trim();
    }
    if (this.ideaForm.get("optionB").value == undefined || this.ideaForm.get("optionB").value == null) {
      this.dataToSend.OptionB = "";
    }
    else {
      this.dataToSend.OptionB = this.ideaForm.get("optionB").value.trim();
    }
    if (this.ideaForm.get("optionC").value == undefined || this.ideaForm.get("optionC").value == null) {
      this.dataToSend.OptionC = "";
    }
    else {
      this.dataToSend.OptionC = this.ideaForm.get("optionC").value.trim();
    }
    this.dataToSend.CreatedBy = (this.userInOrg == undefined) ? this.ideaSubmitterId : this.userInOrg.UserId;
    this.dataToSend.IsFBPApproved = this.ideaForm.value.IsFBPApproved;
    this.dataToSend.IsFPCApproved = this.ideaForm.value.IsFPCApproved;
    this.dataToSend.BusinessAreaId = Number(this.ideaForm.controls["businessAreaName"].value);
    this.dataToSend.BusinessLineId = Number(this.ideaForm.controls["businessLineName"].value);
    this.dataToSend.IsOriginatedOPW = this.ideaForm.value.IsOriginatedOPW;

    return this.dataToSend;
  }

  // this below is for error and validation for removal of Options A/B/C----------------->
  // used during removal of Option A/B/C; 
  checkOptionOk() {
    this.optionsRequired = false;
  }

  checkOptionCancel() {
    let data = this.sendData();
    let afterEditOptionA = data.OptionA;
    let afterEditOptionB = data.OptionB;
    let afterEditOptionC = data.OptionC;
    if (afterEditOptionA == "" || afterEditOptionA == null || afterEditOptionA == undefined) {
      this.ideaForm.patchValue({
        optionA: this.optionAvalue
      })
    }
    if (afterEditOptionB == "" || afterEditOptionB == null || afterEditOptionB == undefined) {
      this.ideaForm.patchValue({
        optionB: this.optionBvalue
      })
    }
    if (afterEditOptionC == "" || afterEditOptionC == null || afterEditOptionC == undefined) {
      this.ideaForm.patchValue({
        optionC: this.optionCvalue
      })
    }
    this.optionsRequired = true;
  }

  // Checks whether the option was removed from field or not;
  checkOptionPresence() {
    let data = this.sendData();
    let afterEditOptionA = data.OptionA;
    let afterEditOptionB = data.OptionB;
    let afterEditOptionC = data.OptionC;
    let message = [];
    if (this.optionAvalue == undefined || this.optionBvalue == undefined || this.optionCvalue == undefined) {
      this.optionsRequired = false;
    }
    else {
      if (this.optionAvalue != "" && afterEditOptionA == "") {
        message.push({ Option_A: this.optionRemovalMessage });
      }
      if (this.optionBvalue != "" && afterEditOptionB == "") {
        message.push({ Option_B: this.optionRemovalMessage });
      }
      if (this.optionCvalue != "" && afterEditOptionC == "") {
        message.push({ Option_C: this.optionRemovalMessage });
      }
      if (message.length == 0) {
        this.optionsRequired = false;
        return false;
      }
      else {
        if (this.optionsRequired) {
          this.openDialog(message);
        }
      }
    }
  }

  // Below Object containing status of error;
  // the field value should be Camel Case and two words separated by '_'.. example - Form_Data_Value;
  errorObject = {
    Submitter: false,
    Submitter_Org_Code: false,
    Responsible: false,
    Responsible_Org_Code: false,
    Title: false,
    Current_Situation_And_Complication: false,
    Solution_Proposal: false,
  }

  // Function called everytime whenever there is any error;
  getErrorMessage(message, field, status,) {
    this.formerror = [];
    this.errorObject[field] = JSON.parse(status)
  }

  // used validation of OPW ID;
  checkOpwid(event) {
    let value = (<HTMLInputElement>document.getElementById("inputBox")).value;
    if (value.length == 10) {
      event.preventDefault();
    }
    if (value == "") {
      this.ideaForm.get("OPWNumber").setValue("")
    }
    if (value.length > 10) {
      this.ideaForm.get("OPWNumber").setValue(value.substring(0, 10))
    }
  }
  hideSpecialCharacters(event) {
    return event.keyCode !== 69 && event.keyCode !== 187 && event.keyCode !== 189 && event.keyCode !== 190
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // to check the validation for the categorization page
  checkFPOvalidation() {
    let errorMessage = [];
    let error = serverMessage.fpoValidationMessage;

    if (this.ideaForm.value.IsFPCApproved) {
      if (this.ideaId == null) {
        errorMessage.push(error);
      }
      else if (this.ideaId != null) {
        // && parseInt(this.ideaId) > 0) {
        //  this.catService.getCategorizationDataById(parseInt(this.ideaId)).subscribe((data: any) => {
        //   let errorMsgeFromBack = data.Data ? data.Data.Message : ""
        //   if (data.StatusCode == 200) {
        //     let responseData;
        //     responseData = data;
        //     this.categorizationData = data.Data.Categorization;
        //   }
        // })

        if (this.categorizationData == undefined) {
          errorMessage.push(error);
        }
        else if (this.categorizationData.IsActivelyDriven == null || this.categorizationData.IsCostReductionRequired == null || this.categorizationData.IsDirectImpactOnEbit == null
          || this.categorizationData.IsImpactPermanent == null || this.categorizationData.IsRatioImprovementRequired == null
        ) {
          errorMessage.push(error);
        }
        else if (this.categorizationData.IsCostReductionRequired == false) {
          if (this.categorizationData.IsFixCostAffected == null || this.categorizationData.IsRevenueIncreased == null) {
            errorMessage.push(error);
          }
        }
        else if (this.categorizationData.IsPvoImpacted) {
          if (this.categorizationData.IsRatioImprovementRequired == null) {
            errorMessage.push(error);
          }
          else if (this.categorizationData.CommodityId == null) {
            errorMessage.push(error);
          }
          else if (this.categorizationData.SubCommodityId == null) {
            errorMessage.push(error);
          }
        }
      }

    }
    return errorMessage;
  }
 

  // Its check for error and than if the form is error free..posting of data is done;
  submit(event?: any) {
    if (this.ideaId == null && !this.hasUnsavedChanges && event == undefined) {
      return true
    }
    let fpoValidationError;
    this.submitted = true
    let response = null;
    let error = null;

    fpoValidationError = this.checkFPOvalidation();
    if (fpoValidationError.length != 0 || fpoValidationError == undefined) {
      if (fpoValidationError.length != 0) {
        for (let i of fpoValidationError) {
          this.formerror.push({ [i]: serverMessage.warning });
        }
      }
    }
    for (let i in this.errorObject) {
      if (!this.errorObject[i]) {
        if (i == "Responsible") {
          this.formerror.push({ [messages.ideaForm.responsibleEmailMessage]: this.validationHeaderMessage })
        } else {
          this.formerror.push({ [i]: this.validationHeaderMessage })
        }
      }
    }
    if (this.ideaForm.get("OPWNumber").invalid) {
      this.formerror.push({ "OPW ID": messages.ideaForm.invalidOPWId })
    }
    if (this.formerror.length) {
      this.submitted = false
      this.openDialog(this.formerror);
      this.submitted = false
    } else {
      this.checkOptionPresence();
      if (this.optionsRequired) {
        this.submitted = false
        return;
      }
      else if (this.hasUnsavedChanges) {
        response = this._ideaservice.postData(this.sendData()).toPromise().
          then((res) => {
            this.submitResponse = res;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {
                this.hasUnsavedChanges = false;
                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");
                this.localStorageService.set("ideaId", this.submitResponse.Data.SuccessId);
                if (event != undefined) {
                  //Remove dataCategorization
                  this.sharedService.onRemoveCategorizationData();
                  this.hasUnsavedChanges = false;
                  this.router.navigate(['/manageIdea'])
                }
                return true;
              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer)
                this.submitted = false;
                return false;
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          },
            (error) => {
              this.submitted = false;
              this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
              return false;
            })
      }
      else {
        this.router.navigate(['/manageIdea'])
      }
      return response;
    }
  }

  // move user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }

  // called when cancel button is clicked;
  onClickCancel() {
    //Remove dataCategorization
    this.sharedService.onRemoveCategorizationData();
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }

  // Called to show the error popUp Window
  openDialog(error) {
    //  data is send in the format -- [{field:message},{field:message},{field:message}.....].
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // People Picker Functions;
  fetchUserData(input, bind, formField) {
    let searchInput: string = "";
    // bind == false; it means function is called from the HTML;
    // bind == true; it means function is called from the Class;
    if (!bind) {
      searchInput = input.target.value.toLowerCase();
    } else {
      searchInput = input.toLowerCase();
    }


    if (searchInput.length >= 4) {
      let constString = ideaPageConstants.deletedUser;
      if (searchInput.includes(constString) && bind) {
        if (this.allActInActUser == undefined) {
          this.userReplacementSer.getUserList(Number(this.ideaForm.controls['businessAreaName'].value), Number(this.ideaForm.controls['businessLineName'].value)).subscribe((data) => {
            this.allActInActUser = data;
            let userDetail = [];
            userDetail = this.searchUsrInUsrlst(this.allActInActUser, searchInput);
            this.bindValueToSubRes(bind, formField, userDetail);
            this.fillSearchValue(formField, bind, userDetail);
          })
        } else {
          let userDetail = [];
          userDetail = this.searchUsrInUsrlst(this.allActInActUser, searchInput);
          this.bindValueToSubRes(bind, formField, userDetail);
          this.fillSearchValue(formField, bind, userDetail);
        }
      }
      else {
        if (this.allUserList == undefined) {

          if (this.ideaForm.controls['businessAreaName'].value != "" && this.ideaForm.controls['businessLineName'].value != "") {
            this.userService.getUserList(Number(this.ideaForm.controls['businessAreaName'].value), Number(this.ideaForm.controls['businessLineName'].value)).subscribe((data) => {
              this.allUserList = data;
              let userDetail = [];
              userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
              this.bindValueToSubRes(bind, formField, userDetail);
              this.fillSearchValue(formField, bind, userDetail);
            })

          } else {
            this.bindValueToSubRes(bind, formField, this.loginUserDetails)
            this.fillSearchValue(formField, bind, this.loginUserDetails);
          }
        } else {
          let userDetail = [];
          userDetail = this.searchUsrInUsrlst(this.allUserList, searchInput);
          this.bindValueToSubRes(bind, formField, userDetail);
          this.fillSearchValue(formField, bind, userDetail);
        }
      }
    }
    else {
      if (formField == "responsible") {
        this.responsibleSearchData = [this.oldDataResponsible];
        this.responsiblePeoplePickr.get("responsiblePeoplePickr").setValue(this.oldDataResponsible);
      } else if (formField == "submitter") {
        this.submitterSearchData = [this.oldDataSubmitter];
        this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(this.oldDataSubmitter);
      }
    }
  }

  // this will fill the array with search data if found and set a value to null in the start of the search;
  fillSearchValue(formField, bind, userDetail) {
    if (formField == "submitter") {
      this.submitterSearchData = userDetail;
      if (!bind) {
        this.submitterPeoplePickr.get("submitterPeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    } else if (formField == "responsible") {
      this.responsibleSearchData = userDetail;
      if (!bind) {
        this.responsiblePeoplePickr.get("responsiblePeoplePickr").setValue("");
        this.hasUnsavedChanges = true;
      }
    } else if (formField == "both") {
      this.submitterSearchData = this.responsibleSearchData = userDetail;
    }
  }

  // It will run a search operation in the userlist recieved from DB and return the user Information;
  searchUsrInUsrlst(userList, searchValue) {
    let userDetail = []
    userList.filter(data => {
      if (data.Email.toLowerCase().includes(searchValue)) {
        userDetail.push({
          Email: data.Email,
          FirstName: data.FirstName,
          FullName: data.FullName,
          GID: data.GID,
          IsActive: data.IsActive,
          LastName: data.LastName,
          OrgCode: data.OrgCode,
          RoleId: data.RoleId,
          RoleTitle: data.RoleTitle,
          UserDetails: data.UserDetails,
          UserId: data.UserId
        });
      }
    });
    return userDetail;
  }

  // It will bind the data to the submitter and responsible field on page load;
  bindValueToSubRes(bind, formField, userDetail) {
    if (bind && formField == 'submitter') {
      this.oldDataSubmitter = userDetail[0];

      this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(userDetail[0]);
      this.submitterId = this.submitterPeoplePickr.get("submitterPeoplePickr").value.UserId;
    } else if (bind && formField == 'responsible') {
      this.oldDataResponsible = userDetail[0];

      this.responsiblePeoplePickr.get("responsiblePeoplePickr").setValue(userDetail[0]);
      this.responsibleId = this.responsiblePeoplePickr.get("responsiblePeoplePickr").value.UserId;
    } else if (bind && formField == "both") {
      this.oldDataResponsible = this.oldDataSubmitter = userDetail[0];

      this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(userDetail[0]);
      this.responsiblePeoplePickr.get("responsiblePeoplePickr").setValue(userDetail[0]);
      this.submitterId = this.submitterPeoplePickr.get("submitterPeoplePickr").value.UserId;
      this.responsibleId = this.responsiblePeoplePickr.get("responsiblePeoplePickr").value.UserId;
    }
  }

  // It will clear the search input field once the dropdown is closed;
  clearSearch(formField) {
    if (formField == "submitter") {
      this.submitterSearchData = [this.oldDataSubmitter];
      this.submitterPeoplePickr.get("submitterPeoplePickr").setValue(this.oldDataSubmitter);

      this.searchInputSubmitter.setValue("");
    } else if (formField == "responsible") {
      this.responsibleSearchData = [this.oldDataResponsible];
      this.responsiblePeoplePickr.get("responsiblePeoplePickr").setValue(this.oldDataResponsible);

      this.searchInputResponsible.setValue("");
    }
  }

  // It will focus the cursor to the input search field;
  autofocus(field) {
    this.cd.detectChanges()
    if (field == "sub") {
      setInterval(() => this.myInputField?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputField.nativeElement.focus();
      document.getElementById("myInput")?.focus();
    } else if (field == "res") {
      setInterval(() => this.myInputFieldRes?.nativeElement.focus(), 500);
      // making the focus at the input field at it's opening;
      this.myInputFieldRes.nativeElement.focus();
    }
  }

  // It will assign the ID to submitter and responsible;
  assignValueId(userDetail, formField) {
    if (formField == "submitter") {
      this.oldDataSubmitter = userDetail;
      this.submitterId = userDetail.UserId;
      this.ideaForm.patchValue({
        orgCodeSub: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    } else if (formField == "responsible") {
      this.oldDataResponsible = userDetail
      this.responsibleId = userDetail.UserId;
      this.ideaForm.patchValue({
        orgCodeResp: userDetail.OrgCode
      })
      this.hasUnsavedChanges = true;
    }
  }

  // funtion which will take user to the TFS Link provided in the TFS Link input box;
  tfsLinkBind(para, fromDB) {
    let tfsLinkValue: any;
    tfsLinkValue = fromDB ? para : para.target.value;
    this.tfsLinkValue = tfsLinkValue.includes("https://") || tfsLinkValue.includes("http://") ? tfsLinkValue : "https://" + tfsLinkValue;
  }
  devopsLinkBind(para, fromDB) {
    let devopsinkValue: any;
    devopsinkValue = fromDB ? para : para.target.value;
    this.devopsWorkitemLink = devopsinkValue;
  }
}

// vallidates if only white space is present into the input field;
function validateSpaceOnly(check: FormControl) {
  let regEx = /^ *$/;
  let value = !regEx.test(check.value);
  return value ? true : 'required';
}
