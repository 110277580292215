import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './fixedpage/nav/nav.component';
import { HeaderComponent } from './fixedpage/header/header.component';
import { FooterComponent } from './fixedpage/footer/footer.component';
import { HelpComponent } from './fixedpage/help/help.component';
import { HomeComponent } from './pages/home/home.component';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { CdkTreeModule } from '@angular/cdk/tree';
import { CdkTableModule } from '@angular/cdk/table';
import { MatButtonModule } from '@angular/material/button';
import { AgGridModule } from 'ag-grid-angular';
import { GridComponent } from './grid/grid.component'
import { MatCheckboxModule } from '@angular/material/checkbox';
import { IdeaformComponent } from './pages/ideaform/ideaform.component';
import { EditDeleteComponent } from './grid/button-edit/edit-delete/edit-delete.component';
import { CategorizationComponent } from './pages/categorization/categorization.component';
import { ImpactComponent } from './pages/impact/impact.component';
import { DirectcostComponent } from './pages/plc/directcost/directcost.component';
import 'ag-grid-enterprise';
import { CostsavingsComponent } from './pages/non-plc/costsavings/costsavings.component';
import { AddDeleteComponent } from './pages/non-plc/costsavings/add-delete/add-delete.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DeleteiconComponent } from './pages/deleteIconNormalGrid/deleteicon.component';
import { MatTreeModule } from '@angular/material/tree';
import { RefscenarioComponent } from './pages/non-plc/complexity/refscenario/refscenario.component';
import { TargetscenarioComponent } from './pages/non-plc/complexity/targetscenario/targetscenario.component';

import { DialogComponent } from './dialog/dialog.component';
import { NonPlcSummaryComponent } from './pages/non-plc/non-plc-summary/non-plc-summary.component';
import { MatTableModule } from '@angular/material/table';
import {  MatDatepickerModule } from '@angular/material/datepicker';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { SubdetailsComponent } from './pages/plc/processcost/subdetails/subdetails.component'
import { ProcessCostRefScenarioComponent } from './pages/plc/processcost/refscenario/refscenario.component'
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { DoublingEditorComponent } from './pages/non-plc/costsavings/doubling-editor.component';
import { OptionAComponent } from './pages/non-plc/costsavings/option-a/option-a.component';
import { OptionBComponent } from './pages/non-plc/costsavings/option-b/option-b.component';
import { OptionCComponent } from './pages/non-plc/costsavings/option-c/option-c.component';
import { ToasterComponent } from './toaster/toaster.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { GridlinkComponent } from './pages/plc/processcost/gridlink/gridlink.component';
import { SharedServiceService } from './services/shared-service.service'
import { ComplexityOptionAComponent } from './pages/non-plc/complexity/complexity-option-a/complexity-option-a.component';
import { ComplexityOptionBComponent } from './pages/non-plc/complexity/complexity-option-b/complexity-option-b.component';
import { ComplexityOptionCComponent } from './pages/non-plc/complexity/complexity-option-c/complexity-option-c.component';
import { NumericEditorComponent } from './pages/non-plc/costsavings/numeric-editor.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { DirectcostOptionAComponent } from './pages/plc/directcost/directcost-option-a/directcost-option-a.component';
import { DirectcostOptionBComponent } from './pages/plc/directcost/directcost-option-b/directcost-option-b.component';
import { DirectcostOptionCComponent } from './pages/plc/directcost/directcost-option-c/directcost-option-c.component';

import { default as auth } from '../assets/auth-config.json';

import { HelpEditComponent } from './pages/admin/help-edit/help-edit.component';
import { PlcRefScenerioComponent } from './pages/plc/complexityRisk/plc-ref-scenerio/plc-ref-scenerio.component';
import { PlcTarScenerioComponent } from './pages/plc/complexityRisk/plc-tar-scenerio/plc-tar-scenerio.component';
import { PlcOptionAComponent } from './pages/plc/complexityRisk/plc-option-a/plc-option-a.component';
import { PlcOptionBComponent } from './pages/plc/complexityRisk/plc-option-b/plc-option-b.component';
import { PlcOptionCComponent } from './pages/plc/complexityRisk/plc-option-c/plc-option-c.component';
import { PlcProcessCostOptionAComponent } from './pages/plc/processcost/plc-process-cost-option-a/plc-process-cost-option-a.component';
import { PlcProcessCostOptionBComponent } from './pages/plc/processcost/plc-process-cost-option-b/plc-process-cost-option-b.component';
import { PlcProcessCostOptionCComponent } from './pages/plc/processcost/plc-process-cost-option-c/plc-process-cost-option-c.component';
import { PlcSummaryComponent } from './pages/plc/plc-summary/plc-summary.component';
import { PlcPartsUsedRefScenarioComponent } from './pages/plc/partsUsed/plc-parts-used-ref-scenario/plc-parts-used-ref-scenario.component';
import { PlcPartsUsedOptionAComponent } from './pages/plc/partsUsed/plc-parts-used-option-a/plc-parts-used-option-a.component';
import { PlcPartsUsedOptionBComponent } from './pages/plc/partsUsed/plc-parts-used-option-b/plc-parts-used-option-b.component';
import { PlcPartsUsedOptionCComponent } from './pages/plc/partsUsed/plc-parts-used-option-c/plc-parts-used-option-c.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PlcIBaseRefScenarioComponent } from './pages/plc/Ibase/plc-ibase-ref-scenario/plc-ibase-ref-scenario.component';
import { PlcIBaseOptionAComponent } from './pages/plc/Ibase/plc-ibase-option-a/plc-ibase-option-a.component';
import { PlcIBaseOptionBComponent } from './pages/plc/Ibase/plc-ibase-option-b/plc-ibase-option-b.component';
import { PlcIBaseOptionCComponent } from './pages/plc/Ibase/plc-ibase-option-c/plc-ibase-option-c.component';

import { QuillModule } from 'ngx-quill';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ManagedataComponent } from './managedata/managedata.component';
import { NumericvalueEditorComponent } from './pages/plc/Ibase/plc-ibase-ref-scenario/numeric-value-editor.component';

import { LoginCheckComponent } from './pages/login-check/login-check.component';
import { GridDatepickerComponent } from './pages/grid-datepicker/grid-datepicker.component';

import { UserManagementComponent } from './pages/admin/user-management/user-management.component';
import { PlcIbaseTarScenarioComponent } from './pages/plc/Ibase/plc-ibase-tar-scenario/plc-ibase-tar-scenario.component';
import { ProcessCostTgtScenarioComponent } from './pages/plc/processcost/tgtscenario/tgtscenario.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PlcSummaryanalysisRefscenarioComponent } from './pages/plc/plc-summary/plc-summaryanalysis-refscenario/plc-summaryanalysis-refscenario.component';
import { PlcSummaryanalysisTgtscenarioComponent } from './pages/plc/plc-summary/plc-summaryanalysis-tgtscenario/plc-summaryanalysis-tgtscenario.component';
import {PlcSummaryanalysisOptionAComponent} from  './pages/plc/plc-summary/plc-summaryanalysis-option-a/plc-summaryanalysis-option-a.component'
import {PlcSummaryanalysisOptionBComponent} from './pages/plc/plc-summary/plc-summaryanalysis-option-b/plc-summaryanalysis-option-b.component'
import {PlcSummaryanalysisOptionCComponent} from './pages/plc/plc-summary/plc-summaryanalysis-option-c/plc-summaryanalysis-option-c.component'
import { MasterDataComponent } from './pages/admin/master-data/master-data.component';
import { PeoplePickerComponent } from './people-picker/people-picker.component';
import { MatSelectModule } from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { PeopleDropDownComponent } from './people-drop-down/people-drop-down.component';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { ContactDropdownComponent } from './contact-dropdown/contact-dropdown.component';
import { AuditLogComponent } from './pages/audit-log/audit-log.component';
import { MDUnitsComponent } from './pages/admin/md-units/md-units.component';
import { ProductManagementComponent } from './pages/admin/product-management/product-management.component';
import { CheckboxComponent } from './pages/admin/checkbox/checkbox.component';
import { UserReplacementComponent } from './pages/admin/user-replacement/user-replacement.component';
import { ActualUnitComponent } from './pages/admin/actual-unit/actual-unit.component';
import { PeriodLockComponent } from './pages/admin/period-lock/period-lock.component';
import { FxtranslationComponent } from './pages/admin/fx-translation/fxtranslation.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;



export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: auth.credentials.clientId,

      authority: 'https://login.microsoftonline.com/' + auth.credentials.tenantId,
      redirectUri: auth.configuration.redirectUri,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
      //cacheLocation:'localStorage',
      //storeAuthStateInCookie:true
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(auth.resources.todoListApi.resourceUri, auth.resources.todoListApi.resourceScopes);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/users', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeaderComponent,
    FooterComponent,
    HelpComponent,
    HomeComponent,
    GridComponent,
    IdeaformComponent,
    EditDeleteComponent,
    CategorizationComponent,
    ImpactComponent,
    DirectcostComponent,
    CostsavingsComponent,
    AddDeleteComponent,

    DeleteiconComponent,
    RefscenarioComponent,
    TargetscenarioComponent,
    DialogComponent,
   
    NonPlcSummaryComponent,
    AlertDialogComponent,
    SubdetailsComponent,
    ProcessCostRefScenarioComponent,
    DoublingEditorComponent,
    OptionAComponent,
    OptionBComponent,
    OptionCComponent,
    ToasterComponent,
    GridlinkComponent,
    ComplexityOptionAComponent,
    ComplexityOptionBComponent,
    ComplexityOptionCComponent,
    NumericEditorComponent,
    DirectcostOptionAComponent,
    DirectcostOptionBComponent,
    DirectcostOptionCComponent,
    HelpEditComponent,
    DirectcostOptionCComponent,
    PlcRefScenerioComponent,
    PlcTarScenerioComponent,
    PlcOptionAComponent,
    PlcOptionBComponent,
    PlcOptionCComponent,
    PlcProcessCostOptionAComponent,
    PlcProcessCostOptionBComponent,
    PlcProcessCostOptionCComponent,
    PlcSummaryComponent,
    PlcPartsUsedRefScenarioComponent,
    PlcPartsUsedOptionAComponent,
    PlcPartsUsedOptionBComponent,
    PlcPartsUsedOptionCComponent,
    PlcIBaseRefScenarioComponent,
    PlcIBaseOptionAComponent,
    PlcIBaseOptionBComponent,
    PlcIBaseOptionCComponent,
    PageNotFoundComponent,
    ManagedataComponent,
    LoginCheckComponent,
    UserManagementComponent,
    GridDatepickerComponent,
    NumericvalueEditorComponent,
    PlcIbaseTarScenarioComponent,
    ProcessCostTgtScenarioComponent,
    ProgressSpinnerComponent,
    PlcSummaryanalysisRefscenarioComponent,
    PlcSummaryanalysisTgtscenarioComponent,
    PlcSummaryanalysisOptionAComponent,
    PlcSummaryanalysisOptionBComponent,
    PlcSummaryanalysisOptionCComponent,
    MasterDataComponent,
    PeoplePickerComponent,
    PeopleDropDownComponent,
    ContactDropdownComponent,
    AuditLogComponent,
    MDUnitsComponent,
    ProductManagementComponent,
    CheckboxComponent,
    UserReplacementComponent,
    ActualUnitComponent,
    PeriodLockComponent,
    FxtranslationComponent,
   
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    BrowserAnimationsModule,
    CdkTreeModule,
    CdkTableModule,
    MatButtonModule,
    AgGridModule.withComponents([AddDeleteComponent, GridDatepickerComponent]),
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    MatTreeModule,
    MatDialogModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MsalModule,
    MatProgressSpinnerModule,
    QuillModule.forRoot(),
    MatSelectModule,
    MatInputModule,
    AgChartsAngularModule,

    MatRippleModule,
    MatAutocompleteModule,

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
    , {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    SharedServiceService,
    MsalGuard,
    MsalBroadcastService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    SharedServiceService, DatePipe

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [GridDatepickerComponent]

})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon(
      'add',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg'));
  }

}
