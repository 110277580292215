import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MComplxCmplxLvl } from '../models/mComplxCmplxLvl';
import { MComplxProb } from '../models/mComplxProb';
import { ComplexityRiskDetail } from '../models/complexity-risk-detail';
import { EnumMasterType } from '../enum/MasterType';

@Injectable({
  providedIn: 'root'
})

export class NonCmplxRefSecService {
  // contains the baseUrl;
  readonly baseUrl = environment.baseURL;
  constructor(private http: HttpClient) { }
getSettingData(ideaId,isPlc) {
  return this.http.get(this.baseUrl+"api/ComplexityRisk/GetSettingsForComplexityRisk"+'?ideaId='+ideaId+ '&isPLC=' + isPlc).pipe(
    retry(1),
    catchError(this.handleError))
}
  getAllData(ideaId, solutionId, isPLC, userId) {
    return this.http.get(this.baseUrl + "api/ComplexityRisk/GetComplexityRiskData" + '?ideaId=' + ideaId + '&solutionId=' + solutionId + '&isPLC=' + isPLC + '&userId=' + userId).pipe(
      retry(1),
      catchError(this.handleError))
  }

  public urlMasterComplxLvl = "api/Master/GetAllData";
  getMasterComplxLvl(): Observable<MComplxCmplxLvl> {
    return this.http.get<MComplxCmplxLvl>(this.baseUrl + this.urlMasterComplxLvl + '?masterTypeId='+EnumMasterType.ComplexityLevel).pipe(
      retry(1),
      catchError(this.handleError))
  }

  getMasterRiskProb(): Observable<MComplxProb> {
    return this.http.get<MComplxProb>(this.baseUrl + this.urlMasterComplxLvl + '?masterTypeId='+EnumMasterType.Probability).pipe(
      retry(1),
      catchError(this.handleError))
  }
  getMasterRiskRemainProb(): Observable<MComplxProb> {
    return this.http.get<MComplxProb>(this.baseUrl + this.urlMasterComplxLvl + '?masterTypeId='+EnumMasterType.Probability).pipe(
      retry(1),
      catchError(this.handleError))
  }

  postAllData(data): Observable<ComplexityRiskDetail> {
    return this.http.post<ComplexityRiskDetail>(this.baseUrl + "api/ComplexityRisk/InsertUpdateComplexityRiskData", data).pipe(
      retry(1),
      catchError(this.handleError))
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error || "Server Error");
  }

}
