import { Component, OnInit, HostListener, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ColumnApi, GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community';
import { DeleteiconComponent } from '../../pages/deleteIconNormalGrid/deleteicon.component';
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";

import { ImpactService } from '../../services/impact.service';
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { Dirty } from 'src/app/interface/dirty-interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { Router } from '@angular/router';

import { messages } from '../../popUpMessages/messages';
import { serverMessage } from '../../popUpMessages/serverMessage';
import { MenuService } from 'src/app/services/menu.service';
import { EnumErrorCodes } from 'src/app/enum/EnumErrorCodes';
import { ContactDropdownComponent } from '../../contact-dropdown/contact-dropdown.component';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from "src/app/services/user-info.service";
import { CategorizationService } from "../../services/categorization.service";
import { FxRatesService } from 'src/app/services/fx-rates.service';
import { default as moment } from 'moment';
import { PlcSummaryService } from 'src/app/services/plc-summary.service';
import { NonplcsummaryService } from 'src/app/services/nonplcsummary.service';
import { HelpService } from 'src/app/services/help.service';


interface Node {
  name: string;
  id?: number;
  selected?: boolean;
  indeterminate?: boolean;
  children?: Node[];
  type?: string;
}
@Component({
  selector: 'app-impact',
  templateUrl: './impact.component.html',
  styleUrls: ['./impact.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImpactComponent implements OnInit, Dirty {
  public domLayout: any;
  private orgapi: GridApi;
  private orgcolumnApi: ColumnApi;
  public orgGridOptions: Partial<GridOptions>;
  public errorMessage = [];
  public treeControl = new NestedTreeControl<Node>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<Node>();
  public data: any;
  public selectedNode: any;
  public flatdata: Node[] = [];
  //dataBind = {};
  dataBind: any;
  gridForm: FormGroup = new FormGroup({
    ImpactedOrganization: new FormGroup({}),
    ImpactedProduct: new FormGroup({})
  });

  categorizationForm: FormGroup;
  public orgColumnDefs: any
  public orgRowData: any;
  public resPostOrgData: any;
  public resGetOrgData: any;
  public mDataOrgUnitTtl: any;
  public mDataImpactTtl: any;
  public mDataImpProductTtl: any;
  public mProduct = [];
  public mOrgUnit = [];
  public mImpact = [];
  public sortingOrder: any;
  public defaultColDef: any;
  public submitResponse: any;
  public hideButton = true;
  public recImpactedOrgs = [];
  public recAllData: any;
  public rowOrgAllData = [];
  public showGrid = false;
  public showTree = false;
  public showopw = false;
  public recImactedProd = [];
  public selectedDataProduct: any;
  public ImProdIdSelData = [];
  public alertErrorBox = [];
  public popupParent: any;
  public hasUnsavedChanges: boolean = false;
  public isEditable: boolean = false;
  public saveBtnDisplay: boolean = true;
  public ideaSubmitterId: any;
  public modifiedOrgRows = [];
  public ideaId: any;
  public submitted: boolean = false;

  // used during sending the data to database;
  public gridDataSend: any = [];
  public treeDataSend: any = [];
  public categorizationDataSend: any = [];
  public allDataSend = {};
  public modifiedTreeData: any = [];
  public dbSelectedNodeIds = [];
  public frameworkComponents: any;

  public isUser = false;
  public rowIndex: any;

  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;

  //organisation
  public businessAreaId: number;
  public businessLineId: number;

  public userInOrg: any;
  public allUserList: any;
  //Measure Type
  measureType: any = [];
  //Measure category		
  measureCategory: any = [];
  // Currency 
  masterCurrencyData: any;
  filtered: any;
  //Cost Impact		
  costImpact: any = [];
  //currency
  finalCurrencyIdToSend: any;
  filterValue: string;
  categorizationData: any = [];
  public allopwdata: any[];
  public nonPlcData: any[];
  public formerror = [];
  public isPLCrequiredFromDB: any;
  public showform = false;
  public headerMessage = "Please enter below required field:";
  public recCategorizationData = [];
  originalStatus: any;
  userConsent: any;
  orginalStartDate: any;
  submitData: any;
  public recentChangedStatus: any;
  loginUserDetails: any;
  //Product Lifecycle Cost assessment			
  costAssessment: any[] = [{ id: 1, title: "Required" }, { id: 2, title: "Not Required" }];
  //Status
  status: any = [];
  receivedHelpIconTextData: any;

  // Help Text
  MeasureTypeHelpText: any;
  MeasureCategoryHelpText: any;
  StartDateHelpText: any;
  CurrencyHelpText: any;
  CostImpactHelpText: any;
  PLCAssesmentHelpText: any;
  ImpactedOrganizationtext: any;
  StatusHelpText: any;
  public HelpTextList: any;
  public errorObject = {
    'Measure Type': false,
    'Measure Category': false,
    'Currency': false,
    'StartDate': false,
    'Cost Impact': false,
    'Status': false,
    'Product Lifecycle Cost assessment': false,
  };

  public getErrorMessage(message, field, status,) {
    this.formerror = []
    this.errorObject[field] = JSON.parse(status)
  };


  @ViewChild('impactGrid') impactGrid: ElementRef;
  constructor(
    public fb: FormBuilder,
    private catService: CategorizationService,
    private fxservice: FxRatesService,
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private impactService: ImpactService,
    public dialog: MatDialog,
    private localStorageService: LocalStorageService,
    private ideaService: IdeaformService,
    private router: Router,
    private menuService: MenuService,
    private userService: UserInfoService,
    private plcSummaryService: PlcSummaryService,
    private NonplcsummaryService: NonplcsummaryService,
    private service: HelpService,
  ) {
    this.popupParent = document.querySelector('body');
    this.frameworkComponents = { contactDropdownEditor: ContactDropdownComponent };
  }

  @HostListener('document:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    let KeyboardEvent: any = event;
    if (KeyboardEvent) {
      let keyCode = KeyboardEvent.keyCode;
      // keyCode = 27 is for the "Escape Key";
      if (keyCode == 27) {
        this.orgapi.stopEditing(false);
      }
      // keyCode = 27 is for the "Tab Key";
      else if (keyCode == 9) {
        let currentColumn = [];
        if (currentColumn.length != 0) {
          let field = currentColumn[0].column.colDef.field;
          if (field == "User") {
            this.isUser = true;
            let rowIndex = currentColumn[0].rowIndex;
            this.rowIndex = rowIndex;
            this.orgapi.setFocusedCell(rowIndex, 'User');
          } else if (field == "Impact") {
            let rowIndex = currentColumn[0].rowIndex;
            this.orgapi.setFocusedCell(rowIndex, 'Impact');
          } else {
            this.orgapi.stopEditing(false);
          }
        }
        else {
          if (this.isUser) {
            this.orgapi.setFocusedCell(this.rowIndex, 'Impact');
            this.isUser = false;
            this.rowIndex = null;
          }
        }
      }
    }
  }


  ngOnInit() {
    this.sharedService.getIdeaIdAndtitle();

    //Get current loging user details
    // this.sharedService.ideaSubmitterId.subscribe(
    //   (id) => {
    //     this.ideaSubmitterId = id;
    //   }
    // )
    // for popu up click ok
    this.sharedService.catgorizationOnClickOk.subscribe(
      () => {
        this.userConsent = true;
      }
    )
    // for popu up click cancel
    this.sharedService.catgorizationOnClickCancel.subscribe(
      () => {
        this.userConsent = false;
        this.categorizationForm.patchValue(
          {
            IsPlcrequired: this.isPLCrequiredFromDB ? 1 : 2,
            MeasureStatusId: this.originalStatus,
            StartDate: new Date(this.orginalStartDate)
          }
        )
      }
    )
    this.sharedService.impactOrgDeletedRows.subscribe(
      res => {

        this.hasUnsavedChanges = true
        if (res.ImpactedOrgId != 0) {
          res.IsActive = false;
          let isPushed = false
          if (this.modifiedOrgRows.length == 0) {
            this.modifiedOrgRows.push(res)
            isPushed = true;
          }
          else {
            this.modifiedOrgRows.forEach(row => {
              if (row.ImpactedOrgId == res.ImpactedOrgId) {
                isPushed = true;
              }
            });
          }
          if (!isPushed) {
            this.modifiedOrgRows.push(res)
          }
        }
        this.onRowClicked("")
      }
    )
    //Make categorization Form with properties
    this.categorizationForm = this.fb.group({
      CategorizationId: [0],
      IdeadId: [this.ideaId],
      MeasureTypeId: ['', [Validators.required]],
      MeasureCategoryId: ['', [Validators.required]],
      CurrencyId: ['', [Validators.required]],
      StartDate: ['', [Validators.required]],
      CostImpactId: ['', [Validators.required]],
      MeasureStatusId: ['', [Validators.required]],
      IsPlcrequired: ['', [Validators.required]],
    });

    this.categorizationForm.valueChanges.subscribe(e => {
      if (!this.hasUnsavedChanges)
        this.hasUnsavedChanges = this.categorizationForm.dirty;
    })
    this.hideButton = false;

    let ideaId = this.localStorageService.get('ideaId');
    if (ideaId != null) {
      this.ideaService.getData(ideaId).subscribe((data: any) => {
        if (data.StatusCode == 200) {
          this.businessAreaId = data.Data.BusinessAreaId;
          this.businessLineId = data.Data.BusinessLineId;
          this.sharedService.loggedInUser.subscribe((user: any) => {
            if (user) {
              this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d) => {
                this.allUserList = d;
                this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
                if (this.userInOrg != undefined) {
                  this.ideaSubmitterId = this.userInOrg.UserId;

                  if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                    this.isEditable = true;
                  }
                  else if (this.userInOrg.RoleId == 3) {
                    if (data.Data.SubmitterId == this.userInOrg.UserId) {
                      this.isEditable = true;
                    }
                    else {
                      this.isEditable = false;
                    }
                  }
                  this.masterDataOrgUnit();
                }
              })
            }
          })
          //Bind all Categorizatio Dropdown 
          this.bindCategorizationDropdown();
        }
      });
    } else {
      this.businessAreaId = this.businessLineId = 0;
      this.masterDataImpact();
      //Bind all Categorizatio Dropdown 
      this.bindCategorizationDropdown();
    }
    this.refreshHelpIconText();
    this.checkStartDateValidation();
  }
  refreshHelpIconText() {
    this.service.getAllText().subscribe(data => {
      this.HelpTextList = data;

      let successCode = this.HelpTextList.StatusCode;
      if (successCode != 200) {
        let errorMsgeFromBack = this.HelpTextList.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
        return false;
      }
      else if (successCode == 200) {
        this.receivedHelpIconTextData = this.HelpTextList.Data;
        this.bindhelpicontextdata();
      }
    });
  }

  bindhelpicontextdata() {
    for (let i of this.receivedHelpIconTextData) {

      if (i.HelpIconId == 1 && i.Language == "English") {

        this.MeasureTypeHelpText = i.HelpIconText;
      }
      if (i.HelpIconId == 2 && i.Language == "English") {

        this.MeasureCategoryHelpText = i.HelpIconText;
      }
      if (i.Component == "StartDate" && i.Language == "English") {

        this.StartDateHelpText = i.HelpIconText;
      }
      if (i.Component == "Currency" && i.Language == "English") {

        this.CurrencyHelpText = i.HelpIconText;
      }
      if (i.Component == "CostImpact" && i.Language == "English") {

        this.CostImpactHelpText = i.HelpIconText;
      }
      if (i.Component == "PLCAssesment" && i.Language == "English") {

        this.PLCAssesmentHelpText = i.HelpIconText;
      }
      if (i.Component == "Status" && i.Language == "English") {

        this.StatusHelpText = i.HelpIconText;
      }
      if(i.Component == "Impacted Organization" && i.Language == "English"){

      }this.ImpactedOrganizationtext = i.HelpIconText;
     
    }
  }
  canDeactivate() {
    return this.hasUnsavedChanges
  }

  openDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // Function is Called when row is clicked on grid;
  onRowClicked(event) {
    this.orgapi.setDomLayout('normal');
    document.getElementById('orgGrid').style.height = '290px';
    let currentGridHeight = document.getElementById('orgGrid').offsetHeight;
    let currentProductTreeHght = document.getElementById('product').offsetHeight;
    if (currentGridHeight <= currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '290px';
    } else if (currentGridHeight >= currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '507px';
    }
  }

  // Its Vallidate the rows of Grid;Checks if the feild are empty or not during adding of new value;
  validateRow(value) {
    this.alertErrorBox = [];
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    let error = [];
    this.orgapi.forEachNode(node => count += 1);
    count = count - 1;
    let countRow = this.orgapi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      if (value == 1 && error.length == 0) {
        this.orgapi.applyTransaction({
          add: [{
            OrgUnit: {
              orgUnitTitle: "Please Select",
              orgUnitId: null
            },
            User: null,
            Email: null,
            ContactEmail: null,
            Impact: {
              impactTitle: "Please Select",
              impactId: null
            },
            ImpactedOrgId: 0,
            IdeaId: 0,
            IsActive: true,
            CreatedBy: this.ideaSubmitterId,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }
          ], addIndex: 0
        })

      } else if (value == 2 && error.length == 0) {
        return true;
      }

    } else {
      let data = this.orgapi.getDisplayedRowAtIndex(0).data;
      if (data.OrgUnit.orgUnitTitle == "" || data.OrgUnit.orgUnitTitle == "Please Select" || data.OrgUnit.orgUnitId == null) {
        error.push({ Org_unit: errorMessage })
      }
      // this return false when only space is present in the User name;
      let regex = new RegExp(/[^\\s]+/);
      let checked = regex.test(data.User)
      if (data.User == "" || data.User == null || !checked) {
        error.push({ Contact: errorMessage })
      }
      if (data.Impact.impactTitle == "" || data.Impact.impactTitle == "Please Select" || data.Impact.impactId == null) {
        error.push({ Impact: errorMessage })
      };
      if (value == 1 && error.length == 0) {
        this.orgapi.applyTransaction({
          add: [{
            OrgUnit: {
              orgUnitTitle: "Please Select",
              orgUnitId: null
            },
            User: null,
            Email: null,
            ContactEmail: null,
            Impact: {
              impactTitle: "Please Select",
              impactId: null
            },
            ImpactedOrgId: 0,
            IdeaId: 0,
            IsActive: true,
            CreatedBy: this.ideaSubmitterId,
            CreatedOn: null,
            ModifiedBy: null,
            ModifiedOn: null,
            IsEditable: this.isEditable
          }
          ], addIndex: 0
        })


      } else if (value == 2 && error.length == 0) {
        return true;
      } else if (value == 1 && error.length != 0) {
        this.alertErrorBox = error
        this.openAlertDialog(error)
      } else if (value == 2 && error.length != 0) {
        this.alertErrorBox = error;
        return false;
      }
    }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  AddNewRowImpact() {
    this.orgapi.stopEditing();

    this.validateRow(1);
    let currentGridHeight = document.getElementById('orgGrid').offsetHeight;
    let currentProductTreeHght = document.getElementById('product').offsetHeight;
    if (currentGridHeight <= currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '290px';
    } else if (currentGridHeight >= currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '507px';
    }
    this.hasUnsavedChanges = true;
  }

  masterDataOrgUnit() {
    this.impactService.getMasterDataOrgUnit(this.businessAreaId, this.businessLineId).subscribe((data1: any) => {

      let errorMsgeFromBack = data1.Data.Message;
      if (data1.StatusCode == 200) {

        this.mDataOrgUnitTtl = data1
        this.mDataOrgUnitTtl = this.mDataOrgUnitTtl.Data
        for (let i of this.mDataOrgUnitTtl) {
          this.mOrgUnit.push({ orgUnitId: i.OrgUnitId, orgUnitTitle: i.OrgUnitTitle })
        }

        this.masterDataImpact();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    },
      (error) => {
        this.hideButton = true;
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      })
  }

  masterDataImpact() {
    this.impactService.getMasterDataImpact(this.businessAreaId, this.businessLineId).subscribe((data: any) => {
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {
        this.mDataImpactTtl = data
        this.mDataImpactTtl = this.mDataImpactTtl.Data
        for (let i of this.mDataImpactTtl) {
          this.mImpact.push({ impactId: i.ImpactId, impactTitle: i.ImpactTitle })
        }
        this.masterDataProduct();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    },
      (error) => {
        this.hideButton = true;
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      })
  }

  masterDataProduct() {
    this.impactService.getMasterDataProduct(this.businessAreaId, this.businessLineId).subscribe((data: any) => {
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {

        this.mDataImpProductTtl = data;
        this.mDataImpProductTtl = this.mDataImpProductTtl.Data;

        for (let i of this.mDataImpProductTtl) {
          this.mProduct.push({ productId: i.ProductId, productTitle: i.ProductTitle })
        }
        this.loadAllData();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    },
      (error) => {
        this.hideButton = true;
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      })
  }

  loadAllData() {
    this.showopw = true;
    this.ideaId = this.localStorageService.get('ideaId')
    if (this.ideaId == null) {
      this.ideaId = 0;
    }

    if (this.userInOrg == undefined) {
      this.userInOrg = {
        UserId: 0
      }
    }
    this.impactService.getAllData(this.ideaId, this.userInOrg.UserId).subscribe((data: any) => {
      let errorMsgeFromBack = data.Data.Message;
      if (data.StatusCode == 200) {
        this.recAllData = data;

        this.recAllData = this.recAllData.Data;
        this.recImpactedOrgs = this.recAllData.ImpactedOrgs;
        this.recImactedProd = this.recAllData.ImpactedProducts;
        this.recCategorizationData = this.recAllData.Categorization;

        // for remark field;
        this.remarkDataRecieved = this.recAllData.Remark;
        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }

        let count = 0;
        for (let i of this.recImpactedOrgs) {
          count += 1
          let rowDataOrg = {};
          rowDataOrg["IsEditable"] = this.recAllData.IsEditable
          for (let j in i) {
            if (j == "OrgUnitId") {
              let orgUnitValue = i.OrgUnitId;
              for (let k of this.mOrgUnit) {
                if (k.orgUnitId == orgUnitValue) {
                  let obj = {}
                  obj["orgUnitTitle"] = k.orgUnitTitle;
                  obj["orgUnitId"] = orgUnitValue;
                  rowDataOrg["OrgUnit"] = obj;
                }
              }
            }
            else if (j == "ImpactId") {
              let impactId = i.ImpactId;
              for (let k of this.mImpact) {
                if (k.impactId == impactId) {
                  let obj = {};
                  obj["impactTitle"] = k.impactTitle;
                  obj["impactId"] = impactId;
                  rowDataOrg["Impact"] = obj;
                }
              }
            }
            else {
              // rowDataOrg["email"]="singh.shashank.ext@siemens-healthineers.com;hamzah.akhter.ext@siemens-healthineers.com"
              rowDataOrg["Email"] = i.ContactEmail
              rowDataOrg[j] = i[j];
            }
          }
          this.rowOrgAllData.push(rowDataOrg);
        }
        this.orgRowData = this.rowOrgAllData;

        let selectedProduct = [];
        for (let i of this.recImactedProd) {
          selectedProduct.push({ id: i.ProductId });
          this.ImProdIdSelData.push({ id: i.ProductId, ImpactedProductId: i.ImpactedProductId });
        }
        this.selectedDataProduct = selectedProduct;
        // Local storage:- setting isPLCselected =true/false;
        if (this.selectedDataProduct.length != 0) {
          this.localStorageService.set('isPLCseclected', true);
        } else {
          this.localStorageService.set('isPLCseclected', false);
        }
        this.loadTree();
        this.getCategorizationFormData(this.recCategorizationData);
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    },
      (error) => {
        this.hideButton = true;
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
      })
  }

  checkForError() {
    if (this.errorMessage.length != 0) {
      this.hideButton = false;
      this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
    } else {
      this.saveBtnDisplay = this.isEditable;
      this.loadGrid();
    }
  }
  onColumnResized(params) {

    params.api.resetRowHeights();
  }

  onColumnVisible(params) {

    params.api.resetRowHeights();
  }

  loadGrid() {
    let regex = new RegExp(/\^s*$/);
    this.showGrid = true;
    this.defaultColDef = {
      editable: this.isEditable,
      sortable: false,
      suppressMovable: true,

      wrapText: true,
      autoHeight: false,
    };
    this.domLayout = 'normal';
    this.orgColumnDefs = [
      {
        field: 'OrgUnit', cellEditor: 'agRichSelectCellEditor', headerTooltip: "Org Unit",
        cellRenderer: mOrgUnitCellRenderer, tooltipField: 'OrgUnit.orgUnitTitle',
        keyCreator: function (OrgUnit) {
          return OrgUnit.orgUnitTitle;
        },
        cellEditorParams: {
          cellRenderer: mOrgUnitCellRenderer,
          values: this.mOrgUnit
        }, singleClickEdit: true,
        width: 100,
        cellStyle: (params) => (params.data.OrgUnit.orgUnitTitle == 'Please Select') ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' }
      },
      {
        headerName: 'Contact', field: 'User', headerTooltip: "Contact",
        cellEditor: 'contactDropdownEditor',
        width: 140,
        cellStyle: (params) => (params.data.User == "" || params.data.User == null) || regex.test(params.data.User) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },
        singleClickEdit: true, tooltipField: 'User', cellRenderer: function (param) {

          if (param.data.User != null) {
            let multilineVal = param.data.User.split(";").join('<br/>')

            return multilineVal;
          } else {
            return param.data.User;
          }

        },

      },
      {
        field: 'Impact', cellEditor: 'agRichSelectCellEditor', headerTooltip: "Impact",
        cellRenderer: mImpactCellRenderer,
        tooltipField: 'Impact.impactTitle',
        keyCreator: function (Impact) {
          return Impact.impactTitle;
        },
        cellEditorParams: {
          cellRenderer: mImpactCellRenderer,
          values: this.mImpact
        },
        singleClickEdit: true,
        width: 60,
        cellStyle: (params) => (params.data.Impact.impactTitle == 'Please Select') ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' }
      },
      {
        headerName: 'Action', headerTooltip: "Action", width: 40, field: 'del', editable: false, cellRendererFramework: DeleteiconComponent,
      }
    ]
    this.showGrid = true;
  }

  loadTree() {
    this.showTree = true;
    this.dataSource.data = this.mDataImpProductTtl;
    Object.keys(this.dataSource.data).forEach(x => {
      this.setParent(this.dataSource.data[Number(x)], null);
    });
    this.getFlatData(this.dataSource.data)
    this.getSelectedNode(this.flatdata)
    this.saveBtnDisplay = this.isEditable;
    this.loadGrid();
  }

  // this function is called whenever there is any change in cell'c valuein the Impact Org Grid;
  onCellValueChanged(params) {

    // this will reset the row height when there is new value is added in any cell; here it's used for the "Contact" column;
    params.api.resetRowHeights();
    // it's true it means there is change in value of the grid; If it's true then only "POST Api" is called;
    this.hasUnsavedChanges = true;
    // the below logic is to add the updated row only;
    if (params.data.ImpactedOrgId != 0) {
      let isPushed = false;
      if (this.modifiedOrgRows.length == 0) {
        this.modifiedOrgRows.push(params.data)
        isPushed = true;
      }
      else {
        this.modifiedOrgRows.forEach(row => {
          if (row.ImpactedOrgId == params.data.ImpactedOrgId) {
            isPushed = true;
          }
        })
      }
      if (!isPushed) {
        this.modifiedOrgRows.push(params.data);
      }
    }
  }

  // this function is called directly from the grid to reset the overall dimension of the grid;
  onGridSizeChanged(params) {
    this.orgapi = params.api;
    this.orgapi.sizeColumnsToFit();

  }

  // To get the modified impacted org grid data
  AllOrgData() {
    let rowData = [];
    this.orgapi.forEachNode(node => rowData.push(node.data));
    return this.modifiedOrgRows;
  }

  // for grid --contains the params of Grid; Called on load of the grid;
  OrggridReady(params: GridReadyEvent) {
    this.orgapi = params.api;
    this.orgcolumnApi = params.columnApi;
    //logic to bring vertical scroll in the grid;
    let currentGridHeight = document.getElementById('orgGrid').offsetHeight;
    let currentProductTreeHght = document.getElementById('product').offsetHeight;
    if (currentGridHeight < currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '290px';
    } else if (currentGridHeight >= currentProductTreeHght) {
      this.orgapi.setDomLayout('normal');
      document.getElementById('orgGrid').style.height = '507px';
    }

    // this shared service is emitted when the contact people picker is closed completly; it reset the grid data with the new data;
    this.sharedService.peoplePickerGridApi.subscribe(api => {
      this.orgapi = api
    })
  }

  hasChild = (_: number, node: Node) =>
    !!node.children && node.children.length > 0;

  // To set the parent of a node and make tree like structure
  setParent(data: any, parent: any) {
    data.parent = parent;
    if (data.children) {
      data.children.forEach((x: any) => {
        this.setParent(x, data);
      });
    }
  }

  // To check all parent node if a particular node is selected
  checkAllParents(node: any) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent).filter((node) => node.type == "product");
      node.parent.selected = descendants.every(child => child.selected);
      node.parent.indeterminate = descendants.some(child => child.selected);
      this.checkAllParents(node.parent);
    }
  }

  // toggle node selection
  todoItemSelectionToggle(checked: any, node: any, event?: any) {
    if (event != undefined) { //if the product node is selected/unselected from UI
      this.hasUnsavedChanges = true;
    }
    this.manageModifiedTreeData(checked, node);
    node.selected = checked;

    if (node.children) {
      node.children.forEach((x: any) => {
        if (x.type !== "version") {
          this.todoItemSelectionToggle(checked, x);
        }
      });
      if (!node.selected && node.children) {
        if (node.children.every((child: any) => child.type == "version")) {
          node.children.forEach((x: any) => {
            x.selected = false;
            this.manageModifiedTreeData(false, x);
          });
        }
      }
    }
    if (node.type == "product") {
      this.checkAllParents(node);
    } else {
      if (checked && event != undefined && !node.parent.selected) {
        this.manageModifiedTreeData(true, node.parent);
      }
      this.checkImmediateParent(node);
    }
  }

  /* To make the immediate parent of version node fully checked 
   instead of indeterminate even if all the version aren't selected*/
  checkImmediateParent(node: any) {
    node.parent.selected = true;
    node.parent.indeterminate = false;
    this.checkAllParents(node.parent);
  }

  manageModifiedTreeData(checked, node) {
    if (!node.selected && checked && (!node.children?.some(child => child.type == "product"))) {
      node.IsActive = true;
      node.CreatedBy = this.ideaSubmitterId;
      node.ModifiedBy = null;
      this.getModifiedTreeData(node);
    }
    if (node.selected && checked && node.children.length > 0 && node.children.every(child => child.type == "version")) {
      if (!this.dbSelectedNodeIds.includes(node.id)) {
        this.getModifiedTreeData(node);
      }
    }
    // if the selected node is unchecked 
    if (!checked) {
      node.ModifiedBy = this.ideaSubmitterId;
      node.IsActive = false;
      if (!node.children || !node.children.some(child => child.type == "product" && this.dbSelectedNodeIds.includes(child.Id))) {
        this.getModifiedTreeData(node);
      }
      if (!this.dbSelectedNodeIds.includes(node.id)) {
        this.modifiedTreeData = this.modifiedTreeData.filter(modifiedNode => {
          return modifiedNode.id != node.id;
        })
      }
    }
  }

  // this will give all the modified tree data;
  getModifiedTreeData(node) {
    let isPushed = false;
    if (this.modifiedTreeData.length == 0) {
      this.modifiedTreeData.push(node);
      isPushed = true;
    }
    else {
      this.modifiedTreeData.forEach(data => {
        if (node.id == data.id) {
          isPushed = true;
        }
      })
    }
    if (!isPushed) {
      this.modifiedTreeData.push(node);
    }
  }

  getCategorizationFormData(recCategorizationData) {
    //Get Categorization data from local Storage 
    // let dataCategorization = this.localStorageService.get(this.ideaId + '_CategorizationData');

    if (recCategorizationData != null) {
      this.showform = true;
      //this.hasUnsavedChanges = true;
      this.fillCategorizationData(recCategorizationData);
    } else {
      //Bind all Categorizatio Data by IdeaId 
      this.bindCategorizationByIdeadId();
    }
  }

  //Fill Categorization form data
  fillCategorizationData(objCategorization) {

    this.checkIsPLCrequiredChange(objCategorization);
    // Set the Currency Id to validate
    this.finalCurrencyIdToSend = objCategorization.CurrencyId;
    //Bind Form Data
    this.categorizationForm.patchValue({
      CategorizationId: objCategorization.CategorizationId,
      IdeaId: objCategorization.IdeaId,
      MeasureTypeId: objCategorization.MeasureTypeId,
      MeasureCategoryId: objCategorization.MeasureCategoryId,
      //for old dropdown
      //CurrencyId: objCategorization.CurrencyId == 0 ? "" : objCategorization.CurrencyId,
      CurrencyId: this.masterCurrencyData.find(x => x.CurrencyId == objCategorization.CurrencyId).CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyId == objCategorization.CurrencyId).Currency,
      StartDate: new Date(objCategorization.StartDate),
      CostImpactId: objCategorization.CostImpactId,
      MeasureStatusId: objCategorization.MeasureStatusId,
      IsPlcrequired: objCategorization.IsPLCRequired ? 1 : 2,
    });
    //checkStartDateValidation;
    let UIDate = new Date(this.categorizationForm.value.StartDate);
    let StartDate = new Date(Date.UTC(UIDate.getFullYear(), UIDate.getMonth(), UIDate.getDate())).toISOString();
    let startyear = UIDate.getFullYear();
    if(startyear == 2999)
    {
      this.openAlertDialog([{ [messages.startDatePopUpMessage]: serverMessage.warning }]);
    }

  }
  // Get all Categorizatio data
  bindCategorizationByIdeadId() {
    if (this.ideaId == null) {
      this.ideaId = 0;
    }
    if (this.ideaId != null) {
      // && parseInt(this.ideaId) > 0) {
      this.catService.getCategorizationDataById(parseInt(this.ideaId)).subscribe((data: any) => {
        let errorMsgeFromBack = data.Data ? data.Data.Message : ""
        if (data.StatusCode == 200) {
          let responseData;
          responseData = data;
          this.categorizationData = data.Data.Categorization;
          if (this.categorizationData != null || this.categorizationData != undefined) {
            if (this.categorizationData.IsPLCRequired === true) {
              this.checkPlcSummaryData();
            }
            else if (this.categorizationData.IsPLCRequired === false) {
              this.checkNonPlcSummaryData();
            }
          }
          this.showform = true;
          this.hasUnsavedChanges = false;


          if (responseData.Data.Categorization != null) {

            this.fillCategorizationData(responseData.Data.Categorization);
          } else {
            //Bind ideaForm Data
            this.ideaService.getData(this.ideaId).subscribe((data) => {
              let responseData;
              responseData = data;

              if (responseData.Data != null) {
                this.categorizationForm.patchValue({
                  MeasureStatusId: responseData.Data.MeasureStatusId,
                })
              }
            });
            // Set the Currency dropdown to auto filled for AT and LD
            if (this.businessAreaId != 0) {
              if (this.businessAreaId == 1) {
                this.categorizationForm.patchValue({
                  //for old dropdown
                  //CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyId,
                  CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").Currency,
                })
                //for old dropdown
                //this.onOptionsSelected(this.categorizationForm.get('CurrencyId').value);
                // this.onSearchChange(this.categorizationForm.get('CurrencyId').value);
                this.finalCurrencyIdToSend = this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyId;
                this.catService.changeMsg(this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "eur").CurrencyCode.trim());
              }
              else if (this.businessAreaId == 3) {
                this.categorizationForm.patchValue({
                  //for old dropdown
                  //CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyId,
                  CurrencyId: this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyCode + ' - ' + this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").Currency,
                })
                //for old dropdown
                //this.onOptionsSelected(this.categorizationForm.get('CurrencyId').value);
                // this.onSearchChange(this.categorizationForm.get('CurrencyId').value);
                this.finalCurrencyIdToSend = this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyId;
                this.catService.changeMsg(this.masterCurrencyData.find(x => x.CurrencyCode.trim().toLowerCase() == "usd").CurrencyCode.trim());
              }
              else {
                this.categorizationForm.patchValue({
                  CurrencyId: "",
                })
              }
            }
            else {
              this.categorizationForm.patchValue({
                CurrencyId: "",
              })
            }

          }
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer);

        }
      }, (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      });
    }
  }

  checkIsPLCrequiredChange(objCategorization) {
    if (objCategorization.CategorizationId != 0) {
      this.originalStatus = objCategorization.MeasureStatusId;
      this.orginalStartDate = objCategorization.StartDate;
      this.isPLCrequiredFromDB = objCategorization.IsPLCRequired;
    }
  }

  //check plcsummary data
  checkPlcSummaryData() {
    this.ideaId = this.localStorageService.get('ideaId');
    this.plcSummaryService.getPLCSummaryOPWProductivity(this.ideaId).subscribe(
      (data: any) => {
        let getOPWResponse = data;
        let successCode = getOPWResponse.StatusCode;
        if (successCode != 200) {
          let errorMessage = serverMessage.serverErrorMessage;
          let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
          this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
          return false;
        }
        else if (successCode == 200) {
          this.sharedService.getIdeaIdAndtitle();
          this.allopwdata = getOPWResponse.Data;
          if (getOPWResponse.Data[0].MonthColumns == null && getOPWResponse.Data[0].MonthColumns == "") {
            let errorMessage = serverMessage.serverErrorMessage;
            let valueAlert = { [errorMessage]: serverMessage.serverErrorHeader };
            this.dialog.open(AlertDialogComponent, { data: [valueAlert] });
            return false;
          }
        }
      }, (error) => {
      })
  }

  checkNonPlcSummaryData() {
    this.ideaId = this.localStorageService.get('ideaId');
    this.NonplcsummaryService.getOPWProductivity(this.ideaId).subscribe(
      (data: any) => {
        let errorMsgeFromBack = data.Data.Message;
        if (data.StatusCode == 200) {
          this.sharedService.getIdeaIdAndtitle();
          let responseData;
          responseData = data;
          this.nonPlcData = responseData.Data;
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        // this.sharedService.getErrorMessage(error);
        //Show errorCode and errorMessage in the UI
        //this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  // checks for change in plc assesment dropdown;
  checkPlcAssessmentChange() {
    let message = [];
    if (this.isPLCrequiredFromDB != undefined) {
      if (this.dataBind.IsPLCRequired != this.isPLCrequiredFromDB && !this.userConsent) {
        if (this.dataBind.IsPLCRequired) {
          message.push(
            { [messages.categorization.plcChangeToRequiredMessage]: serverMessage.warning });
        }
        else {
          message.push({ [messages.categorization.plcChangeToNotRequiredMessage]: serverMessage.warning })
        }
        this.openAlertDialog(message);
      }
      else {
        this.userConsent = true;
      }
    }
    else {
      this.userConsent = true;
    }
  }

  // check apply for valuation;
  checkPlcStatusChange(event) {
    this.recentChangedStatus = this.categorizationForm.get("MeasureStatusId").value;
    let value = event.target.value;
    if (value == 1 || value == 2) {
      this.categorizationForm.controls['MeasureStatusId'].setValue(this.status.Data[1].MeasureStatusId);
    }
    this.openAlertDialog([{ [messages.categorization.plcAssesmentChange]: serverMessage.warning }]);
  }

  categorizatinSectionData() {
    //categorizationSectionData
    //Set Start Date As UTC date
    let dbDate = new Date(this.categorizationForm.value.StartDate);
    let StartDate = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
    console.log(StartDate);
    this.dataBind = {
      CategorizationId: parseInt(this.categorizationForm.value.CategorizationId),
      IdeaId: parseInt(this.ideaId),
      MeasureTypeId: parseInt(this.categorizationForm.value.MeasureTypeId),
      MeasureCategoryId: parseInt(this.categorizationForm.value.MeasureCategoryId),
      CurrencyId: parseInt(this.finalCurrencyIdToSend),
      StartDate: StartDate,
      CostImpactId: parseInt(this.categorizationForm.value.CostImpactId),
      MeasureStatusId: parseInt(this.categorizationForm.value.MeasureStatusId),
      IsPLCRequired: parseInt(this.categorizationForm.value.IsPlcrequired) == 1 ? true : false,
      IsActive: true,
      CreatedBy: this.userInOrg.UserId
    }
    return this.dataBind;
  }

  // this will bind data and send it to submit function;
  dataSend(gridData, productTree) {
    this.gridDataSend = [];
    this.treeDataSend = [];
    this.categorizationDataSend = [];
    this.allDataSend = {};
    this.orgapi.forEachNode(node => {
      if (node.data.ImpactedOrgId == 0) {
        this.hasUnsavedChanges = true;
        gridData.push(node.data);
      }
    })

    // binding grid data;
    for (let i of gridData) {
      let modifierId = null
      if (i.ImpactedOrgId != 0) {
        modifierId = this.ideaSubmitterId;
      }
      this.gridDataSend.push({
        ImpactedOrgId: i.ImpactedOrgId,
        IdeaId: this.ideaId,
        OrgUnitId: i.OrgUnit.orgUnitId,
        User: i.User,
        ContactEmail: i.Email,
        ImpactId: i.Impact.impactId,
        IsActive: i.IsActive,
        CreatedBy: i.CreatedBy,
        CreatedOn: "2021-03-24",
        ModifiedBy: modifierId,
        ModifiedOn: null
      })
    }

    // Local Storag :-setting isPlcseledtec true/false;
    if (productTree.length != 0) {
      this.localStorageService.set('isPLCseclected', true);
    } else {
      this.localStorageService.set('isPLCseclected', false);
    }
    // Binding product tree data;
    for (let j of productTree) {
      let impactprodId = 0;
      for (let i of this.ImProdIdSelData) {
        if (i.id == j.ProductId) {
          impactprodId = i.ImpactedProductId;
          break;
        }
      }
      this.treeDataSend.push({
        ImpactedProductId: impactprodId,
        IdeaId: this.ideaId,
        ProductId: j.ProductId,
        IsActive: j.IsActive,
        CreatedBy: this.ideaSubmitterId,
        CreatedOn: "2021-03-24",
        ModifiedBy: j.ModifiedBy,
        ModifiedOn: j.ModifiedOn,
      })
    }

    // Remarks changes;
    let Remark: any;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remark = {
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: MSolutionType.Impact,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.Impact
        }
      } else {
        Remark = null;
      }
    } else {
      Remark = null;
    }

    // Categorization changes
    //let dbDate = new Date(this.categorizationForm.value.StartDate);
    //let StartDate = new Date(Date.UTC(dbDate.getFullYear(), dbDate.getMonth(), dbDate.getDate())).toISOString();
    // this.dataBind = {
    //   CategorizationId: parseInt(this.categorizationForm.value.CategorizationId),
    //   IdeaId: parseInt(this.ideaId),
    //   MeasureTypeId: parseInt(this.categorizationForm.value.MeasureTypeId),
    //   MeasureCategoryId: parseInt(this.categorizationForm.value.MeasureCategoryId),
    //   CurrencyId: parseInt(this.finalCurrencyIdToSend),
    //   StartDate: StartDate,
    //   CostImpactId: parseInt(this.categorizationForm.value.CostImpactId),
    //   MeasureStatusId: parseInt(this.categorizationForm.value.MeasureStatusId),
    //   IsPLCRequired: parseInt(this.categorizationForm.value.IsPlcrequired) == 1 ? true : false
    // }
    this.allDataSend = { ImpactedOrgs: this.gridDataSend, ImpactedProducts: this.treeDataSend, Remark: Remark, IdeaId: this.ideaId, UserId: this.ideaSubmitterId, BusinessAreaId: this.businessAreaId, BusinessLineId: this.businessLineId, Categorization: this.dataBind }
    return this.allDataSend;
  }

  // checks for validation of status and startdate;
  checkStatusValidation() {
    let statusErrorMessage = [];

    let selectedDateUTC = this.categorizationForm.get("StartDate").value;
    if (selectedDateUTC === "") {
      return statusErrorMessage;
    }
    selectedDateUTC = selectedDateUTC.toUTCString()
    selectedDateUTC = moment(selectedDateUTC).format('MM/DD/YYYY');
    selectedDateUTC = new Date(selectedDateUTC);

    let todayDate: Date = new Date();
    let todayDateUTC: any;
    todayDateUTC = todayDate.toUTCString();
    todayDateUTC = moment(todayDateUTC).format('MM/DD/YYYY');
    todayDateUTC = new Date(todayDateUTC);

    // if (selectedDateUTC > todayDateUTC) {
    //   let status = this.categorizationForm.get("MeasureStatusId").value;
    //   if (status == 5) {
    //     statusErrorMessage.push({ Implementation: messages.categorization.startDateBig });
    //   }
    // } else
    if (selectedDateUTC < todayDateUTC) {
      let status = this.categorizationForm.get("MeasureStatusId").value;
      if (status == this.status.Data[0].MeasureStatusId || status == this.status.Data[1].MeasureStatusId || status == this.status.Data[2].MeasureStatusId || status == this.status.Data[3].MeasureStatusId) {
        let error = messages.categorization.todayDateBig;
        statusErrorMessage.push({ Assesment: error }, { Ideation: error }, { Prioritzation: error }, { Valuation: error })
      }
    } else if (selectedDateUTC.toString() === todayDateUTC.toString()) {
      let status = this.categorizationForm.get("MeasureStatusId").value;
      if (status == this.status.Data[0].MeasureStatusId || status == this.status.Data[1].MeasureStatusId || status == this.status.Data[2].MeasureStatusId || status == this.status.Data[3].MeasureStatusId) {
        let error = messages.categorization.equalDate;
        statusErrorMessage.push({ Assesment: error }, { Ideation: error }, { Prioritzation: error }, { Valuation: error })
      }
    }
    return statusErrorMessage;
  }

  //check for currency dropdown
  checkCurrencyChange() {
    let errorMessage = [];
    if (this.finalCurrencyIdToSend == undefined || this.finalCurrencyIdToSend == null) {
      let error = serverMessage.currencyError;
      errorMessage.push(error);
    }
    return errorMessage;
  }

  checkActualsAndStartdate() {
    let errorMessage = [];
    let startDateFromDb = this.categorizationData.StartDate;
    if (this.categorizationData != null) {
      let ideaStateIdFromUI = this.categorizationForm.value.MeasureStatusId;
      let dbDate = new Date(this.categorizationForm.value.StartDate);
      let hasActualData = false;
      let masterStateid = (this.status.Data).filter((obj) => obj.MeasureStatusTitle.toLowerCase().startsWith('implementation'));
      let masterStatusId = masterStateid[0].MeasureStatusId;
      if (parseInt(this.categorizationForm.value.IsPlcrequired) == 1) {
        // call plc method
        if (dbDate.getTime() > new Date(startDateFromDb).getTime() && ideaStateIdFromUI == masterStatusId) {
          let filtered = this.allopwdata.filter((val) => val.SolutionId == 6)
          for (let i = 1; i <= 12; i++) {
            let month = 'Month' + i;
            if (filtered[0][month] != undefined) {
              if (filtered[0][month] != null) {
                if (filtered[0][month] != 0) {
                  hasActualData = true;
                }
              }
            }
          }
        }
      }
      else if (parseInt(this.categorizationForm.value.IsPlcrequired) == 2) {
        //call non plc method

        if (dbDate.getTime() > new Date(startDateFromDb).getTime() && ideaStateIdFromUI == masterStatusId) {
          let filteredData = this.nonPlcData.filter((val) => val.SolutionId == 6)
          for (let i = 1; i <= 12; i++) {
            let month = 'Month' + i;
            if (filteredData[0][month] != undefined) {
              if (filteredData[0][month] != null) {
                if (filteredData[0][month] != 0) {
                  hasActualData = true;
                }
              }
            }
          }
          //}
        }
      }
      if (hasActualData) {
        //error message
        let error = serverMessage.dateError1;
        errorMessage.push(error)
      }
    }
    return errorMessage;
  }

  checkStartDateValidation()
  {
    let startDateErrorMessage = [];
    let UIDate = new Date(this.categorizationForm.value.StartDate);
    let StartDate = new Date(Date.UTC(UIDate.getFullYear(), UIDate.getMonth(), UIDate.getDate())).toISOString();
    let startyear = UIDate.getFullYear();
    console.log(StartDate);
    console.log(startyear);
    if(startyear == 2999)
    {
      let error = serverMessage.startDateChangeMessage;
      startDateErrorMessage.push(error);
    }
    return startDateErrorMessage;
    
  }

  // this will collect all the data of tree and grid and calls other function for validaton and binding of data; 
  submit(event?: any) {
    // this will close all the editing cell before saving;
    let startDateError;
    this.submitted = true;
    this.submitted = true;
    let response = null;
    this.orgapi.stopEditing(true);
    if (this.ideaId == null || this.ideaId == 0) {
      if (event) {
        this.openAlertDialog([{ [messages.ideaData]: serverMessage.warning }]);
      }
      return true;
    }
    for (let i in this.errorObject) {
      if (!this.errorObject[i]) {
        this.formerror.push({ [i]: this.headerMessage })
      }
    }


    //let startDateValidation = this.checkStartDateValidation();
    let statusError = this.checkStatusValidation();
    let currencyError = this.checkCurrencyChange();
    if (this.categorizationData != null || this.categorizationData != undefined) {
      startDateError = this.checkActualsAndStartdate();
    }
    if (startDateError != undefined) {
      if (startDateError.length != 0) {
        for (let i of startDateError) {
          this.formerror.push({ [i]: serverMessage.warning });
        }
      }

    }

    if (currencyError.length != 0) {
      for (let i of currencyError) {
        this.formerror.push({ [i]: this.headerMessage });
      }
    }

    if (statusError.length != 0) {
      for (let i of statusError) {
        this.formerror.push(i);
      }
    }

    // if(startDateValidation.length !=0)
    // {
    //   for (let i of startDateValidation) {
    //     this.formerror.push(i);
    //   }
    // }
    let checkValidation = this.validateRow(2);
    let finalGridData = this.AllOrgData();
    // Remarks changes;
    let remarkChange = this.checkRemarkChange();

    if ((this.alertErrorBox.length == 0 && this.errorMessage.length == 0 && finalGridData && checkValidation && this.hasUnsavedChanges) || remarkChange) {
      let result: any = [];
      let finaltreeData = [];
      finaltreeData = this.modifiedTreeData.map(data => {
        return {
          ProductId: data.id,
          IsActive: data.IsActive,
          CreatedBy: data.CreatedBy,
          CreatedOn: data.CreatedOn,
          ModifiedBy: data.ModifiedBy,
          ModifiedOn: data.ModifiedOn
        }
      })
      if (this.formerror.length) {
        this.submitted = false;
        this.openDialog(this.formerror)
      }
      else {
        this.categorizatinSectionData();
        let postData = this.dataSend(finalGridData, finaltreeData);
        this.checkPlcAssessmentChange();
        if (!this.userConsent) {
          this.submitted = false;
          return;
        }
        if (this.hasUnsavedChanges) {
          //if (this.userConsent && this.hasUnsavedChanges) {
          response = this.impactService.postData(postData).toPromise().then((response) => {
            this.submitResponse = response;
            let successCode = this.submitResponse.StatusCode;
            let errorCode = this.submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = this.submitResponse.Data.Message;

            if (successCode == 200) {
              if (errorCode == 0) {
                this.hasUnsavedChanges = false;
                this.toast.notify(serverMessage.dataSaveMessage, "success");
                if (event != undefined) {
                  //Remove dataCategorization
                  this.sharedService.onRemoveCategorizationData();
                  this.router.navigate(['/manageIdea']);
                }
                if (event == 'save&exit') {
                  this.router.navigate(['/manageIdea']);
                }
                if (event == "next") {
                  this.router.navigate(['/categorization']);
                }
                return true;
              }
              else {
                //Show errorCode and errorMessage in the UI;
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer);
                this.submitted = false;
                return false;
              }
            }
            else if (errorCode == EnumErrorCodes.ImpactCategorizationValidateError) {

              this.submitted = false;
              this.openAlertDialog([{ [errorMsgeFromBack]: serverMessage.warning }]);
              return false;
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
              this.submitted = false;
              return false;
            }
          }, (error) => {
            this.submitted = false;
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
            return false;
          })
        }
        else {
          this.sharedService.onRemoveCategorizationData();
          this.router.navigate(['/manageIdea']);
        }
      }

    } else if (this.errorMessage.length != 0) {
      this.submitted = false;
      this.openAlertDialog([{ [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader }]);
    } else if (this.alertErrorBox.length != 0) {
      this.submitted = false;
      this.openAlertDialog(this.alertErrorBox);
    }
    else if (!this.hasUnsavedChanges) {
      this.router.navigate(['/manageIdea']);
    }
    return response;
  }

  // get called when user click on cancel and exit;
  onClickCancel() {
    //Remove dataCategorization
    this.sharedService.onRemoveCategorizationData();
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea']);
  }

  // move user to back page;
  onClickBack() {
    this.menuService.onClickNextAndBack("back");
  }

  // moves user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next");
  }

  /* To auto expand the selected nodes coming from database */
  expand(data: any, uniqueId: number): any {
    data.forEach((node: any) => {
      if (node.id === uniqueId) {
        this.treeControl.expand(node);
        if (node.parent) {
          this.expand(this.dataSource.data, node.parent.id);
        }
      }
      else if (node.children?.find((c: any) => c.children)) {
        this.expand(node.children, uniqueId);
      }
    })
  }

  /* To fetch the selected nodes from database and to autoselect and autoexpand them*/
  getSelectedNode(data: any) {
    this.selectedNode = this.selectedDataProduct;
    this.selectedNode.forEach((node: any) => {
      this.expand(data, node.id);
      data.forEach((d: any) => {
        if (d.id == node.id) {
          d.selected = true;
          this.dbSelectedNodeIds.push(node.id);
          this.todoItemSelectionToggle(true, d);
        }
      })
    })
  }

  /* To populate flatdata array with all the node present in the tree data 
  so that the flatdata can be passed to the getSelectedNode for easy searching of required node 
  for expand and toggle purposes*/
  getFlatData(dataSource: any) {
    dataSource.forEach((node: any) => {
      if (!node.children) {
        this.flatdata.push(node);
      }
      else if (node.children) {
        this.flatdata.push(node);
        this.getFlatData(node.children);
      }
    })
  }

  // check for any changes in the remark field;
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
  bindCategorizationDropdown() {
    this.catService.getMeasureType(this.businessAreaId, this.businessLineId).subscribe((measureType: any) => {
      let errorMsgeFromBack = measureType.Data.Message;
      if (measureType.StatusCode == 200) {
        this.measureType = measureType;
        this.getMeasureCategory();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {

      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);

    });
  }

  onSearchChange(searchValue: string) {
    this.finalCurrencyIdToSend = null;
    this.filterValue = searchValue.trim();
    if (this.filterValue == "") {
      this.filtered = this.masterCurrencyData;
    }
    else if (this.filterValue.includes('-')) {
      this.filtered = this.masterCurrencyData.filter((val) => val.CurrencyCode.toLowerCase().includes(this.filterValue.toLowerCase().split('-')[0].trim()));
    }
    else {
      this.filtered = this.masterCurrencyData.filter((value) => value.Currency.toLowerCase().includes(this.filterValue.toLocaleLowerCase()) || value.CurrencyCode.toLowerCase().includes(this.filterValue.toLocaleLowerCase()));
    }
  }

  focusOutFunction(event: any) {
    if (this.filtered.length == 0) {
      let errorContainer = [
        { [serverMessage.currencyError]: serverMessage.serverErrorHeader }
      ]
      this.openAlertDialog(errorContainer);
      this.categorizationForm.get('CurrencyId')?.reset();
      this.filtered = this.masterCurrencyData;
      return false;

    }
  }

  onSelCurrency($event: any, option) {
    if ($event.isUserInput) {
      let currencyCode = this.masterCurrencyData.find(x => x.CurrencyId == option).CurrencyCode;
      this.finalCurrencyIdToSend = option;
      this.catService.changeMsg(currencyCode.trim());
    }

  }
  getMeasureCategory() {
    this.catService.getMeasureCategory(this.businessAreaId, this.businessLineId).subscribe((measureCategory: any) => {
      let errorMsgeFromBack = measureCategory.Data.Message;
      if (measureCategory.StatusCode == 200) {
        this.measureCategory = measureCategory;
        this.getMasterCurrency();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
  getMasterCurrency() {
    this.fxservice.GetMasterCurrency().subscribe((currencyData: any) => {
      let errorMsgeFromBack = currencyData.Data.Message;
      if (currencyData.StatusCode == 200) {
        this.masterCurrencyData = currencyData.Data;
        this.masterCurrencyData.sort((a, b) => a.CurrencyId > b.CurrencyId ? 1 : -1);
        this.filtered = this.masterCurrencyData;
        this.getCostImpacts();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }

  getCostImpacts() {
    this.catService.getCostImpacts(this.businessAreaId, this.businessLineId).subscribe((mcostImpact: any) => {
      let errorMsgeFromBack = mcostImpact.Data.Message;
      if (mcostImpact.StatusCode == 200) {
        this.costImpact = mcostImpact;
        this.getMeasureStatus();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
  getMeasureStatus() {
    this.catService.getMeasureStatus(this.businessAreaId, this.businessLineId).subscribe((measureStatus: any) => {
      let errorMsgeFromBack = measureStatus.Data.Message;
      if (measureStatus.StatusCode == 200) {
        this.status = measureStatus;
        //   this.getMCommoditiyHierarchy();
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }

    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    });
  }
}

// used by dropDown-OrgUnit in Imapct Grid and it return Name which is to be shown in dropdown;
const mOrgUnitCellRenderer = (params) => params.value.orgUnitTitle;
// used by dropDown-Impact in Imapct Grid and it return Name which is to be shown in dropdown;
const mImpactCellRenderer = (params) => params.value.impactTitle;
