import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { UserInfo } from 'src/app/models/user-info';
import { PeopleDropDownComponent } from 'src/app/people-drop-down/people-drop-down.component';
import { messages } from 'src/app/popUpMessages/messages';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { UserReplacementService } from 'src/app/services/user-replacement.service';
import { DeleteiconComponent } from '../../deleteIconNormalGrid/deleteicon.component';
// Checkbox Component;
import { CheckboxComponent } from '../checkbox/checkbox.component';
@Component({
  selector: 'app-user-replacement',
  templateUrl: './user-replacement.component.html',
  styleUrls: ['./user-replacement.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserReplacementComponent implements OnInit {

  public rowData: any;
  public defaultColDef: any;
  public columnDefs: any;
  public gridApi: any;
  public gridColumnApi: any;
  public domLayout: any;
  public rowClassRules: any;
  public rowHeight: any;
  public headerHeight: any;
  public getRowNodeId: any;

  public alertErrorBox = [];
  public modifiedUserRows = [];
  public errorMessage = []
  public roleMasterData = [];
  //for role dropdown data that are binded
  public roleDropDownData = [];
  //to set the default role as normal user when new row is added
  public defaultRole: any;
  public submitResponse: any;
  public loggedInUser: UserInfo;
  public finalError = [];
  public data: any;
  public frameworkComponents: any;
  public submitted = false;
  public showGrid = false;
  public postData: any;

  // variable associated with managedata component functions;
  public adminSubscription: any;
  public usrReplmntChanges: boolean = false;
  public onLegalHoldObj = ["Yes", "No"];
  public changedLegalHold: string = messages.admin.userReplacement.changeLegarHold;
  @Output() unSavedChanges = new EventEmitter<any>();

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";
  hideExport: boolean = true;

  constructor(
    private userManagmntSvc: UserInfoService,
    private userSvc: UserReplacementService,
    private sharedService: SharedServiceService,
    public dialog: MatDialog,
    private toast: ToasterService
  ) {
    this.frameworkComponents = { checkboxRenderer: CheckboxComponent, userDropdownEditor: PeopleDropDownComponent };
  }

  ngOnInit(): void {
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loggedInUser = user;

        this.roleBusinessArea = user.BusinessArea;
        this.roleAllBusinessLine = user.BusinessLine;
        this.roleBusinessArea = this.roleBusinessArea.filter((v,i,a)=>a.findIndex(v2=>(v2.BusinessAreaId===v.BusinessAreaId))===i);
        
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
          this.hideExport = false;
        }
        else{this.hideExport = true;}
      }
    )

    // subscribe to manage data eventemitter;
    this.adminSubscription = this.sharedService.userReplacement.subscribe(() => {
      this.submit();
    })

    this.sharedService.userManagementDeletedRows.subscribe(
      res => {
        if (res.UserId != 0) {
          //  emits whenever saved data is deleted;
          let valToEmit = {
            unsavedChanges: true,
            saveButtonClick: false
          }
          this.unSavedChanges.emit(valToEmit);

          let isPushed = false;
          if (this.modifiedUserRows.length == 0) {
            this.modifiedUserRows.push(res)
            isPushed = true
          }
          else {
            this.modifiedUserRows.forEach(row => {
              if (row.UserId == res.UserId) {
                isPushed = true
              }
            });
          }
          if (!isPushed) {
            this.modifiedUserRows.push(res)
          }
        }
      }
    )
    this.getRoleMasterData();
    this.sharedService.getIdeaIdAndtitle();
    
  }

  updateBusinessLine() {
    this.hideExport = true;
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
    this.getUserList();
  }

  updateUserList() {
    if(this.selectBusinessLine == "")
   {
    this.hideExport = true;
   }
   else
   {
    this.hideExport = false;
   }
    this.getUserList();
  }

  // Fetch role master data to get the role title and role id to bind the Role field
  getRoleMasterData() {
    this.userSvc.getRoleMasterData().subscribe((res: any) => {

      let errorMsgeFromBack = res.Data.Message;
      if (res.StatusCode == 200) {
        if (res.Data) {
          this.roleMasterData = res.Data;
          // For binding role dropdown
          for (let role of this.roleMasterData) {
            let obj = {};
            obj["name"] = role.RoleTitle;
            obj["id"] = role.RoleId;
            this.roleDropDownData.push(obj);

            // To set the role to normal user when a new row is added
            if (role.RoleId == 3) {
              this.defaultRole = { name: role.RoleTitle, id: role.RoleId }
            }
          }
          this.getUserList();
        }
      }
      else {
        //Show errorCode and errorMessage in the UI;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    },
      (error) => {
        //Show errorCode and errorMessage in the UI
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  //Fetch user list to be displayed
  getUserList() {
    this.showGrid=false
    this.userSvc.getUserList(Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe((res: any) => {
      if (res == null) {
        return
      }
      else {
        this.rowData = res.filter(x => x.IsActive == 0);
        for (let data of this.rowData) {
          for (let role of this.roleDropDownData) {
            if (data.RoleId == role.id) {
              data.Role = role;
            }
          }
          if (data.IsActive) {
            delete data["IsActive"]
            data["IsActive"] = "Yes"
          }
          else if (!data.IsActive) {
            delete data["IsActive"]
            data["IsActive"] = "No"
          }
          if (data.OnLegalHold) {
            delete data["OnLegalHold"]
            data["OnLegalHold"] = "Yes"
          }
          else if (!data.OnLegalHold) {
            delete data["OnLegalHold"]
            data["OnLegalHold"] = "No"
          }
          data["userRemovalPage"] = true;
        }
       
        this.loadGrid()
      }
    })
  }

  //loads the grid structure after user list is fetched
  loadGrid() {
    this.columnDefs = [
      {
        headerName: "User Id",
        field: "UserId",
        tooltipField: "UserId",
        headerTooltip: "User Id",
        width: 75,
      },
      {
        headerName: "GID",
        field: "GID",
        tooltipField: "GID",
        headerTooltip: "GID",
        
        valueGetter: convUpperCase
      },
      {
        headerName: "Email",
        field: "Email",
        headerTooltip: "Email",
        tooltipField: "Email",
        cellEditor: "userDropdownEditor",
      },
      {
        headerName: "First Name",
        field: "FirstName",
        headerTooltip: "First Name",
        editable: false,
        tooltipField: "FirstName",
      },
      {
        headerName: "Last Name",
        field: "LastName",
        headerTooltip: "Last Name",
        editable: false,
        tooltipField: "LastName",
      },
      {
        headerName: "Full Name",
        field: "FullName",
        headerTooltip: "Full Name",
        editable: false,
        tooltipField: "FullName",
      },
      {
        headerName: "Org Code",
        headerTooltip: "Org Code",
        field: "OrgCode",
        editable: false,
        tooltipField: "OrgCode",
      },
      {
        headerName: "Business Area",
        headerTooltip: "Business Area",
        field: "BusinessAreaName",
        editable: false,
        tooltipField: "BusinessArea",
      },
      {
        headerName: "Business Line",
        headerTooltip: "Business Line",
        field: "BusinessLineName",
        editable: false,
        tooltipField: "BusinessLine",
      },
      {
        headerName: "Role",
        field: "Role",
        headerTooltip: "Role",
        tooltipField: "Role.name",
        cellEditor: 'agRichSelectCellEditor',
        filter: false,
        cellRenderer: roleName,
        keyCreator: function (Role) {
          return Role.name;
        },
        cellEditorParams: {
          cellRenderer: roleName,
          values: this.roleDropDownData
        }
      },
      {
        headerName: "Role",
        field: "RoleTitle",
        headerTooltip: "Role",
        tooltipField: "Role",
        filter: false,
        hide: true,
      },
      {
        // headerName: "Legal Hold",
        // field: "OnLegalHold",
        // tooltipField: "OnLegalHold",
        // headerTooltip: "Legal Hold",
        // width: 60,

        headerName: "Legal Hold",
        field: "OnLegalHold",
        headerTooltip: "Legal Hold",
        tooltipField: "params",
        cellEditor: 'agRichSelectCellEditor',
        filter: false,
        editable: function (params) {        
          if (params.node.data.OnLegalHold == "No") {
            return false;
          }
          else {
            return true;
          }
        },
        singleClickEdit: true,
        cellRenderer: onLegalHoldVal,
        keyCreator: function (params) {
          return params
        },
        cellEditorParams: {
          cellRenderer: onLegalHoldVal,
          values: this.onLegalHoldObj
        },
        width: 75,
      },
      {
        headerName: "Is Active",
        field: "IsActive",
        tooltipField: "IsActive",
        headerTooltip: "Is Active",
        width: 80,
      },
      {
        editable: false,
        filter: false,
        headerTooltip: "Remove User",
        headerName: "Remove User",
        width: 95,
        cellRendererFramework: DeleteiconComponent,
      }
    ];

    this.defaultColDef = {
      editable: false,
      disable: true,
      filter: true,
      sortable: true,
      suppressMovable: true,
      singleClickEdit: true
    }
    this.getRowNodeId = function (data) {
      return data.UserId;
    };
    this.domLayout = 'autoHeight';
    this.showGrid=true
  }

  // stores all the details of the grid,-
  //  gets called as soon as soon as the grid loads up
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit()
    this.sharedService.userMangementObj.subscribe(api => {
      this.gridApi = api
    })
  }

  // This is get called whenevr the size of the grid changed;
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  // gets called whenever the value get changes in any cell;
  onCellValueChanged(params) {

    // checks if the OnLegalHold data is changed or not?
    // if changed from "Yes" to "No" it gives warning and saves it further-
    // on approval;
    if (params.oldValue == "Yes" && params.newValue == "No") {
      this.openWarningDialogBox(params.data);
    }
  }

  // gives warning and saves data whenever the on leagl hold field is change from "Yes" to "No";
  openWarningDialogBox(rowData) {

    const dialogRef = this.dialog.open(DialogComponent, { data: { message: this.changedLegalHold, okBtn: "Yes", cancelBtn: "No" } })
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        let rowNode = this.gridApi.getRowNode(rowData.UserId);
        rowNode.setDataValue('OnLegalHold', "Yes");

      } else if (result) {

        let data = {
          UserId: rowData.UserId,
          FirstName: rowData.FirstName,
          LastName: rowData.LastName,
          FullName: rowData.FullName,
          Email: rowData.Email,
          GID: rowData.GID.trim(),
          OrgCode: rowData.OrgCode,
          RoleId: rowData.Role.id,
          BusinessAreaId: Number(this.selectBusinessArea),
          BusinessLineId: Number(this.selectBusinessLine),
          IsActive: rowData.IsActive == "Yes" ? true : false,
          CreatedBy: rowData.CreatedBy,
          CreatedOn: rowData.CreatedOn,
          ModifiedBy: this.loggedInUser.UserId,
          ModifiedOn: null,
          OnLegalHold: rowData.OnLegalHold == "No" ? false : true
        }

        let postData = {
          GID: this.loggedInUser.GID,
          UserId: this.loggedInUser.UserId,
          UserDetails: [data]
        }

        let submitResponse: any;
        this.userManagmntSvc.insertUpdateUserInfo(postData).subscribe(
          (res: any) => {
            submitResponse = res;
            let successCode = submitResponse.StatusCode;
            let errorCode = submitResponse.Data.ErrorCode;
            let errorMsgeFromBack = submitResponse.Data.Message;
            if (successCode == 200) {
              if (errorCode == 0) {

                let dataSaveMessage = serverMessage.dataSaveMessage;
                this.toast.notify(dataSaveMessage, "success");

              }
              else {
                //Show errorCode and errorMessage in the UI
                let errorContainer = [
                  { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                  { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
                ]
                this.openAlertDialog(errorContainer);
              }
            }
            else {
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer);
            }
          },
          (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
          })
      }
    })
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  // get called on click of cancel button;
  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unSavedChanges.emit(valToEmit);
    this.getUserList();
  }

  dataToSend(modifiedUserRows) {
    let postData = {};
    modifiedUserRows = modifiedUserRows.map(user => {
      let modifierId = null
      if (user.UserId != 0) {
        modifierId = this.loggedInUser.UserId;
      }
      return {
        UserId: user.UserId,
        FirstName: user.FirstName,
        LastName: user.LastName,
        FullName: user.FullName,
        Email: user.Email,
        GID: user.GID.trim(),
        OrgCode: user.OrgCode,
        RoleId: user.Role.id,
        BusinessAreaId: Number(this.selectBusinessArea),
        BusinessLineId: Number(this.selectBusinessLine),
        IsActive: user.IsActive == 'Yes' ? 1 : 0,
        CreatedBy: user.CreatedBy,
        CreatedOn: user.CreatedOn,
        ModifiedBy: modifierId,
        ModifiedOn: null,
        OnLegalHold: user.OnLegalHold == 'Yes' ? 1 : 0
      }
    })
    postData = {
      // GID: this.loggedInUser.GID,
      UserId: this.loggedInUser.UserId,
      UserDetails: modifiedUserRows
    }
    return postData;
  }

  // save the added and modified user row
  submit(event?: any) {
    this.submitted = true;
    this.postData = this.dataToSend(this.modifiedUserRows);
    if (this.postData.UserDetails.length != 0) {
      this.userSvc.removeUser(this.postData).subscribe(
        (res: any) => {
          this.submitResponse = res;
          let successCode = this.submitResponse.StatusCode;
          let errorCode = this.submitResponse.Data.ErrorCode;
          let errorMsgeFromBack = this.submitResponse.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.submitted = false;
              this.modifiedUserRows = [];

              // emit data to manage data component;
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }
              this.unSavedChanges.emit(valToEmit);

              let dataSaveMessage = serverMessage.dataSaveMessage;
              this.toast.notify(dataSaveMessage, "success");
              this.getUserList()
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
            }
          }
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
        },
        (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.submitted = false;
          return false;
        })
    } else {
      this.submitted = false;
      this.modifiedUserRows = [];

      // emit data to manage data component;
      let valToEmit = {
        unsavedChanges: false,
        saveButtonClick: event == undefined ? false : true
      }
      this.unSavedChanges.emit(valToEmit);
    }
  }
  
  exportGridData() {
    //importing  details only if  isActive is Yes
    //this.SelectFilterOnIsActive();
    //importing  details only if  isActive is Yes
    this.gridApi.exportDataAsExcel({
      columnKeys: ['UserId', 'GID', 'Email', 'FirstName', 'LastName', 'FullName', 'OrgCode', 'BusinessAreaName', 'BusinessLineName', 'OnLegalHold', 'RoleTitle', 'IsActive'],
      //allColumns: true,
      protection: true,
      sheetName: "User Removal",
      fileName: "User Removal"
    });
    this.RemoveFilterOnIsActive();
    this.showGrid = true;
    this.toast.notify(serverMessage.excelExportMessage,"success");
    // importing  details only if  isActive is Yes
  }
  SelectFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive');
    instance.setModel({ values: ['Yes'] });
    this.gridApi.onFilterChanged();
  }

  RemoveFilterOnIsActive() {
    const instance = this.gridApi.getFilterInstance('IsActive');
    instance.setModel(null);
    this.gridApi.onFilterChanged();
  }

  // destroys all the instance of component on tab change in admin page;
  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe();
    }
  }
}

// returns the role dropdown name; 
const roleName = (params) => params.value.name;

// return dropdown value to the LegalHold Columns;
const onLegalHoldVal = (params) => params.value;

//Used to convert GID into uppercase;
function convUpperCase(params) {
  params.data.GID = params.data.GID ? params.data.GID.trim() : params.data.GID;
  params.data.GID = params.data.GID ? params.data.GID.toUpperCase() : params.data.GId
  return params.data.GID
}