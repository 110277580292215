<app-progress-spinner *ngIf="!showProduct"></app-progress-spinner>
<app-progress-spinner *ngIf="showSpinner"></app-progress-spinner>

<div class="form-row container">
  <div class="form-group col-md-3">
      <label for="businessArea" class="label">Business Area</label>
      <select [(ngModel)]="selectBusinessArea" (change)="updateBusinessLine()" id="businessArea" class="form-control" aria-label="businessArea" [disabled]="this.disableBusinessAreaDropdown == true">
          <option value="">Please select</option>
          <option *ngFor="let ba of roleBusinessArea" [value]="ba.BusinessAreaId">
            {{ba.BusinessAreaName}}
          </option>
      </select>
  </div>
  <div class="form-group col-md-3">
      <label for="businessLine" class="label">Business Line</label>
      <select [(ngModel)]="selectBusinessLine" (change)="updateProductList()" id="businessLine" class="form-control" aria-label="businessLine" [disabled]="this.disableBusinessLineDropdown == true">
          <option value="">Please select</option>
          <option *ngFor="let bl of roleBusinessLine" [value]="bl.BusinessLineId">
            {{bl.BusinessLineName}}
          </option>
      </select>
  </div>
</div>
<br>

<div *ngIf="showProduct" class="container">
  <div class="card" style="height: 100%">
    <div class="label-header" style="width: 100%; margin-top: 0px">
      <label class="label-headercolor"
        >Product Management (Add New Product</label
      >
      <button (click)="AddNewProduct()" title="Add">
        <img
          alt="plusIcon"
          src="../assets/icons/Plus.svg"
          width="25"
          height="25"
          class="icon-add"
        /></button
      ><label class="label-headercolor">)</label>
    </div>

    <div class="card-body" style="padding: 10px">
      <ag-grid-angular
        id="productGrid"
        style="width: 100%; height: 720px"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        (gridReady)="onGridReady($event)"
        (cellValueChanged)="onCellValueChanged($event)"
        (gridSizeChanged)="onGridSizeChanged($event)"
        [enableBrowserTooltips]="true"
        [frameworkComponents]="frameworkComponents"
      >
      </ag-grid-angular>
      <div class="row">
        <div class="offset-4 col-md-8">
          <div class="buttonStyle">
            <button
              class="button button--small"
              style="margin-right: 5px"
              type="submit"
              (click)="onClickCancel()"
            >
              Cancel
            </button>
            <button
              class="button button--small"
              type="submit"
              [disabled]="submitted"
              (click)="submit('true')"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
