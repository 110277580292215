import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { LocalStorageService } from '../../../../services/local-storage.service';
@Component({
  selector: 'app-gridlink',
  templateUrl: './gridlink.component.html'
  // styleUrls: ['./gridlink.component.css']
})
export class GridlinkComponent implements ICellRendererAngularComp {

  params: any;
  linkVisible: boolean = true;
  value:any;
  constructor(private route: Router, private localStorageService: LocalStorageService) { }
  refresh(): boolean {
    return null;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    
    if (this.params.data.SubProcessTitle == "Total process cost") {
      this.linkVisible = false
    }
    if (params.value != undefined && params.value.length > 85 ) {
  
    let value = params.value.substr(0, 85) + '...'
    this.value= value
  }
  else {
   this.value= params.value
  }
  }

  onClick() {
    // this will check whether is params is coming from "ManageIdeaPage";
    if (this.params.data.IdeaId) {
      // assigning the Clicked Idea ID for IdeaForm Page;
      this.localStorageService.set('ideaId', this.params.data.IdeaId);
      
      this.route.navigate(['/idea']);
    }else{
      this.route.navigate(["plc-processcost/subdetails", this.params.data.SubProcessID,  this.params.data.SolutionId]);
    }
  }
}
