import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { MSolutionType } from 'src/app/enum/MSolutionType';
import { Dirty } from 'src/app/interface/dirty-interface';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MenuService } from 'src/app/services/menu.service';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DeleteiconComponent } from '../../../pages/deleteIconNormalGrid/deleteicon.component';
import { DirectCostService } from '../../../services/direct-cost.service';
import { DoublingEditorComponent } from '../../non-plc/costsavings/doubling-editor.component';
import { messages } from '../../../popUpMessages/messages';
import { serverMessage } from '../../../popUpMessages/serverMessage';
import { EnumRemarkType } from 'src/app/enum/EnumRemarkType';
import { IdeaformService } from 'src/app/services/ideaform.service';
import { UserInfoService } from 'src/app/services/user-info.service';
import { CategorizationService } from 'src/app/services/categorization.service';
//Defined Global letiables
let countryCode;
@Component({
  selector: 'app-directcost',
  templateUrl: './directcost.component.html',
  styleUrls: ['./directcost.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DirectcostComponent implements OnInit, Dirty {
  // variable used to store Grid definition and structure;
  public refScenerioColumndef: any;
  public refScenerioRowdata: any;
  public targetRowdata: any;
  public targetColumnDef: any;
  public defaultProperties: any;
  public gridApi: any;
  public headerHeightRef: any;
  public gridColApi: any;
  public rowStylerRef: any;
  public pinnedBottomRowData: any;
  public domLayout: any;
  public rowHeight: any;
  public total: any;
  public hasUnsavedChanges: boolean = false;
  // hover;
  public tooltipShowDelay;
  public frameworkComponents;
  // fot total row color and styling;
  public rowStyle: any;
  public rowClassRulesRef: any;
  public rowClassRulesTar: any;
  public getRowHeightRef: any;
  public getRowHeightTar: any;
  // for IdeaId and SolutionId
  public ideaId: any;
  public solutionIdReference: any;
  public solutionIdTarget: any;
  public UserId: any;
  // for storing Data coming from API Call;
  public referenceDataReceived: any;
  public targetDataReceived: any;
  // for last total row data coming from API Call;
  public referenceOnlyTotalRowData: any;
  public targetOnlyTotalRowData: any;
  // this will store id value of Data row and Sum Row which will distinguish bwn new or old rows of the Grid;
  public referenceDirectCostSumId: any;
  public referenceDirectCostId: any;
  public referenceComponentId: any;
  public targetDirectCostSumId: any;
  public targetDirectCostId: any;
  public targetComponentId: any;
  // load Grid after loading data;
  public showReference = false;
  public showTarget = false;
  public CreatedBy: any;
  public ModifiedBy = null;
  // variable related to the Id in DTO during GET AND POST service call;
  public refDirectCostSumsNull = true;
  public ReferenceDirectCostSumId: any;
  public TargetDirectCostSumId: any;
  // target scenerio
  public gridApiTarget: any;
  public gridColApiTarget: any;
  public pinnedBottomRowDataTarget: any;
  public finalErrorReference = [];
  public finalErrorTarget = [];
  public modifiedReferenceRows = [];
  public ideaSubmitterId: any;
  public modifiedTargetRows = [];
  // for remark input field;
  public remarkId: any;
  public remark: any;
  public remarkDataRecieved: any;
  public isChangeRemarks: any;
  public submitted: boolean = false;

  public isEditable: boolean = false;
  public regex = new RegExp(/[^\\s]+/);

  public newComponentIdTar:number=0;
  public newComponentIdRef:number=0;
  
  public businessAreaId: number;
  public businessLineId: number;
  public allUserList: any;
  public userInOrg: any;

  constructor(
    private localStorageService: LocalStorageService,
    private toast: ToasterService,
    private sharedService: SharedServiceService,
    private directCostService: DirectCostService,
    public dialog: MatDialog,
    private LocalStorageService: LocalStorageService,
    private router: Router,
    private menuService: MenuService,
    private ideaService: IdeaformService,
    private userService: UserInfoService,
    private catService: CategorizationService
  ) { }
  ngOnInit(): void {
    this.newComponentIdRef=0;
    this.newComponentIdTar=0;
    //Get current loging user details;
    this.sharedService.getIdeaIdAndtitle();

    this.remarkId = EnumRemarkType.DirectCost;
    //Get Country Code;

    countryCode = this.LocalStorageService.get('countryCode');
    this.sharedService.directCostReferenceSc.subscribe((response) => {
      this.updateTotalChangeRow();
      this.onRowClickedReference(1);
      this.hasUnsavedChanges = true;
      
      if (response.DirectCostId != 0) {
        response.IsActive = false;
        let isPushed = false
        if (this.modifiedReferenceRows.length == 0) {
          this.modifiedReferenceRows.push(response)
          isPushed = true
        }
        else {
          this.modifiedReferenceRows.forEach(row => {
            if (row.DirectCostId == response.DirectCostId) {
              isPushed = true
            }
          });
        }
        if (!isPushed) {
          this.modifiedReferenceRows.push(response)
        }
      }
    });
    this.sharedService.directCostTargetSc.subscribe((response) => {
      this.updateTotalChangeRow();
      this.hasUnsavedChanges = true;
   
      if (response.DirectCostId != 0) {
        response.IsActive = false;
        let isPushed = false
        if (this.modifiedTargetRows.length == 0) {
          this.modifiedTargetRows.push(response)
          isPushed = true
        }
        else {
          this.modifiedTargetRows.forEach(row => {
            if (row.DirectCostId == response.DirectCostId) {
              isPushed = true
            }
          });
        }
        if (!isPushed) {
          this.modifiedTargetRows.push(response)
        }
      }
    });
    this.solutionIdReference = MSolutionType.ReferenceScenario;
    this.solutionIdTarget = MSolutionType.TargetScenario;
    this.ideaId = this.localStorageService.get('ideaId');
    this.showReference = false;
    this.showTarget = false;
    this.isEditable = false;
    this.ideaService.getData(this.ideaId).subscribe((data: any) => {
      if (data.StatusCode == 200) {
        this.businessAreaId = data.Data.BusinessAreaId;
        this.businessLineId = data.Data.BusinessLineId;

        this.sharedService.loggedInUser.subscribe((user: any) => {
          this.userService.getUserList(this.businessAreaId, this.businessLineId).subscribe((d: any) => {
            this.allUserList = d;
            this.userInOrg = this.allUserList.find(u => u.Email == user.Email);
            if (this.userInOrg != undefined) {
              this.ideaSubmitterId = this.userInOrg.UserId;

              if (this.userInOrg.RoleId == 1 || this.userInOrg.RoleId == 2) {
                this.isEditable = true;
                this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
              }
              else if (this.userInOrg.RoleId == 3) {
                if (data.Data.SubmitterId == this.userInOrg.UserId) {
                  this.isEditable = true;
                  this.pageLock(this.ideaId, this.businessAreaId, this.businessLineId);
                }
                else {
                  this.isEditable = false;
                }
              }
              this.loadReferenceGridData();
            }
          })
        })
      }
    })
  }

  pageLock(ideaId, businessAreaId, businessLineId) {
    this.catService.getPageLockStatus(ideaId, businessAreaId, businessLineId).subscribe(data => {
      let errorMsgeFromBack = data.Data.Message;
      let pageLockMessage = "";
      if (data.StatusCode == 200) {
        this.isEditable = data.Data.Status;
        pageLockMessage = data.Data.Message;
        if(pageLockMessage != ""){
          this.openAlertDialog([{ [pageLockMessage]: serverMessage.warning }]);
        }
      }
      else {
        //Show errorCode and errorMessage in the UI
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer)
      }
    }, (error) => {
      //Show errorCode and errorMessage in the UI
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
    });
  }
  
  // loads reference grid data using api call;
  loadReferenceGridData() {
    this.directCostService.getAllData(this.ideaId, this.solutionIdReference, this.userInOrg.UserId).subscribe((data) => {
   
      if (data == null) {
        return;
      }
     
      let referenceRowDataOnly: any;
      let referenceOnlyTotalDataRec: any;
      this.referenceDataReceived = data;
      if (this.referenceDataReceived.StatusCode == 200) {
        this.referenceDataReceived = this.referenceDataReceived.Data;
        referenceOnlyTotalDataRec = this.referenceDataReceived.DirectCostSums;
        referenceRowDataOnly = this.referenceDataReceived.DirectCosts;
        if (referenceOnlyTotalDataRec === null) {
          this.refDirectCostSumsNull = true;
          this.ReferenceDirectCostSumId = 0;
        }
        else {
          this.refDirectCostSumsNull = false;
          this.ReferenceDirectCostSumId = referenceOnlyTotalDataRec.DirectCostSumId;
        }
        this.refScenerioRowdata = [];

        for (let i of referenceRowDataOnly) {
          i["IsEditable"] = this.isEditable
          this.refScenerioRowdata.push({
            DirectCostId: i.DirectCostId,
            IdeaId: i.IdeaId,
            SolutionId: i.SolutionId,
            ComponentId: i.ComponentId,
            refereceScenerioTitle: i.ComponentTitle,
            material1: i.Prdt_Material,
            labor1: i.Prdt_Labor,
            overhead: i.Prdt_Overhead,
            product: i.Prdt_Total,
            material2: i.Svc_Material,
            labor2: i.Svc_Labor,
            travel: i.Svc_Travel,
            service: i.Svc_Total,
            specialfactory: i.Factory,
            local: i.Local,
            others: i.Other,
            special: i.SpclCostTotal,
            totaldirectcost: i.GrandTotal,
            IsActive: i.IsActive,
            CreatedBy: i.CreatedBy,
            CreatedOn: i.CreatedOn,
            ModifiedBy: i.ModifiedBy,
            ModifiedOn: i.ModifiedOn,
            IsEditable: i.IsEditable
          })
        }
        this.refScenerioRowdata.sort((a,b)=>{
          return a.ComponentId-b.ComponentId;
        })
        this.loadTargetGridData();
      } else {
        let errorMsgeFromBack = this.referenceDataReceived.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  }
  // loads target grid data using api call;
  loadTargetGridData() {
    this.directCostService.getAllData(this.ideaId, this.solutionIdTarget, this.userInOrg.UserId).subscribe((data) => {
   
      if (data == null) {
        return;
      }
      let targetRowDataOnly: any;
      let targetOnlyTotalDataRecieved: any;
      this.targetDataReceived = data;
      if (this.targetDataReceived.StatusCode == 200) {
        this.targetDataReceived = this.targetDataReceived.Data;
        // for remark field;
        this.remarkDataRecieved = this.targetDataReceived.Remarks;
        targetOnlyTotalDataRecieved = this.targetDataReceived.DirectCostSums;
        targetRowDataOnly = this.targetDataReceived.DirectCosts;
        // for remark field;
        if (this.remarkDataRecieved != null) {
          this.remarkId = this.remarkDataRecieved.RemarkId;
          this.remark = this.remarkDataRecieved.Remark;
          this.isChangeRemarks = this.remarkDataRecieved.Remark;
        } else {
          this.remarkId = null;
          this.remark = null;
          this.isChangeRemarks = null;
        }
        if (targetOnlyTotalDataRecieved === null) {
          this.TargetDirectCostSumId = 0;
        }
        else {
          this.TargetDirectCostSumId = targetOnlyTotalDataRecieved.DirectCostSumId;
        }
        this.targetRowdata = [];
        for (let i of targetRowDataOnly) {
          i["IsEditable"] = this.isEditable
          this.targetRowdata.push({
            DirectCostId: i.DirectCostId,
            IdeaId: i.IdeaId,
            SolutionId: i.SolutionId,
            ComponentId: i.ComponentId,
            targetScenerioTitle: i.ComponentTitle,
            material1: i.Prdt_Material,
            labor1: i.Prdt_Labor,
            overhead: i.Prdt_Overhead,
            product: i.Prdt_Total,
            material2: i.Svc_Material,
            labor2: i.Svc_Labor,
            travel: i.Svc_Travel,
            service: i.Svc_Total,
            specialfactory: i.Factory,
            local: i.Local,
            others: i.Other,
            special: i.SpclCostTotal,
            totaldirectcost: i.GrandTotal,
            IsActive: i.IsActive,
            CreatedBy: i.CreatedBy,
            CreatedOn: i.CreatedOn,
            ModifiedBy: i.ModifiedBy,
            ModifiedOn: i.ModifiedOn,
            IsEditable: i.IsEditable
          })
        }
        
        this.loadGridStructure();
      } else {
        let errorMsgeFromBack = this.targetDataReceived.Data.Message;
        let errorContainer = [
          { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
          { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
        ]
        this.openAlertDialog(errorContainer);
      }
    }, (error) => {
      this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }]);
    })
  }
  // this will load the structure of grid to display the data coming from API Calls;
  loadGridStructure() {
    this.getRowHeightRef = params => params.data.refereceScenerioTitle == 'Total' ? 32 : 35;
    this.getRowHeightTar = params => params.data.targetScenerioTitle == 'Total' || params.data.targetScenerioTitle == 'Change to reference' ? 32 : 35;
    this.rowClassRulesRef = {
      'colorTotal': function (params) {
        let data = params.data.refereceScenerioTitle;
        return data === 'Total';
      }
    }
    this.rowClassRulesTar = {
      'colorTotal': function (params) {
        let data = params.data.targetScenerioTitle;
        return data === 'Total' || data === 'Change to reference';
      }
    }
    this.domLayout = 'autoHeight';
    this.defaultProperties = {     
      suppressMovable: true,
      sortable: false,
      enableValue: false,
      resizable: false
    }

    // hover
    this.tooltipShowDelay = 0;
    this.headerHeightRef = 62;
    this.rowStyle = {
      fontSize: '0.7rem'
    }
    this.frameworkComponents = {

      DoublingEditor: DoublingEditorComponent,
    };
    this.refScenerioColumndef = [
      {
        headerName: "Component",headerTooltip: "Component", field: "refereceScenerioTitle",width:300,
        cellStyle: (params) => (params.node.data.refereceScenerioTitle != 'Total' && params.node.data.refereceScenerioTitle != 'Change to reference' && (params.data.refereceScenerioTitle == null || params.data.refereceScenerioTitle == '' || !this.regex.test(params.data.refereceScenerioTitle) )) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },

        tooltipField: 'refereceScenerioTitle', filter: true, singleClickEdit: true,
        editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        },
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },

      },
      {
        headerName: "Material",headerTooltip: "Material", field: "material1", tooltipValueGetter:CurrencyCellRenderer, valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
          
            if (params.api != null) {
              params.api.forEachNode((node) => {
               
                total += Number(node.data.material1)
              })
              params.data.material1 = total;
              return total;
            }
          } else {
            return (params.data.material1);
          }
        }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,
      },
      {
        headerName: "Labor",headerTooltip: "Labor", field: "labor1",tooltipValueGetter:CurrencyCellRenderer, cellEditor: 'DoublingEditor',
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.labor1)
              });
              params.data.labor1 = total;
              return total;
            }
          } else {
            return (params.data.labor1);
          }
        }, cellStyle: { textAlign: "left" }, editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Overhead", field: "overhead", tooltipValueGetter:CurrencyCellRenderer,headerTooltip: "Overhead",
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.overhead)
              });
              params.data.overhead = total;
              return total;
            }
          } else {
            return (params.data.overhead)
          }
        }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Direct Product Costs",  headerTooltip: "Direct Product Costs", cellRenderer: CurrencyCellRenderer,tooltipValueGetter:CurrencyCellRenderer,
        field: "product", headerClass: 'right-border', cellClass: 'right-border colorTotal', cellStyle: { textAlign: "left" }, valueGetter: directProductCostReference,
      },
      {
        headerName: "Material",  headerTooltip: "Material", field: "material2", tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.material2)
              });
              params.data.material2 = total;
              return total;
            }
          } else {
            return (params.data.material2);
          }
        }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Labor",  headerTooltip: "Labor", field: "labor2",tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.labor2)
              });
              params.data.labor2 = total;
              return total;
            }
          } else {
            return (params.data.labor2);
          }
        },
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Travel",  headerTooltip: "Travel", field: "travel",tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.travel)
              });
              params.data.travel = total;
              return total;
            }
          } else {
            return (params.data.travel);
          }
        }, cellRenderer: CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true
      },
      {
        headerName: "Direct Service Costs",  headerTooltip: "Direct Service Costs", field: "service",tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal', cellStyle: { textAlign: "left" }, valueGetter: directServiceCostReference
      },
      {
        headerName: "Special Direct Costs Factory",  headerTooltip: "Special Direct Costs Factory", field: "specialfactory",tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.specialfactory)
              });
              params.data.specialfactory = total;
              return total;
            }
          } else {
            return (params.data.specialfactory);
          }
        }, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Local Cost",  headerTooltip: "Local Cost", field: "local", tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.local)
              });
              params.data.local = total;
              return total;
            }
          } else {
            return (params.data.local);
          }
        }, cellRenderer: CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true
      },
      {
        headerName: "Others",  headerTooltip: "Others", field: "others", tooltipValueGetter:CurrencyCellRenderer,
        valueGetter: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            let total = 0;
            if (params.api != null) {
              params.api.forEachNode((node) => {
                total += Number(node.data.others)
              });
              params.data.others = total;
              return total;
            }
          } else {
            return (params.data.others);
          }
        }, cellRenderer: CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.refereceScenerioTitle === 'Total' || params.node.data.refereceScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true
      },
      {
        headerName: "Special Direct Costs",  headerTooltip: "Special Direct Costs", field: "special",tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" }, valueGetter: specialDirectCostReference,
      },
      {
        headerName: "Total Direct Costs",  headerTooltip: "Total Direct Costs", field: "totaldirectcost", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" }, valueGetter: totalDirectCostReference
      },
      {
        headerName: "Action",
        filter: false,
        width:100,
        cellClass: 'center-align',
        cellRendererFramework: DeleteiconComponent,
        colId: "edit",
        sortable: false,
        headerTooltip: "Action"
      }
    ];
    this.targetColumnDef = [
      {
        headerName: "Component",  headerTooltip: "Component", field: "targetScenerioTitle",width:300,
        cellStyle: (params) => (params.node.data.targetScenerioTitle != 'Total' && params.node.data.targetScenerioTitle != 'Change to reference' && (params.data.targetScenerioTitle == null || params.data.targetScenerioTitle == ''  || !this.regex.test(params.data.targetScenerioTitle) )) ? { borderLeft: '5px solid #a94442' } : { borderLeft: '' },

        filter: true, singleClickEdit: true, editable: function (params) {

          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        },   tooltipField: 'targetScenerioTitle',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: {
          maxLength: '255',   // override the editor defaults
          cols: '40',
          rows: '5'
        },

      },
      {
        headerName: "Material",   headerTooltip: "Material",field: "material1",tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
        editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Labor",  headerTooltip: "Labor", field: "labor1",tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor',
        editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Overhead",   field: "overhead", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer,headerTooltip: "Overhead"
      },
      {
        headerName: "Direct Product Costs",  headerTooltip: "Direct Product Costs", field: "product", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" }, valueGetter: directProductCost
      },
      {
        headerName: "Material",  headerTooltip: "Material", field: "material2", tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Labor",  headerTooltip: "Labor", field: "labor2",tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Travel",  headerTooltip: "Travel", field: "travel", tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Direct Service Costs",  headerTooltip: "Direct Service Costs", field: "service",tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, headerClass: 'right-border',
        cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" }, valueGetter: directServiceCost
      },
      {
        headerName: "Special Direct Costs Factory",  headerTooltip: "Special Direct Costs Factory", cellStyle: { textAlign: "left" },
        cellEditor: 'DoublingEditor', field: "specialfactory", tooltipValueGetter:CurrencyCellRenderer,
        editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Local Cost",  headerTooltip: "Local Cost", field: "local", tooltipValueGetter:CurrencyCellRenderer, cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {
            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Others",  headerTooltip: "Others", field: "others",tooltipValueGetter:CurrencyCellRenderer,
        cellStyle: { textAlign: "left" }, cellEditor: 'DoublingEditor', editable: function (params) {
          if (params.node.data.targetScenerioTitle === 'Total' || params.node.data.targetScenerioTitle === 'Change to reference') {
            return false;
          }
          else {

            return params.data.IsEditable;
          }
        }, singleClickEdit: true, cellRenderer: CurrencyCellRenderer
      },
      {
        headerName: "Special Direct Costs",  headerTooltip: "Special Direct Costs", field: "special", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, valueGetter: specialDirectCost,
        headerClass: 'right-border', cellClass: 'right-border colorTotal', cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Total Direct Costs",  headerTooltip: "Total Direct Costs", field: "totaldirectcost", tooltipValueGetter:CurrencyCellRenderer,
        cellRenderer: CurrencyCellRenderer, valueGetter: totalDirectCost,
        headerClass: 'right-border', cellClass: 'right-border colorTotal ', cellStyle: { textAlign: "left" },
      }
      , {
        headerName: "Action",
        filter: false,
        cellClass: 'center-align',
        cellRendererFramework: DeleteiconComponent,
        colId: "edit",
        sortable: false,
        width:100,
        headerTooltip: "Action"
      }

    ]
    this.showReference = true;
    this.showTarget = true;
  }
  // get called when reference grid is loaded and it can be used to get all the information  related to the grid;
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColApi = params.columnApi;
    this.pinnedBottomRowData = createData(1, this.totalRef());
    params.api.setPinnedBottomRowData(this.pinnedBottomRowData);
    let rowCnt = this.gridApi.getDisplayedRowCount();
    if (rowCnt >= 5) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('refSceDrtCost').style.height = '269px';
    }
  }
  // get called when reference grid cell is clicked;
  onRowClickedReference($event) {
    let rowCount = this.gridApi.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApi.setDomLayout('autoHeight');
      document.getElementById('refSceDrtCost').style.height = null;
    };
  }
  // called when target grid is loaded in ui and contains important information of the grid
  onGridReadyTarget(params) {
    this.gridApiTarget = params.api
    this.gridColApiTarget = params.columnApi
    this.updateTotalChangeRow();
    let rowCnt = this.gridApiTarget.getDisplayedRowCount();
    if (rowCnt >= 5) {
      this.gridApiTarget.setDomLayout('normal');
      document.getElementById('tgtSceDrtCost').style.height = '269px';
    }
  }
  // get called when target grid cell is clicked;
  onRowClickedTarget($event) {
    let rowCount = this.gridApiTarget.getDisplayedRowCount()
    if (rowCount < 5) {
      this.gridApiTarget.setDomLayout('autoHeight');
      document.getElementById('tgtSceDrtCost').style.height = null;
    }
  }
  // get called when reference grid size is changed and resize the grid;
  onGridRefSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }
  // get called when target grid size is changed and resize the grid;
  onGridTgtSizeChanged(params) {
    this.gridApiTarget = params.api;
    this.gridApiTarget.sizeColumnsToFit();
  }
  // this will get called when reference grid cell value is changed;
  onCellValueChanged(params) {
    this.hasUnsavedChanges = true;
    this.updateTotalChangeRow();

    if (params.data.DirectCostId != 0) {
      let isPushed = false;
      if (this.modifiedReferenceRows.length == 0) {
        this.modifiedReferenceRows.push(params.data)
        isPushed = true;
      }
      else {
        // same row changed;
        this.modifiedReferenceRows.forEach(row => {
          if (row.DirectCostId == params.data.DirectCostId) {
            isPushed = true;
          }
        });
      }
      // if another row is changed;
      if (!isPushed) {
        this.modifiedReferenceRows.push(params.data)
      }
    }
  }
  // it updates the change to reference row of target grid on change of total row in reference and target grid;
  updateTotalChangeRow() {
    let countRow = this.gridApiTarget.getDisplayedRowAtIndex(0);
    countRow = countRow == undefined ? 0 : 1;
    let changeInReferenceRow = this.createRowChangeReference(countRow);
    this.gridApiTarget.setPinnedBottomRowData(changeInReferenceRow);
  }
  // called when cell value changed in target grid;
  onCellValueChangedTarget(params) {
    this.hasUnsavedChanges = true;
    this.updateTotalChangeRow();
    if (params.data.DirectCostId != 0) {
      let isPushed = false;
      if (this.modifiedTargetRows.length == 0) {
        this.modifiedTargetRows.push(params.data)
        isPushed = true;
      }
      else {
        // same row changed;
        this.modifiedTargetRows.forEach(row => {
          if (row.DirectCostId == params.data.DirectCostId) {
            isPushed = true;
          }
        });
      }
      // if another row is changed;
      if (!isPushed) {
        this.modifiedTargetRows.push(params.data)
      }
    }
  }
  // called when user click add row icon for reference grid;
  AddNewRowRef() {
    this.validateAddReference();
    let rowCount = this.gridApi.getDisplayedRowCount()
    if (rowCount == 5) {
      this.gridApi.setDomLayout('normal');
      document.getElementById('refSceDrtCost').style.height = '269px';
    }
  }
  // add new row to target grid on click of add icon;
  AddNewRowTar() {
    this.validateAddTarget();
    let rowCount = this.gridApiTarget.getDisplayedRowCount()
    if (rowCount == 5) {
      this.gridApiTarget.setDomLayout('normal');
      document.getElementById('tgtSceDrtCost').style.height = '269px';
    };
  }
  // vallidate reference grid last row before adding new row and also add new row;
  validateAddReference() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApi.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApi.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.newComponentIdRef=this.newComponentIdRef-1;
      this.gridApi.applyTransaction({
        add: [{
          DirectCostId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionIdReference,
          ComponentId: this.newComponentIdRef,
          refereceScenerioTitle: "",
          material1: null,
          labor1: null,
          overhead: null,
          product: null,
          material2: null,
          labor2: null,
          travel: null,
          service: null,
          specialfactory: null,
          local: null,
          others: null,
          special: null,
          totaldirectcost: null,
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: this.ModifiedBy,
          ModifiedOn: "2021-04-13T15:16:43.223",
          IsEditable: this.isEditable
        }], addIndex: 0
      })
    } else {
      let data = this.gridApi.getDisplayedRowAtIndex(0).data;
      let checked = this.regex.test(data.refereceScenerioTitle)
      if (data.refereceScenerioTitle === "Add Component Here" || data.refereceScenerioTitle === "" || !checked) {
        error.push({ Component: errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.newComponentIdRef=this.newComponentIdRef-1;
        this.gridApi.applyTransaction({
          add: [{
            DirectCostId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionIdReference,
            ComponentId: this.newComponentIdRef,
            refereceScenerioTitle: "",
            material1: null,
            labor1: null,
            overhead: null,
            product: null,
            material2: null,
            labor2: null,
            travel: null,
            service: null,
            specialfactory: null,
            local: null,
            others: null,
            special: null,
            totaldirectcost: null,
            IsActive: true,
            CreatedBy: this.ideaSubmitterId,
            CreatedOn: "2021-04-13T15:16:43.223",
            ModifiedBy: this.ModifiedBy,
            ModifiedOn: "2021-04-13T15:16:43.223",
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      } else {
        this.openAlertDialog(error)
      }
    }
  }
  // check the last row of Target grid before adding new row to check whether some feild is empty or not;
  validateAddTarget() {
    let errorMessage = messages.cellEmptyErrorMesssage;
    let count = 0;
    this.gridApiTarget.forEachNode(node => count += 1);
    count = count - 1;
    let error = [];
    let countRow = this.gridApiTarget.getDisplayedRowAtIndex(0);
    if (countRow == undefined) {
      this.hasUnsavedChanges = true;
      this.newComponentIdTar=this.newComponentIdTar-1;
      this.gridApiTarget.applyTransaction({
        add: [{
          targetScenerioTitle: "",
          DirectCostId: 0,
          IdeaId: this.ideaId,
          SolutionId: this.solutionIdTarget,
          ComponentId: this.newComponentIdTar,
          material1: null,
          labor1: null,
          overhead: null,
          product: null,
          material2: null,
          labor2: null,
          travel: null,
          service: null,
          specialfactory: null,
          local: null,
          others: null,
          special: null,
          totaldirectcost: null,
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: this.ModifiedBy,
          ModifiedOn: "2021-04-13T15:16:43.223",
          IsEditable: this.isEditable
        }], addIndex: 0
      })
    } else {
      let data = this.gridApiTarget.getDisplayedRowAtIndex(0).data;
      let checked = this.regex.test(data.targetScenerioTitle)
      if (data.targetScenerioTitle === "Add Component Here" || data.targetScenerioTitle === "" || !checked) {
        error.push({ Component: errorMessage })
      }
      if (error.length == 0) {
        this.hasUnsavedChanges = true;
        this.newComponentIdTar=this.newComponentIdTar-1;
        this.gridApiTarget.applyTransaction({
          add: [{
            targetScenerioTitle: "",
            DirectCostId: 0,
            IdeaId: this.ideaId,
            SolutionId: this.solutionIdTarget,
            ComponentId: this.newComponentIdTar,
            material1: null,
            labor1: null,
            overhead: null,
            product: null,
            material2: null,
            labor2: null,
            travel: null,
            service: null,
            specialfactory: null,
            local: null,
            others: null,
            special: null,
            totaldirectcost: null,
            IsActive: true,
            CreatedBy: this.ideaSubmitterId,
            CreatedOn: "2021-04-13T15:16:43.223",
            ModifiedBy: this.ModifiedBy,
            ModifiedOn: "2021-04-13T15:16:43.223",
            IsEditable: this.isEditable
          }], addIndex: 0
        })
      } else {
        this.openAlertDialog(error)

      }
    }
  }

  // calculate total for reference grid for bottom "total row"
  totalRef() {
    let material1 = 0
    let labor1 = 0
    let overhead = 0
    let product = 0
    let material2 = 0
    let labor2 = 0
    let travel = 0
    let service = 0
    let specialfactory = 0
    let local = 0
    let others = 0
    let special = 0
    let totaldirectcost = 0
    this.gridApi.forEachNode(function (node) {
      material1 = Number(node.data.material1) + material1
      labor1 = Number(node.data.labor1) + labor1
      overhead = Number(node.data.overhead) + overhead
      product = Number(node.data.product) + product
      material2 = Number(node.data.material2) + material2
      labor2 = Number(node.data.labor2) + labor2
      travel = Number(node.data.travel) + travel
      service = Number(node.data.service) + service
      specialfactory = Number(node.data.specialfactory) + specialfactory
      local = Number(node.data.local) + local
      others = Number(node.data.others) + others
      special = Number(node.data.special) + special
      totaldirectcost = Number(node.data.totaldirectcost) + totaldirectcost
    })
    return [material1, labor1, overhead, product, material2, labor2, travel, service, specialfactory, local, others, special, totaldirectcost];
  }
  //calculate the total of all row for the Row - "Total" in the Target scenerio table;
  totalTargetScenerio() {
    let material1 = 0;
    let labor1 = 0;
    let overhead = 0;
    let product = 0;
    let material2 = 0;
    let labor2 = 0;
    let travel = 0;
    let service = 0;
    let specialfactory = 0;
    let local = 0;
    let others = 0;
    let special = 0;
    let totaldirectcost = 0;
    this.gridApiTarget.forEachNode(function (node) {
      material1 = Number(node.data.material1) + material1;
      labor1 = Number(node.data.labor1) + labor1;
      overhead = Number(node.data.overhead) + overhead;
      product = Number(node.data.product) + product;
      material2 = Number(node.data.material2) + material2;
      labor2 = Number(node.data.labor2) + labor2;
      travel = Number(node.data.travel) + travel;
      service = Number(node.data.service) + service;
      specialfactory = Number(node.data.specialfactory) + specialfactory;
      local = Number(node.data.local) + local;
      others = Number(node.data.others) + others;
      special = Number(node.data.special) + special;
      totaldirectcost = Number(node.data.totaldirectcost) + totaldirectcost;
    })
    return [material1, labor1, overhead, product, material2, labor2, travel, service, specialfactory, local, others, special, totaldirectcost];
  }
  // create chenge to reference row in target grid;
  createRowChangeReference(countRow) {
    let result = [];
    let referenceTotalData: any;
    let targetTotalData: any;
    referenceTotalData = createData(1, this.totalRef())
    referenceTotalData = referenceTotalData[0];
    result = createDataTar(1, this.totalTargetScenerio());
    targetTotalData = result[0];
   
    if (countRow == 0) {
      result.push({
        targetScenerioTitle: 'Change to reference',
        material1: 0,
        labor1: 0,
        overhead: 0,
        product: 0,
        material2: 0,
        labor2: 0,
        travel: 0,
        service: 0,
        specialfactory: 0,
        local: 0,
        others: 0,
        special: 0,
        totaldirectcost: 0
      })
    } else if (countRow == 1) {
      result.push({
        targetScenerioTitle: 'Change to reference',
        material1: referenceTotalData.material1 - targetTotalData.material1,
        labor1: referenceTotalData.labor1 - targetTotalData.labor1,
        overhead: referenceTotalData.overhead - targetTotalData.overhead,
        product: referenceTotalData.product - targetTotalData.product,
        material2: referenceTotalData.material2 - targetTotalData.material2,
        labor2: referenceTotalData.labor2 - targetTotalData.labor2,
        travel: referenceTotalData.travel - targetTotalData.travel,
        service: referenceTotalData.service - targetTotalData.service,
        specialfactory: referenceTotalData.specialfactory - targetTotalData.specialfactory,
        local: referenceTotalData.local - targetTotalData.local,
        others: referenceTotalData.others - targetTotalData.others,
        special: referenceTotalData.special - targetTotalData.special,
        totaldirectcost: referenceTotalData.totaldirectcost - targetTotalData.totaldirectcost
      })
    }


    return result;
  }
  checkRemarkChange() {
    let remarkPresent = false;
    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        remarkPresent = true;
        this.hasUnsavedChanges = true;
      }
    }
    return remarkPresent;
  }
  // saves  all the data after doing mandatory validations;
  submit(event?: any) {   
    this.submitted = true;
    this.finalErrorReference = [];
    this.finalErrorTarget = [];
    let allReferenceRowData = this.allReferenceRowData();
    let allTargetRowData = this.allTargetRowData();    
    let remarkPresent = this.checkRemarkChange();
    if (allTargetRowData == null || allReferenceRowData == null) {
      let alertBoxError = [];
      alertBoxError = this.finalErrorReference;
      for (let i of this.finalErrorTarget) {
        alertBoxError.push(i);
      }
      this.modifiedReferenceRows = this.modifiedReferenceRows.filter(newRow => newRow.DirectCostId != 0)
      this.modifiedTargetRows = this.modifiedTargetRows.filter(newRow => newRow.DirectCostId != 0)
      
      this.submitted = false;
    } else {
      if (allReferenceRowData.length != 0 || allTargetRowData.length != 0 || remarkPresent) {
       

        let combinedDataToSend = this.dataToSend(allReferenceRowData, allTargetRowData);
        let responseRef: any;
        
        
        return this.directCostService.postAllData(combinedDataToSend).toPromise().then((resReference) => {
          responseRef = resReference;
          let errorMsgeFromBack = responseRef.Data.Message;          
          let successCodeRef = responseRef.StatusCode;
          let errorCodeRef = responseRef.Data.ErrorCode;
          //Check if successCode is ok i.e 200
          if (successCodeRef == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCodeRef == 0) {
              this.toast.notify(serverMessage.dataSaveMessage, "success");
              this.newComponentIdTar=0;
              this.newComponentIdRef=0;
              this.hasUnsavedChanges = false;                
              if (event != undefined) {
                this.router.navigate(['/manageIdea'])
              }
              return true;
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI
              this.modifiedReferenceRows = this.modifiedReferenceRows.filter(newRow => newRow.DirectCostId != 0)
              this.modifiedTargetRows = this.modifiedTargetRows.filter(newRow => newRow.DirectCostId != 0)
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message+errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
              this.submitted = false;
              return false;
              
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            //Show errorCode and errorMessage in the UI
            this.modifiedReferenceRows = this.modifiedReferenceRows.filter(newRow => newRow.DirectCostId != 0)
            this.modifiedTargetRows = this.modifiedTargetRows.filter(newRow => newRow.DirectCostId != 0)
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message+errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            this.submitted = false;
            return false;
          }
          
        }, (error) => {
          this.modifiedReferenceRows = this.modifiedReferenceRows.filter(newRow => newRow.DirectCostId != 0)
          this.modifiedTargetRows = this.modifiedTargetRows.filter(newRow => newRow.DirectCostId != 0)
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          this.submitted = false;
          return false;
        }
        )
      } else {
        this.hasUnsavedChanges = false;
        if (event != undefined) {
          this.router.navigate(['/manageIdea']);
        }
        return true;
      }     
    }
  }
  // this will fetch all the data present in the grid of the Reference Scenerio;
  allReferenceRowData() {
    let rowDataReference = [];
    let rowAllRowData = [];
    this.gridApi.forEachNode((node) => {
      rowAllRowData.push(node.data);
      if (node.data.DirectCostId == 0) {
        rowDataReference.push(node.data)
      }
    });
    let error = this.vallidateAllReferenceRow(rowAllRowData);
    if (error) {
      return null;
    } else {
      this.modifiedReferenceRows = this.modifiedReferenceRows.concat(rowDataReference)
      return this.modifiedReferenceRows;
    }
  }
  // this will fetch all the data present in the grid of the Target Scenerio;
  allTargetRowData() {
    let rowDataTarget = [];
    this.gridApiTarget.forEachNode((node) => { rowDataTarget.push(node.data) });
    let error = this.vallidateAllTargetRow(rowDataTarget);
    if (error) {
      return null;
    } else {
      rowDataTarget.forEach((row) => {
        if (row.DirectCostId == 0) {
          this.modifiedTargetRows.push(row)
        }
      })
      return this.modifiedTargetRows;
    }
  }
  // it will vallidate the all reference scenerio data before sending it to the dataBase;
  vallidateAllReferenceRow(rowDataReference) {
    let countError = 0;
    for (let i of rowDataReference) {
      let checked = this.regex.test(i.refereceScenerioTitle);
      if (i.refereceScenerioTitle == undefined || i.refereceScenerioTitle == null || i.refereceScenerioTitle == "" || !checked) {
        if (countError == 0) {
          this.finalErrorReference.push({ Component: messages.directCost.referenceSc })
        }
        countError += 1;
      }
    }
    if (this.finalErrorReference.length) {
      return true;
    } else {
      return false;
    }
  }
  // it will vallidate the all target scenerio data befor sending it to the dataBase;
  vallidateAllTargetRow(rowDataTarget) {
    let countError = 0;
    for (let i of rowDataTarget) {
      let checked = this.regex.test(i.targetScenerioTitle);
      if (i.targetScenerioTitle == undefined || i.targetScenerioTitle == null || i.targetScenerioTitle == "" || !checked) {
        if (countError == 0) {
          this.finalErrorTarget.push({ Component: messages.directCost.targetSc })
        }
        countError += 1;
      }
    }
    if (this.finalErrorTarget.length) {
      return true;
    } else {
      return false;
    }
  }
  //Converts the vallidated data which is to be send in the database into require DTO; 
  dataToSend(referenceData, targetData) {
    let DirectCostsReference = [];
    let DirectCostSumsReference: any;
    let combinedDataReference: any;
    // for Combining Reference Row Data;
    for (let i of referenceData) {
      let modifierId = null
      if (i.DirectCostId != 0) {
        modifierId = this.ideaSubmitterId;
      }
      DirectCostsReference.push({
        DirectCostId: i.DirectCostId,
        IdeaId: i.IdeaId,
        SolutionId: i.SolutionId,
        ComponentId: i.ComponentId,
        ComponentTitle: i.refereceScenerioTitle.trim(),
        Prdt_Material: Number(i.material1) == 0 ? null : Number(i.material1),
        Prdt_Labor: Number(i.labor1) == 0 ? null : Number(i.labor1),
        Prdt_Overhead: Number(i.overhead) == 0 ? null : Number(i.overhead),
        Prdt_Total: Number(i.product),
        Svc_Material: Number(i.material2) == 0 ? null : Number(i.material2),
        Svc_Labor: Number(i.labor2) == 0 ? null : Number(i.labor2),
        Svc_Travel: Number(i.travel) == 0 ? null : Number(i.travel),
        Svc_Total: Number(i.service),
        Factory: Number(i.specialfactory) == 0 ? null : Number(i.specialfactory),
        Local: Number(i.local) == 0 ? null : Number(i.local),
        Other: Number(i.others) == 0 ? null : Number(i.others),
        SpclCostTotal: Number(i.special),
        GrandTotal: Number(i.totaldirectcost),
        IsActive: i.IsActive,
        CreatedBy: i.CreatedBy,
        CreatedOn: i.CreatedOn,
        ModifiedBy: modifierId, //adding modifier id
        ModifiedOn: i.ModifiedOn
      })
    }
    let ReferenceDirectCostSumId = this.ReferenceDirectCostSumId;
    let SolutionIdReference = this.solutionIdReference;
    let IdeaId = this.ideaId;
    this.referenceOnlyTotalRowData = this.gridApi.pinnedRowModel.pinnedBottomRows[0].data;
  
    if (referenceData.length == 0) {
      DirectCostSumsReference = null;
    } else {
      DirectCostSumsReference = {
        DirectCostSumId: ReferenceDirectCostSumId,
        IdeaId: IdeaId,
        SolutionId: SolutionIdReference,
        Prdt_MaterialSum: this.referenceOnlyTotalRowData.material1,
        Prdt_LaborSum: this.referenceOnlyTotalRowData.labor1,
        Prdt_OverheadSum: this.referenceOnlyTotalRowData.overhead,
        Prdt_GrandSum: this.referenceOnlyTotalRowData.product,
        Svc_MaterialSum: this.referenceOnlyTotalRowData.material2,
        Svc_LaborSum: this.referenceOnlyTotalRowData.labor2,
        Svc_TravelSum: this.referenceOnlyTotalRowData.travel,
        Svc_GrandSum: this.referenceOnlyTotalRowData.service,
        FactorySum: this.referenceOnlyTotalRowData.specialfactory,
        LocalSum: this.referenceOnlyTotalRowData.local,
        OtherSum: this.referenceOnlyTotalRowData.others,
        SpclCostGrandSum: this.referenceOnlyTotalRowData.special,
        GrandSum: this.referenceOnlyTotalRowData.totaldirectcost,
        IsActive: true,
        CreatedBy: this.ideaSubmitterId,
        CreatedOn: "2021-04-13T15:16:43.223",
        ModifiedBy: null,
        ModifiedOn: null,
      }
    }
    combinedDataReference = { DirectCosts: DirectCostsReference, DirectCostSums: DirectCostSumsReference, Remarks: null, IdeaId: IdeaId, SolutionId: SolutionIdReference, UserId: this.ideaSubmitterId, BusinessAreaId: this.businessAreaId, BusinessLineId: this.businessLineId };
    let DirectCostsTarget = [];
    let DirectCostSumsTarget: any;
    let combinedDataTarget: any;
    for (let j of targetData) {
      let modifierIdTar = null
      if (j.DirectCostId != 0) {
        modifierIdTar = this.ideaSubmitterId;
      }
      DirectCostsTarget.push({
        DirectCostId: j.DirectCostId,
        IdeaId: j.IdeaId,
        SolutionId: j.SolutionId,
        ComponentId: j.ComponentId,
        ComponentTitle: j.targetScenerioTitle.trim(),
        Prdt_Material: Number(j.material1) == 0 ? null : Number(j.material1),
        Prdt_Labor: Number(j.labor1) == 0 ? null : Number(j.labor1),
        Prdt_Overhead: Number(j.overhead) == 0 ? null : Number(j.overhead),
        Prdt_Total: Number(j.product),
        Svc_Material: Number(j.material2) == 0 ? null : Number(j.material2),
        Svc_Labor: Number(j.labor2) == 0 ? null : Number(j.labor2),
        Svc_Travel: Number(j.travel) == 0 ? null : Number(j.travel),
        Svc_Total: Number(j.service),
        Factory: Number(j.specialfactory) == 0 ? null : Number(j.specialfactory),
        Local: Number(j.local) == 0 ? null : Number(j.local),
        Other: Number(j.others) == 0 ? null : Number(j.others),
        SpclCostTotal: Number(j.special),
        GrandTotal: Number(j.totaldirectcost),
        IsActive: j.IsActive,
        CreatedBy: j.CreatedBy,
        CreatedOn: j.CreatedOn,
        ModifiedBy: modifierIdTar,
        ModifiedOn: j.ModifiedOn
      })
    }
    let TargetDirectCostSumId = this.TargetDirectCostSumId;
    let SolutionIdTarget = this.solutionIdTarget;
    this.targetOnlyTotalRowData = this.gridApiTarget.pinnedRowModel.pinnedBottomRows[0].data;
    if (targetData.length == 0) {
      DirectCostSumsTarget = null;
    } else {
      DirectCostSumsTarget = {
        DirectCostSumId: TargetDirectCostSumId,
        IdeaId: IdeaId,
        SolutionId: SolutionIdTarget,
        Prdt_MaterialSum: this.targetOnlyTotalRowData.material1,
        Prdt_LaborSum: this.targetOnlyTotalRowData.labor1,
        Prdt_OverheadSum: this.targetOnlyTotalRowData.overhead,
        Prdt_GrandSum: this.targetOnlyTotalRowData.product,
        Svc_MaterialSum: this.targetOnlyTotalRowData.material2,
        Svc_LaborSum: this.targetOnlyTotalRowData.labor2,
        Svc_TravelSum: this.targetOnlyTotalRowData.travel,
        Svc_GrandSum: this.targetOnlyTotalRowData.service,
        FactorySum: this.targetOnlyTotalRowData.specialfactory,
        LocalSum: this.targetOnlyTotalRowData.local,
        OtherSum: this.targetOnlyTotalRowData.others,
        SpclCostGrandSum: this.targetOnlyTotalRowData.special,
        GrandSum: this.targetOnlyTotalRowData.totaldirectcost,
        IsActive: true,
        CreatedBy: this.ideaSubmitterId,
        CreatedOn: "2021-04-13T15:16:43.223",
        ModifiedBy: this.ideaSubmitterId,
        ModifiedOn: "2021-04-13T15:16:43.223"
      }
    }
    let Remarks: any;

    if (this.remark != null && this.remark != undefined) {
      if (this.remark.trim() != this.isChangeRemarks) {
        Remarks = {
          IsActive: true,
          CreatedBy: this.ideaSubmitterId,
          CreatedOn: "2021-04-13T15:16:43.223",
          ModifiedBy: null,
          ModifiedOn: null,
          IdeaId: this.ideaId,
          SolutionId: SolutionIdTarget,
          Remark: this.remark.trim(),
          RemarkId: this.remarkId ?? 0,
          RemarkTypeId: EnumRemarkType.DirectCost
        }
      } else {
        Remarks = null;
      }
    } else {
      Remarks = null;
    }
    combinedDataTarget = { DirectCosts: DirectCostsTarget, DirectCostSums: DirectCostSumsTarget, Remarks: Remarks, IdeaId: IdeaId, SolutionId: SolutionIdTarget, UserId: this.ideaSubmitterId, BusinessAreaId: this.businessAreaId, BusinessLineId: this.businessLineId };
    let combinedData = [];
    if (combinedDataReference.DirectCosts.length != 0) {
      combinedData.push(combinedDataReference);
    }
    if (combinedDataTarget.DirectCosts.length != 0 || Remarks != null) {
      combinedData.push(combinedDataTarget);
    }
  
    return combinedData;
  }
  // get called when user click on cancel and exit;
  onClickCancel() {
    this.hasUnsavedChanges = false;
    this.router.navigate(['/manageIdea'])
  }
  // move user to back page;
  onClickBack() {
    this.menuService.onClickNextAndBack("back")
  }
  // moves user to the next page;
  onClickNext() {
    this.menuService.onClickNextAndBack("next")
  }
   
  // open dialog box in case of any error;
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
  canDeactivate() {
    return this.hasUnsavedChanges;
  }
}
// calulates direct cost for reference grid;
function directProductCostReference(params) {
  if (params.data.refereceScenerioTitle != "Total") {
    params.data.product = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead);
    if (params.data.product == 0) {
      params.data.product = null;
    }
    return params.data.product;
  } else if (params.data.refereceScenerioTitle == "Total") {
    let totalDirectCost = 0;
    if (params.api != null) {
      params.api.forEachNode((node: any) => { totalDirectCost += Number(node.data.material1) + Number(node.data.labor1) + Number(node.data.overhead) });
      params.data.product = totalDirectCost;
      return totalDirectCost;
    }
  }
}
// calulates service cost for reference grid;
function directServiceCostReference(params) {
  if (params.data.refereceScenerioTitle != "Total") {
    params.data.service = Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel);
    if (params.data.service == 0) {
      params.data.service = null;
    }
    return params.data.service;
  } else if (params.data.refereceScenerioTitle == "Total") {
    let totalServiceCost = 0;
    if (params.api != null) {
      params.api.forEachNode((node: any) => { totalServiceCost += Number(node.data.material2) + Number(node.data.labor2) + Number(node.data.travel) });
      params.data.service = totalServiceCost;
      return totalServiceCost;
    }
  }
}
// calulates special direct cost for reference grid;
function specialDirectCostReference(params) {
  if (params.data.refereceScenerioTitle != "Total") {
    params.data.special = Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
    if (params.data.special == 0) {
      params.data.special = null;
    }
    return params.data.special;
  } else if (params.data.refereceScenerioTitle == "Total") {
    let totalSpecialCost = 0;
    if (params.api != null) {
      params.api.forEachNode((node: any) => { totalSpecialCost += Number(node.data.specialfactory) + Number(node.data.local) + Number(node.data.others) });
      params.data.special = totalSpecialCost;
      return totalSpecialCost;
    }
  }
}
// calulates total direct cost for reference grid;
function totalDirectCostReference(params) {
  if (params.data.refereceScenerioTitle != "Total") {
    params.data.totaldirectcost = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead) + Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel) + Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
    if (params.data.totaldirectcost == 0) {
      params.data.totaldirectcost = null;
    }
    return params.data.totaldirectcost;
  } else if (params.data.refereceScenerioTitle == "Total") {
    let totalDirectCost = 0;
    if (params.api != null) {
      params.api.forEachNode((node: any) => {
        totalDirectCost += Number(node.data.material1) + Number(node.data.labor1) + Number(node.data.overhead) + Number(node.data.material2) + Number(node.data.labor2) + Number(node.data.travel) + Number(node.data.specialfactory) + Number(node.data.local) + Number(node.data.others)
      }
      )
      params.data.totaldirectcost = totalDirectCost;
      return totalDirectCost;
    }
  }
}
// create row for bottom total row for reference grid;
function createData(count, prefix) {
  let result = [];
  result.push({
    refereceScenerioTitle: 'Total',
    material1: prefix[0],
    labor1: prefix[1],
    overhead: prefix[2],
    product: prefix[3],
    material2: prefix[4],
    labor2: prefix[5],
    travel: prefix[6],
    service: prefix[7],
    specialfactory: prefix[8],
    local: prefix[9],
    others: prefix[10],
    special: prefix[11],
    totaldirectcost: prefix[12]
  });
  return result;
}
// create row for bottom total row or target row;
function createDataTar(count, prefix) {
  let result = [];
  result.push({
    targetScenerioTitle: 'Total',
    material1: prefix[0],
    labor1: prefix[1],
    overhead: prefix[2],
    product: prefix[3],
    material2: prefix[4],
    labor2: prefix[5],
    travel: prefix[6],
    service: prefix[7],
    specialfactory: prefix[8],
    local: prefix[9],
    others: prefix[10],
    special: prefix[11],
    totaldirectcost: prefix[12]
  })
  return result;
}
// calculates "Direct product cost";
function directProductCost(params) {
  params.data.product = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead);
  if (params.data.product == 0 && params.data.targetScenerioTitle != "Total" && params.data.targetScenerioTitle != 'Change to reference') {
    params.data.product = null;
  }
  return params.data.product;
};
// calculates "service costs"; 
function directServiceCost(params) {
  params.data.service = Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel);
  if (params.data.service == 0 && params.data.targetScenerioTitle != "Total" && params.data.targetScenerioTitle != 'Change to reference') {
    params.data.service = null;
  }
  return params.data.service;
};
// calculates "special direct cost";
function specialDirectCost(params) {
  params.data.special = Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
  if (params.data.special == 0 && params.data.targetScenerioTitle != "Total" && params.data.targetScenerioTitle != 'Change to reference') {
    params.data.special = null;
  }
  return params.data.special;
};
// calculates "total direct cost";
function totalDirectCost(params) {
  params.data.totaldirectcost = Number(params.data.material1) + Number(params.data.labor1) + Number(params.data.overhead) + Number(params.data.material2) + Number(params.data.labor2) + Number(params.data.travel) + Number(params.data.specialfactory) + Number(params.data.local) + Number(params.data.others);
  if (params.data.totaldirectcost == 0 && params.data.targetScenerioTitle != "Total" && params.data.targetScenerioTitle != 'Change to reference') {
    params.data.totaldirectcost = null;
  }
  return params.data.totaldirectcost;
};
// used to format the cell in germman currency format;
function CurrencyCellRenderer(params: any) {
  return params.value == undefined ? null : new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}