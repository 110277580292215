import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MasterdataService } from '../../../services/masterdata.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { ToasterService } from 'src/app/services/toaster.service';
import { serverMessage } from 'src/app/popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { AlertDialogComponent } from 'src/app/alert-dialog/alert-dialog.component';
import { messages } from 'src/app/popUpMessages/messages';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class MasterDataComponent implements OnInit {
  masterForm: FormGroup;
  masterColumnDefs: any;
  masterRowData: any = [];
  defaultMasterColDef: any;
  headerHeight: any;
  rowHeight: any;
  domLayout: any;
  headerHeightRef: any;
  frameworkComponents: any;
  gridApi: any;
  gridColumnApi: any;
  suppressRowTransform: boolean = true;
  edit: boolean = false;
  showForm: boolean;
  ideaSubmitterId: any;
  editType: any;
  selectPage: boolean;
  rowArray = [];
  masterArray: any;
  titleArray: any
  sendId: any;
  loginUserDetails: any;
  mTitle: any;
  mId: any;
  addDisable: boolean = false;
  showSpinner: boolean = false;
  ifSubCommoditeSelected: boolean = false;
  ifOpwSubFilterSelected: boolean = false;
  commodityArray: any = [];
  opwFilterArray: any = [];

  // changes;
  public adminSubscription: any;
  public unsvdAdminChanges: boolean = false;
  @Output() unSavedChanges = new EventEmitter<any>();


  // end;

  // changes for business area and line dropdown
  public roleBusinessArea = [];
  public roleAllBusinessLine = [];
  public roleBusinessLine = [];
  public disableBusinessAreaDropdown: boolean = false;
  public disableBusinessLineDropdown: boolean = false;

  selectBusinessArea = "";
  selectBusinessLine = "";

  constructor(
    private masterService: MasterdataService,
    private sharedService: SharedServiceService,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private dialog: MatDialog
  ) {
    this.loadGrid();
  }
  ngOnDestroy() {
    if (this.adminSubscription) {
      this.adminSubscription.unsubscribe()
    }
  }

  ngOnInit(): void {

    this.masterForm = this.formBuilder.group({
      "masterId": [{value: '', disabled: true}, Validators.required],
      "commodity": [''],
      "opwFilter": [''],
    });

    // subscribe to manage data eventemitter;
    this.adminSubscription = this.sharedService.masterData.subscribe(() => {
      this.Submit();
    })

    this.sharedService.getIdeaIdAndtitle();
    this.sharedService.loggedInUser.subscribe(
      (user: any) => {
        this.loginUserDetails = user;

        //getting business area and line access of user
        this.roleBusinessArea = this.loginUserDetails.BusinessArea;
        this.roleAllBusinessLine = this.loginUserDetails.BusinessLine;
        //removing duplicates in the list
        this.roleBusinessArea = this.roleBusinessArea.filter((v,i,a)=>a.findIndex(v2=>(v2.BusinessAreaId===v.BusinessAreaId))===i);
        
        //If the user has only one access, auto populate the dropdown
        if (this.roleBusinessArea.length == 1) {
          this.selectBusinessArea = this.roleBusinessArea[0].BusinessAreaId;
          this.roleBusinessLine = this.roleAllBusinessLine;
          this.disableBusinessAreaDropdown = true;
        }
        if (this.roleAllBusinessLine.length == 1) {
          this.selectBusinessLine = this.roleAllBusinessLine[0].BusinessLineId;
          this.disableBusinessLineDropdown = true;
        }

        if (this.selectBusinessArea != "" && this.selectBusinessLine != "") {
          this.masterForm.controls["masterId"].enable();
        }
        else {
          this.masterForm.controls["masterId"].disable();
        }

      }
    );

    this.masterService.getAllMasterTypeData().subscribe(
      (res: any) => {
        let errorMsgeFromBack = res.Data.Message;
        if (res.StatusCode == 200) {
          this.masterArray = res.Data
        }
        else {
          //Show errorCode and errorMessage in the UI
          let errorContainer = [
            { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
            { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
          ]
          this.openAlertDialog(errorContainer)
        }
      },
      (error) => {
        this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
      }
    )
  }

  updateBusinessArea() {
    this.masterForm.controls["masterId"].setValue('');
    this.masterForm.controls["masterId"].disable();
    this.dataChange(0);
    this.selectBusinessLine = "";
    this.roleBusinessLine = this.roleAllBusinessLine.filter(
      bl => bl.BusinessAreaId == Number(this.selectBusinessArea)
    );
  }

  updateBusinessLine() {
    this.masterForm.controls["masterId"].setValue('');
    this.dataChange(0);
    if (this.selectBusinessArea != "" && this.selectBusinessLine != "")
      this.masterForm.controls["masterId"].enable();
    else 
      this.masterForm.controls["masterId"].disable();
  }

  loadGrid() {
    this.masterColumnDefs = [
      {
        field: 'Id', headerName: 'Id', editable: false, minWidth: 50, hide: true, cellRenderer: this.dataFormat
      },
      {
        field: 'Title', headerName: 'Title', headerTooltip: "Title", editable: true, minWidth: 100, menuTabs: ['filterMenuTab'],
        cellStyle: cellStyle
      },
    ]

    this.defaultMasterColDef = {
      flex: 1,
      resizable: true,
      filter: true,
      singleClickEdit: true,
      suppressMenu: false
    };

    this.rowHeight = 40;
    this.headerHeight = 40;
    this.headerHeightRef = 50;
    this.editType = 'fullRow'
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  dataChange(value) {
    this.sendId = value;
    if (value == "8") {
      this.masterForm.controls["commodity"].setValue("");
      this.selectPage = true
      this.masterRowData = [];
      this.gridApi.getColumnDef("Title").headerName = 'Title';
      this.gridApi.getColumnDef("Id").headerName = 'Id';
      this.gridColumnApi.setColumnVisible("Id", false);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshHeader();

      this.masterService.getDatabyMasterTypeId("7", Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
        (res: any) => {
          if (res.StatusCode == 200) {
            this.commodityArray = res.Data
          }
        }
      )
      this.ifSubCommoditeSelected = true;
    }
    else {
      this.ifSubCommoditeSelected = false
    }

    if (value == "20") {
      this.masterForm.controls["opwFilter"].setValue("");
      this.selectPage = true
      this.masterRowData = [];
      this.gridApi.getColumnDef("Title").headerName = 'Title';
      this.gridApi.getColumnDef("Id").headerName = 'Id';
      this.gridColumnApi.setColumnVisible("Id", false);
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshHeader();

      this.masterService.getDatabyMasterTypeId("19", Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
        (res: any) => {
          if (res.StatusCode == 200) {
            this.opwFilterArray = res.Data
          }
        }
      )
      this.ifOpwSubFilterSelected = true;
    }
    else {
      this.ifOpwSubFilterSelected = false
    }

    this.titleArray = [];
    if (value == "") {
      this.selectPage = false;
      this.masterRowData = '';
      this.gridApi.getColumnDef("Title").headerName = 'Title';
      this.gridApi.getColumnDef("Id").headerName = 'Id';
      this.gridApi.refreshHeader();
    }
    else if (value != "8" && value != "20") {
      this.selectPage = true
      this.masterService.getDatabyMasterTypeId(value, Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
        (res: any) => {
          let errorMsgeFromBack = res.Data.Message;
          if (res.StatusCode == 200) {
            if (value == 14) {
              this.addDisable = true
              // To show the id column if the selected value from dropdown is General Setting
              this.gridColumnApi.setColumnVisible("Id", true)
              this.gridApi.sizeColumnsToFit();
            }
            else {
              this.addDisable = false;
              // To hide the id column if the selected value from dropdown is not General Setting
              this.gridColumnApi.setColumnVisible("Id", false)
              this.gridApi.sizeColumnsToFit();
            }
            let rowData = res.Data
            if (rowData == "") {
              this.masterRowData = []
              return
            }
            let keys = Object.keys(rowData[0])
            this.masterRowData = res.Data;
            this.titleArray = res.Data;

            let keysId = [];
            this.mId = keys[0]
            if (this.sendId == 14) {
              keysId.push(keys[1])
            }
            else {
              keysId.push(keys[0])
            }
            keysId.forEach((value, index) => {
              this.gridApi.getColumnDef("Id").field = value;
              this.gridApi.getColumnDef("Id").headerName = value;
              this.gridApi.getColumnDef("Id").tooltipField = value;
            });

            this.gridApi.refreshHeader();
            let keysTitle = [];
            if (this.sendId == 14) {
              keysTitle.push(keys[2])
              //this.gridApi.getColumnDef("Id").minWidth = 100
            }
            else {
              keysTitle.push(keys[1])
            }
            keysTitle.forEach((value, index) => {
              this.gridApi.getColumnDef("Title").field = value;
              this.gridApi.getColumnDef("Title").headerName = value;
              this.gridApi.getColumnDef("Title").tooltipField = value;
            });
            this.gridApi.refreshHeader();
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
        (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
        }
      )
    }
  }

  onCommodityChange(commodityValue?) {
    this.addDisable = false;
    if (this.masterForm.get("commodity").value == "") {
      this.masterRowData = [];
    }
    if (commodityValue == undefined) {
      commodityValue = this.masterForm.get("commodity").value;
    }
    this.masterService.getDatabyMasterTypeId("8", Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
      (res: any) => {
        this.masterRowData = res.Data.filter(data => data.CommodityId == commodityValue);
        // this.setGridHeight(res.Data.length)
      }
    )

    this.gridApi.getColumnDef("Id").field = "SubCommodityId";
    this.gridApi.getColumnDef("Id").headerName = "SubCommodityId";
    this.gridApi.getColumnDef("Id").tooltipField = "SubCommodityId";

    this.gridApi.getColumnDef("Title").field = "SubCommodityTitle";
    this.gridApi.getColumnDef("Title").headerName = "SubCommodityTitle";
    this.gridApi.getColumnDef("Title").tooltipField = "SubCommodityTitle"

    this.gridApi.refreshHeader();
  }

  onOpwFilterChange(opwFilterValue?) {
    this.addDisable = false;
    if (this.masterForm.get("opwFilter").value == "") {
      this.masterRowData = [];
    }
    if (opwFilterValue == undefined) {
      opwFilterValue = this.masterForm.get("opwFilter").value;
    }
    this.masterService.getDatabyMasterTypeId("20", Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
      (res: any) => {
        this.masterRowData = res.Data.filter(data => data.OPWFilterId == opwFilterValue);
        // this.setGridHeight(res.Data.length)
      }
    )

    this.gridApi.getColumnDef("Id").field = "OPWSubFilterId";
    this.gridApi.getColumnDef("Id").headerName = "OPWSubFilterId";
    this.gridApi.getColumnDef("Id").tooltipField = "OPWSubFilterId";

    this.gridApi.getColumnDef("Title").field = "OPWSubFilterTitle";
    this.gridApi.getColumnDef("Title").headerName = "OPWSubFilterTitle";
    this.gridApi.getColumnDef("Title").tooltipField = "OPWSubFilterTitle"

    this.gridApi.refreshHeader();
  }

  // get called on click of cancel button;
  onCancel() {
    let valToEmit = {
      unsavedChanges: false,
      saveButtonClick: true
    }
    this.unSavedChanges.emit(valToEmit);

    if (this.selectPage) {
      if (this.sendId == "8") {
        this.onCommodityChange();
      }
      else {
        this.dataChange(this.sendId);
      }
    }
  }

  validateRow(row?) {
    let data;
    if (row) {
      data = row;
    }
    else {
      data = this.gridApi.getDisplayedRowAtIndex(0) ? this.gridApi.getDisplayedRowAtIndex(0).data : null;
      if (data == null && this.sendId == 8 && this.masterForm.get("commodity").value != "") {
        return true
      } else if (data == null && this.sendId == 8 && this.masterForm.get("commodity").value == "") {
        let message = messages.masterData.commodity
        this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
        return false
      }

      if (data == null && this.sendId == 20 && this.masterForm.get("opwFilter").value != "") {
        return true
      } else if (data == null && this.sendId == 20 && this.masterForm.get("opwFilter").value == "") {
        let message = messages.masterData.opwFilter
        this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
        return false
      }
    }
    if (this.sendId == 1 && (data.OrgUnitTitle == null || data.OrgUnitTitle == "")) {
      let message = messages.masterData.OrgUnitTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 3 && (data.MeasureTypeTitle == null || data.MeasureTypeTitle == "")) {
      let message = messages.masterData.MeasureTypeTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 4 && (data.MeasureCategoryTitle == null || data.MeasureCategoryTitle == "")) {
      let message = messages.masterData.MeasureCategoryTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 5 && (data.CostImpactTitle == null || data.CostImpactTitle == "")) {
      let message = messages.masterData.CostImpactTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 6 && (data.MeasureStatusTitle == null || data.MeasureStatusTitle == "")) {
      let message = messages.masterData.MeasureStatusTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 7 && (data.CommodityTitle == null || data.CommodityTitle == "")) {
      let message = messages.masterData.CommodityTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 8 && (data.SubCommodityTitle == null || data.SubCommodityTitle == "")) {
      let message = messages.masterData.SubCommodityTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 9 && (data.CostTypeTitle == null || data.CostTypeTitle == "")) {
      let message = messages.masterData.CostTypeTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 11 && (data.ImpactTitle == null || data.ImpactTitle == "")) {
      let message = messages.masterData.ImpactTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 14 && (data.SettingValue == null || data.SettingValue == "")) {
      let message = messages.masterData.SettingValue
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 16 && (data.ProductTitle == null || data.ProductTitle == "")) {
      let message = messages.masterData.ProductTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 19 && (data.OPWFilterTitle == null || data.OPWFilterTitle == "")) {
      let message = messages.masterData.opwFilterTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 20 && (data.OPWSubFilterTitle == null || data.OPWSubFilterTitle == "")) {
      let message = messages.masterData.opwSubFilterTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else if (this.sendId == 21 && (data.OPWTagTitle == null || data.OPWTagTitle == "")) {
      let message = messages.masterData.opwTagTitle
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
      return false
    }
    else {
      return true
    }
  }

  AddNewData() {
    //To reset the filter and sorting while adding a new master row
    this.gridColumnApi.resetColumnState();
    this.gridApi.setFilterModel(null);
    if (!this.selectPage) {
      let message = messages.masterData.selectMaster
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } })
    }
    else {
      if (!this.validateRow()) {
        return
      }
      if (this.sendId == 1) {
        this.gridApi.applyTransaction({
          add: [{
            OrgUnitId: 0,
            OrgUnitTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 3) {
        this.gridApi.applyTransaction({
          add: [{
            MeasureTypeId: 0,
            MeasureTypeTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 4) {
        this.gridApi.applyTransaction({
          add: [{
            MeasureCategoryId: 0,
            MeasureCategoryTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 5) {
        this.gridApi.applyTransaction({
          add: [{
            CostImpactId: 0,
            CostImpactTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 6) {
        this.gridApi.applyTransaction({
          add: [{
            MeasureStatusId: 0,
            MeasureStatusTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 7) {
        this.gridApi.applyTransaction({
          add: [{
            CommodityId: 0,
            CommodityTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 8) {
        this.gridApi.applyTransaction({
          add: [{
            CommodityId: this.masterForm.get("commodity").value,
            SubCommodityId: 0,
            SubCommodityTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 9) {
        this.gridApi.applyTransaction({
          add: [{
            CostTypeId: 0,
            CostTypeTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 11) {
        this.gridApi.applyTransaction({
          add: [{
            ImpactId: 0,
            ImpactTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 14) {
        this.gridApi.applyTransaction({
          add: [{
            GenSettingId: 0,
            SettingKey: null,
            SettingValue: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 19) {
        this.gridApi.applyTransaction({
          add: [{
            OPWFilterId: 0,
            OPWFilterTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 20) {
        this.gridApi.applyTransaction({
          add: [{
            OPWFilterId: this.masterForm.get("opwFilter").value,
            OPWSubFilterId: 0,
            OPWSubFilterTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
      else if (this.sendId == 21) {
        this.gridApi.applyTransaction({
          add: [{
            OPWTagId: 0,
            OPWTagTitle: null,
            IsActive: true,
            ModifiedBy: null,
            ModifiedOn: null
          }],
          addIndex: 0
        })
      }
    }
  }

  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }

  onCellValueChanged(params) {
    let valToEmit = {
      unsavedChanges: true,
      saveButtonClick: false
    }
    this.unSavedChanges.emit(valToEmit);

    if (this.sendId == 1) {
      params.data.OrgUnitTitle = params.data.OrgUnitTitle.trim();
    }
    else if (this.sendId == 3) {
      params.data.MeasureTypeTitle = params.data.MeasureTypeTitle.trim();
    }
    else if (this.sendId == 4) {
      params.data.MeasureCategoryTitle = params.data.MeasureCategoryTitle.trim();
    }
    else if (this.sendId == 5) {
      params.data.CostImpactTitle = params.data.CostImpactTitle.trim();
    }
    else if (this.sendId == 6) {
      params.data.MeasureStatusTitle = params.data.MeasureStatusTitle.trim();
    }
    else if (this.sendId == 7) {
      params.data.CommodityTitle = params.data.CommodityTitle.trim();
    }
    else if (this.sendId == 8) {
      params.data.SubCommodityTitle = params.data.SubCommodityTitle.trim();
    }
    else if (this.sendId == 9) {
      params.data.CostTypeTitle = params.data.CostTypeTitle.trim();
    }
    else if (this.sendId == 11) {
      params.data.ImpactTitle = params.data.ImpactTitle.trim();

    }
    else if (this.sendId == 14) {
      params.data.SettingValue = params.data.SettingValue.trim();
    }
    this.rowArray.push(params.data)
  }

  dataFormat(params) {
    if (params.data.SettingKey) {
      let result = params.data.SettingKey.replace('&lt', '<').replace('&gt', '>').replace('&le', '<=').replace('&ge', '>=');
      return result;
    }
  //  return;
  }

  Submit(event?: any) {
    if (!this.selectPage) {
      let message = messages.masterData.selectMaster;
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } });
    }
    else if (this.sendId == 8 && this.masterForm.get("commodity").value == "") {
      let message = messages.masterData.commodity;
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } });
    }
    else if (this.sendId == 20 && this.masterForm.get("opwFilter").value == "") {
      let message = messages.masterData.opwFilter;
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } });
    }
    else if (this.rowArray.length == 0) {
      let message = messages.masterData.updateData;
      this.dialog.open(DialogComponent, { data: { message: message, okBtn: "Ok" } });
    }
    else {
      if (!this.validateRow()) {
        return;
      }
      for (let value of this.masterRowData) {
        if (!this.validateRow(value)) {
          return;
        }
      }

      let sendData = [];
      this.rowArray = this.rowArray.filter((element, i) => i === this.rowArray.indexOf(element));
      for (let val of this.rowArray) {
        if (!val["CreatedBy"]) {
          val["CreatedBy"] = this.loginUserDetails.UserId;
          val["CreatedOn"] = new Date().toISOString();
        }
        else {
          val["ModifiedBy"] = this.loginUserDetails.UserId;
          val["ModifiedOn"] = new Date().toISOString();
        }
        val["BusinessAreaId"] = Number(this.selectBusinessArea);
        val["BusinessLineId"] = Number(this.selectBusinessLine);
        sendData.push(val);
      }
      this.rowArray = [];

      this.masterService.updateData(this.sendId, sendData).subscribe(
        (res) => {
          let successCode = res.StatusCode;
          let errorCode = res.Data.ErrorCode;
          let errorMsgeFromBack = res.Data.Message;
          //Check if successCode is ok i.e 200
          if (successCode == 200) {
            //check if errorCode in the response is 0 then everythig is fine.
            if (errorCode == 0) {
              // emit when there is succesfull saving;
              let valToEmit = {
                unsavedChanges: false,
                saveButtonClick: event == undefined ? false : true
              }              
              this.unSavedChanges.emit(valToEmit);
              
              this.toasterService.notify(serverMessage.dataSaveMessage, "success");
              this.masterService.getDatabyMasterTypeId(this.sendId, Number(this.selectBusinessArea), Number(this.selectBusinessLine)).subscribe(
                (res) => {
                  if (this.sendId == "20")
                    this.masterRowData = res.Data.filter(data => data.OPWFilterId == this.masterForm.get("opwFilter").value);
                  else if (this.sendId == "8")
                    this.masterRowData = res.Data.filter(data => data.CommodityId == this.masterForm.get("commodity").value);
                  else
                    this.masterRowData = res.Data
                  this.showSpinner = false
                }
              )
              this.showSpinner = false
            }
            //If the error code in the resposne is not 0, then display the error in UI.
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          }
          //Going in else means that success code is not. ie. Http Status from server is not ok.
          else {
            let errorContainer = [
              { [serverMessage.serverNotSaveMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
            return false;
          }
        }, (error) => {
          this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          return false;
        }
      )
    }
  }
}

function cellStyle(params) {
  let regex = new RegExp(/\^s*$/);
  if (params.value == null || params.value == "" || regex.test(params.value)) {
    return { borderLeft: "5px solid #a94442" };
  }
  else {
    return { borderLeft: "" };
  }
}
