import { Component } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { EditDeleteComponent } from './button-edit/edit-delete/edit-delete.component';
import { Router } from '@angular/router';
import { IdeasearchService } from '../services/ideasearch.service';
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { LocalStorageService } from '../services/local-storage.service';
import { GridlinkComponent } from '../pages/plc/processcost/gridlink/gridlink.component';
import { default as moment } from 'moment';
import { serverMessage } from '../popUpMessages/serverMessage';
import { SharedServiceService } from 'src/app/services/shared-service.service';

//Defined Global variables
let countryCode;

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css']
})

export class GridComponent {

  public title = 'Demo';
  public columnDefs: any;
  public defaultProperties: any;
  public rowData: any;
  public gridApi: any;
  public gridColumnApi: any;
  public gridOptions: Partial<GridOptions>;
  public errorMessage = []
  public showGrid = false;
  public dataRecieved: any;
  public rowStyle;
  public headerHeight;
  public paginationPageSize;
  public incomingValue: any;
  public name: string = "";
  public rowHeight: number;
  public isEditable: boolean = false;
  public countryCode: any;
  // use to control the display of edit and del button;
  public showEditdel = false;
  public user: any;

  constructor(
    public router: Router,
    private LocalStorageService: LocalStorageService,
    private gridService: IdeasearchService,
    private dialog: MatDialog,
    private sharedService: SharedServiceService
  ) { };

  ngOnInit() {
    this.loadRowData();
    // set the ideaId null;
    this.LocalStorageService.set('ideaId', null);
    //Get Country Code
    countryCode = this.LocalStorageService.get('countryCode');
  }

  ResetFilter() {
    this.LocalStorageService.set('persistFilter', null);
    const filterModel = this.LocalStorageService.get('persistFilter');
    this.gridApi.setFilterModel(JSON.parse(filterModel));
  }

  // it loads the row data for the grid by making a service call;
  loadRowData() {
    this.sharedService.loggedInUser.subscribe(user => {
      if (user != null) {
        this.user = user;
        this.gridService.getIdea(this.user.UserId).subscribe((data: any) => {
          let errorMsgeFromBack = data.Data.Message;
          if (data.StatusCode == 200) {
            this.incomingValue = data;
            this.rowData = this.incomingValue.Data;
            this.loadGrid();
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.serverErrorMessage]: serverMessage.serverErrorHeader },
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }

        },
          (error) => {
            //Show errorCode and errorMessage in the UI
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          })
      }
    })
  }

  // load grid structure after loading of row data;
  loadGrid() {
    // for the font size of row data;
    this.rowStyle = { fontSize: '0.7rem' };
    // column definition of the grid;
    this.columnDefs = [
      { headerName: "Id", sortable: true, headerTooltip: "Id", field: 'IdeaId', width: 80, filter: 'agTextColumnFilter', tooltipField: "IdeaId", },
      { headerName: "Title", headerTooltip: "Title", field: 'Title', width: 400, filter: 'agTextColumnFilter', sortable: true, tooltipField: 'Title', cellRendererFramework: GridlinkComponent },
      { headerName: "Submitter", headerTooltip: "Submitter", field: 'Submitter',width: 150, resizable: true, sortable: true, filter: true, tooltipField: "Submitter" },
      { headerName: "Responsible", headerTooltip: "Responsible", field: 'Responsible',width: 150, resizable: true, filter: true, sortable: true, tooltipField: "Responsible" },
      { headerName: "Productivity ", headerTooltip: "Productivity", field: 'Productivity', filter: true, width: 100, resizable: true,sortable: true, cellRenderer: roundOfProductivityData, tooltipValueGetter: roundOfProductivityData, },
      { headerName: "Status", headerTooltip: "Status", field: 'MeasureStatusTitle', filter: true,width: 100,resizable: true, sortable: true, tooltipField: "MeasureStatusTitle" },
      {
        headerName: "Start Date", headerTooltip: "Start Date", field: 'StartDate', width: 80, filter: "agDateColumnFilter", filterParams: {
          buttons: ['reset'],
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            let changedValue: any = moment(filterLocalDateAtMidnight).format('MM/DD/YYYY');
            let changedValue1: any = moment(cellValue).format('MM/DD/YYYY');
            changedValue = new Date(changedValue);
            changedValue1 = new Date(changedValue1);
            if (changedValue.getTime() === changedValue1.getTime()) {
              return 0;
            }
            if (changedValue1 < changedValue) {
              return -1;
            }
            if (changedValue1 > changedValue) {
              return 1;
            }
          },
          browserDatePicker: true,
        }, tooltipValueGetter: DateFormat, valueFormatter: DateFormat, sortable: true
      },
      { headerName: "Business Area", headerTooltip: "Business Area",width: 120, field: 'BusinessAreaName', filter: true, sortable: true, tooltipField: "BusinessAreaName" },
      { headerName: "Business Line", headerTooltip: "Business Line",width: 80, field: 'BusinessLineName', filter: true, sortable: true, tooltipField: "BusinessLineName" },
      {
        editable: false,
        headerName: "Action",
        headerTooltip: "Action",
        cellRendererFramework: EditDeleteComponent,
        colId: "add",
        width: 100
      }
    ];
    // contains property which allows pagination in the grid;
    this.gridOptions = {
      pagination: true,
    }
    // contains default properties for the grid;
    this.defaultProperties = {
      editable: this.isEditable,
      // wrapText: true,
      suppressMovable: true,
    }
    this.showGrid = true;
    this.paginationPageSize = 200;
  }

  onFilterChanged(params) {
    this.onPageSizeChanged();
    this.LocalStorageService.set('persistFilter', JSON.stringify(params.api.getFilterModel()));
  }

  onFirstDataRendered(params): void {
    const filterModel = this.LocalStorageService.get('persistFilter');
    params.api.setFilterModel(JSON.parse(filterModel));
  }

  // called on load of grid and coan be used to fetch the properties of the Grid;
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.applyColumnState({
      state: [
        {
          colId: 'IdeaId',
          sort: 'desc',
        },
      ],
    });
    this.gridApi.sizeColumnsToFit();
  }

  // This is get called whenevr the size of the grid changed;
  onGridSizeChanged(params) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }


  // it will apply the filter to show the idea of the logged in user first;
  // onFirstDataRendered(params) {
  //   this.sharedService.loggedInUser.subscribe((user: any) => {
  //     if (user != null) {
  //       // Binding user name with the user's org code;
  //       this.name = user.FullName + " [" + user.OrgCode + "]";
  //       var instance = this.gridApi.getFilterInstance('Submitter');
  //       instance.setModel({
  //         values: [this.name],
  //       });
  //       this.gridApi.onFilterChanged();
  //     }
  //   })
  // }

  // it changes the size of grid and the number of rows to display; called when the user select a value from dropdown;
  onPageSizeChanged() {
    let value = (<HTMLInputElement>document.getElementById('page-size')).value;
    let totalpages = this.gridApi.paginationGetRowCount();
    if (value == "all") {
      this.gridApi.paginationSetPageSize(Number(totalpages));
    }
    else {
      this.gridApi.paginationSetPageSize(Number(value));
    }
  }

  // route user to home page;
  goToHomePage() {
    this.router.navigate(["/home"]);
  }

  //route to create idea page
  goToCreateIdea() {
    this.router.navigate(["/idea"])
  }
}

// this will format date to German Date Format;
function DateFormat(params) {
  let orignalValue = params.value;
  if (orignalValue != null) {
    let changedValue = moment(orignalValue).format('MM/DD/YYYY');
    return changedValue;
  } else {
    return orignalValue;
  }
}

function roundOfProductivityData(params) {
  return new Intl.NumberFormat(countryCode).format(Number(parseFloat(params.value).toFixed(2)));
}
