import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';
import { IdeasearchService } from '../../../services/ideasearch.service';
import { MatDialog } from '@angular/material/dialog'
import { DialogComponent } from '../../../dialog/dialog.component';
import { AlertDialogComponent } from '../../../alert-dialog/alert-dialog.component';
import { SharedServiceService } from 'src/app/services/shared-service.service';
import { serverMessage } from '../../../popUpMessages/serverMessage';
import { ToasterService } from 'src/app/services/toaster.service';
import { messages } from 'src/app/popUpMessages/messages';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-edit-delete',
  templateUrl: './edit-delete.component.html'
  // styleUrls: ['./edit-delete.component.css']
})
export class EditDeleteComponent implements ICellRendererAngularComp {
  public passedData: any;
  public gridApi: any;
  public params: any;
  public objectOfGrid: any;
  public incomingValue: any;
  public statuscode: any;
  public errorMessage = [];
  public user: any;
  public isDelVisible: boolean = false;
  public isCopyVisible: boolean = false;
  public response: any;
  public copiedData: any;
  constructor(private router: Router,
    private toast: ToasterService,
    private gridS: IdeasearchService,
    private dialog: MatDialog,
    private sharedService: SharedServiceService,
    private userService: UserInfoService) { }


  refresh(): boolean {
    return false;
  };

  // get called on load of the component;
  agInit(params: any): void {
    this.params = params;
    this.gridApi = params.api;
    this.sharedService.loggedInUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        if (this.params.data.RoleId == 1 || this.params.data.RoleId == 2) {
          this.isDelVisible = true;
          this.isCopyVisible = true;
        }
        else if (this.params.data.RoleId == 3) {
          let submitter = this.user.FullName + " [" + this.user.OrgCode + "]";

          if (params.data.Submitter == submitter) {
            this.isDelVisible = true;
            this.isCopyVisible = true;
          }
          else {
            this.isDelVisible = false;
            this.isCopyVisible = false;
          }
        }
      }
    })
  }

  // this will move you to the ideaPage for edit;
  redirect(params) {
    this.passedData = params.data.IdeaId;
    this.router.navigate(["/idea", this.passedData]);
  }

  // get called on delete icon is clicked;
  delData(params) {
    let error = messages.deleteMessage;
    // opens the dialog box for confirmation with two button Yes and No
    const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridS.deldata({ 'IdeaId': params.data.IdeaId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId,  'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {

          this.response = data;
          let successCode = this.response.StatusCode;
          let errorCode = this.response.Data.ErrorCode;
          let errorMsgeFromBack = this.response.Data.Message;
          if (successCode == 200) {
            if (errorCode == 0) {
              this.gridApi.applyTransaction({ remove: [params.data] });
              this.toast.notify(serverMessage.ideaSearch.dataDeleted, "success");
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
          (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          })
      }
    })
  }

  // get called on copy icon is clicked;
  copyData(params) {
    let error = messages.copyMessage + params.data.IdeaId + '?';
    // opens the dialog box for confirmation with two button Yes and No
    const dialogRef = this.dialog.open(DialogComponent, { data: { message: error, okBtn: "Yes", cancelBtn: "No" } })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.gridS.copydata({ 'IdeaId': params.data.IdeaId, 'UserId': params.data.UserId, 'BusinessAreaId': params.data.BusinessAreaId,  'BusinessLineId': params.data.BusinessLineId }).subscribe((data) => {
          this.response = data;
          let successCode = this.response.StatusCode;
          let errorCode = this.response.Data.ErrorCode;
          let errorMsgeFromBack = this.response.Data.Message;
          let result = [];
          if (successCode == 200) {
            if (errorCode == 0) {
              this.router.navigateByUrl('/manageIdea');
              this.gridS.getIdea(this.user.UserId).subscribe((data: any) => {

                if (data.StatusCode == 200) {
                  //result = data.Data;
                  //const sorted = result.sort((a, b) => (a.IdeaId > b.IdeaId ? -1 : 1));
                  const sorted = data.Data.toSorted((a, b) => (a.IdeaId > b.IdeaId ? -1 : 1));
                  this.copiedData = sorted;
                  this.gridApi.applyTransaction({
                    add: [{
                      IdeaId: this.copiedData[0].IdeaId,
                      Title: this.copiedData[0].Title,
                      Submitter: this.user.FullName + " [" + this.user.OrgCode + "]",
                      Responsible: this.user.FullName + " [" + this.user.OrgCode + "]",
                      Productivity: params.data.Productivity,
                      MeasureStatusTitle: this.copiedData[0].MeasureStatusTitle,
                      StartDate: params.data.StartDate,
                      BusinessAreaId: params.data.BusinessAreaId,
                      BusinessAreaName: params.data.BusinessAreaName,
                      BusinessLineId: params.data.BusinessLineId,
                      BusinessLineName: params.data.BusinessLineName,
                      RoleId: params.data.RoleId,
                      UserId: params.data.UserId
                    }], addIndex: 0
                  })
                }
              })
              this.toast.notify(serverMessage.ideaSearch.dataCopied, "success");
            }
            else {
              //Show errorCode and errorMessage in the UI
              let errorContainer = [
                { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
              ]
              this.openAlertDialog(errorContainer)
            }
          }
          else {
            //Show errorCode and errorMessage in the UI
            let errorContainer = [
              { [serverMessage.message + errorMsgeFromBack]: serverMessage.serverErrorHeader }
            ]
            this.openAlertDialog(errorContainer)
          }
        },
          (error) => {
            this.openAlertDialog([{ [serverMessage.serverAPIerror]: serverMessage.serverErrorHeader }])
          })
      }
    })
  }

  // To show popup message when there is any error
  openAlertDialog(error) {
    this.dialog.open(AlertDialogComponent, { data: error })
  }
}


