<app-progress-spinner *ngIf="(!showComplex && !showRisk) || submitted"></app-progress-spinner>
<div *ngIf="showComplex && showRisk" class="container">
    <div class="label-header">
        <label class="label-headercolor">Option C </label>
    </div>

    <div class="label-header">
        <label class="label-headercolor">Complexity Assessment (Add New Complexity Item</label>
        <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowRef()" title="Add"><img alt="plusIcon" src="../assets/icons/Plus.svg" width="25" height="25" class="icon-add">
        </button><label class="label-headercolor">)</label>
    </div>

    <ag-grid-angular *ngIf="showComplex" style="border: 1px solid #e6e6e6; width: 100%; " id="refSceComplexGrid"
        class="ag-theme-alpine comp" [columnDefs]="complexityColumndef" [getRowHeight]="getRowHeight"
        [domLayout]="domLayout" [rowData]="complexityRowdata" [defaultColDef]="defaultProperties"
        [headerHeight]="headerHeightComplex" [enableBrowserTooltips]="true" [rowClassRules]="rowClassRulesCom"
        (cellValueChanged)="onCellValueChangedComplex($event)" [popupParent]="popupParent"
        (gridReady)="onGridReady($event)" (rowClicked)="onRowClickedComplx($event)"  (gridSizeChanged)="onGridRefSceComplexSizeChanged($event)">
    </ag-grid-angular>

    <div class="label-header">
        <label class="label-headercolor">Risk Assessment (Add New Risk Item </label>
        <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowRisk()" title="Add">
            <img alt="plusIcon" src="../assets/icons/Plus.svg" width="25" height="25" class="icon-add">
        </button><label class="label-headercolor">)</label>
    </div>

    <ag-grid-angular *ngIf="showRisk" style=" width:100%; border: 1px solid #e6e6e6; " id="refSceRiskGrid"
        class="ag-theme-alpine comp" [domLayout]="domLayout" [columnDefs]="riskColumnDef" [rowData]="riskRowdata"
        [getRowHeight]="getRowHeight" [enableBrowserTooltips]="true" [defaultColDef]="defaultProperties" [headerHeight]="headerHeightComplex"
        [rowStyle]="rowStyleRisk" (cellValueChanged)="onCellValueChangedRisk($event)" [popupParent]="popupParent"
        [rowClassRules]="rowClassRulesRisk" (gridReady)="onGridReadyRisk($event)"
        (rowClicked)="onRowClickedRisk($event)" (gridSizeChanged)="onGridRefSceRiskSizeChanged($event)">
    </ag-grid-angular>
<!-- Remark Changes -->
<div *ngIf="showRisk" class="row row-height remark_style"  >
    <div class="col-md-3 col-lg-3">
      <label>Additional Details </label>
    </div>
    <div class="col-md-9 col-lg-9">
      <textarea
        id="txtareaRemarks"
        style="font-size: 0.7rem; width: 100%"
        aria-label="Remarks_Target"
        [(ngModel)]="remark"
        [disabled]="!isEditable"
        (input)="checkRemarkChange()"
        cols="75"
        rows="3"
        maxlength="1500"
        wrap="hard"
      ></textarea>
    </div>
  </div>
    <div class="row" style="margin-top: 100px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle">
                <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" type="submit" (click)="onClickBack()">Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" type="submit" (click)="onClickNext()">Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" type="submit" (click)="submit($event)">Save & Exit</button>
            </div>
        </div>
    </div>
</div>