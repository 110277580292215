<app-progress-spinner *ngIf="(!showReference && !showTarget) || submitted"></app-progress-spinner>
<div *ngIf="showReference && showTarget" class="container">

    <div class="col-md-12 col-lg-12 label-header">
        <label class="label-headercolor">Reference Scenario (Add New Component </label>
        <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowRef()" title="Add">
            <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
        </button> <label class="label-headercolor">)</label>
    </div>


    <ag-grid-angular *ngIf="showReference" style="width: 100%;border: 1px solid #e6e6e6;" id="refSceDrtCost"
        class="ag-theme-alpine" [columnDefs]="refScenerioColumndef" [domLayout]="domLayout"
        [rowData]="refScenerioRowdata" [rowClassRules]="rowClassRulesRef" [enableBrowserTooltips]="true"
        [frameworkComponents]="frameworkComponents" [getRowHeight]="getRowHeightRef" [headerHeight]="headerHeightRef"
        [rowStyle]="rowStyle" (cellValueChanged)="onCellValueChanged($event)" [defaultColDef]="defaultProperties"
        (rowClicked)="onRowClickedReference($event)" (gridReady)="onGridReady($event)"
        (gridSizeChanged)="onGridRefSizeChanged($event)">
    </ag-grid-angular>


    <div class="col-md-12 col-lg-12 label-header">
        <label class="label-headercolor">Target Scenario (Add New Component </label>
        <button [disabled]="!isEditable" [ngClass]="{'click-not-allowed':!isEditable}" (click)="AddNewRowTar()" title="Add">
            <img alt="plusIcon" src="../assets/icons/Plus.svg" height="25" width="25" class="icon-add">
        </button><label class="label-headercolor"> )</label>
    </div>

    <ag-grid-angular *ngIf="showTarget" style="width: 100%;border: 1px solid #e6e6e6;" id="tgtSceDrtCost"
        class="ag-theme-alpine" [columnDefs]="targetColumnDef" [rowData]="targetRowdata"
        [getRowHeight]="getRowHeightTar" [rowClassRules]="rowClassRulesTar" (rowClicked)="onRowClickedTarget($event)"
        [defaultColDef]="defaultProperties" [headerHeight]="headerHeightRef" [rowStyle]="rowStyle"
        [frameworkComponents]="frameworkComponents" [domLayout]="domLayout" [enableBrowserTooltips]="true"
        (gridReady)="onGridReadyTarget($event)" (cellValueChanged)="onCellValueChangedTarget($event)"
        (gridSizeChanged)="onGridTgtSizeChanged($event)">
    </ag-grid-angular>
    <div class="row row-height" style="margin-top: 15px;">
        <div class="col-md-3 col-lg-3">
            <label>Additional Details </label>
        </div>
        <div class="col-md-9 col-lg-9">
            <textarea  id="txtareaRemarks" style="font-size: 0.7rem;width:100%;" aria-label="Remarks_Target"
            [(ngModel)]="remark" [disabled]="!isEditable"    (input)="checkRemarkChange()"  cols="75" rows="3" maxlength="1500" wrap="hard"></textarea>
        </div>
    </div>

    <div class="row" style="margin-top: 100px">
        <div class="offset-4 col-md-8">
            <div class="buttonStyle" >
                <button class="button button--small" (click)="onClickCancel()">Cancel & Exit</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickBack()" type="submit">Back</button>&nbsp;
                <button class="button button--small" [disabled]="submitted" (click)="onClickNext()" type="submit">Next</button>&nbsp;
                <button class="button button--small" [disabled]="submitted || !isEditable" (click)="submit($event)" type="submit">Save & Exit</button>
            </div>
        </div>
    </div>
</div>